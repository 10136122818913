import webserviceWrapper from "../_helpers/webserviceWrapper";

//whenever something related to admin is done on admin side this page is called
export const adminservice={
    GetAllModuleList,
    DeleteSelectedModules,
    GetAllModuleTabs,
    GetTabsBasedOnModuleID,
    GetModuleDetailsBasedOnModuleTabID,
    GetModuleDataBasedOnModuleID,
    AddEditModuleDetails,
    DeleteModuleDetail,
    AddEditModule,
    CreateNewVersionofModuleBasedOnModuleID,
    GetAssessorList,
    GetAdminProfile,
    GetFullMessage,
    GetEmailTemplate,
    DeleteCourseEnquiryTemplates,
    AddEditAccessor,
    GetSelectedTemplateData,
    CourseEnquiryEmailTemplateAttachmentBridgeDataBasedOncourseEnquiryEmailTemplateId,
}

function GetAllModuleList(){
    return webserviceWrapper.get(
        `Admin/GetAllModuleList`
     );
}

function DeleteSelectedModules(ModuleID){
    return webserviceWrapper.post(
        `Admin/DeleteSelectedModules`,
        ModuleID
    )
}

function GetAllModuleTabs(){
    return webserviceWrapper.get(
        `Admin/GetAllModuleTabs`
     );
}

function GetTabsBasedOnModuleID(moduleID){
    return webserviceWrapper.post(
        `Admin/GetTabsBasedOnModuleID`,
        {moduleID}
    );
}

function GetModuleDetailsBasedOnModuleTabID(moduleID, tabID)
{
    return webserviceWrapper.post(
        `Admin/GetModuleDetailsBasedOnModuleTabID`,
        {moduleID,tabID}
     );
}

function GetModuleDataBasedOnModuleID(moduleID){
    return webserviceWrapper.post(
        `Admin/GetModuleDataBasedOnModuleID`,
        {moduleID}
     );  
}

function AddEditModuleDetails(mduleDetailsModel){
    return webserviceWrapper.post(
        `Admin/AddEditModuleDetails`,
        mduleDetailsModel
      );
}

function DeleteModuleDetail(moduleID, moduleDetailID, TabID){
    return webserviceWrapper.post(
        `Admin/DeleteModuleDetail`,
        {moduleID, moduleDetailID, TabID}
      );
}

function AddEditModule(mduleModel){
    return webserviceWrapper.post(
        `Admin/AddEditModule`,
        mduleModel
      );
}

function CreateNewVersionofModuleBasedOnModuleID(moduleID)
{
    return webserviceWrapper.post(
        `Admin/CreateNewVersionofModuleBasedOnModuleID`,
        {moduleID}
     ); 
}

function GetAssessorList(){
    return webserviceWrapper.get(
        `Admin/GetAssessorList`
     );
}

function GetAdminProfile(adminId){
    return webserviceWrapper.post(
        `Admin/GetAdminProfile?adminId=${adminId}`
    )
}

function GetFullMessage(emailId){
    return webserviceWrapper.post(
        `Admin/GetFullMessage`,
        {emailId}
    )
}

function GetEmailTemplate(){
    return webserviceWrapper.get( `Admin/GetEmailTemplateList`)
}

function DeleteCourseEnquiryTemplates(TemplateListToDelete){
    return webserviceWrapper.post(
        `Admin/DeleteCourseEnquiryTemplates`,
        TemplateListToDelete
    )
}

function AddEditAccessor(accessorEmail){
    return webserviceWrapper.post(
        `Admin/AddEditAccessor`,
        {accessorEmail}
    )
}

function GetSelectedTemplateData(templateName){
    return webserviceWrapper.post(
        `Admin/GetSelectedTemplateData`,
        {templateName}
    )
}

function CourseEnquiryEmailTemplateAttachmentBridgeDataBasedOncourseEnquiryEmailTemplateId(courseEnquiryEmailTemplateId){
    return webserviceWrapper.post(
        `Admin/CourseEnquiryEmailTemplateAttachmentBridgeDataBasedOncourseEnquiryEmailTemplateId`,
        {courseEnquiryEmailTemplateId}
    )
}