import React, { Component } from "react";
import AdminHeader from "../../../_components/AdminHeader";
import { history } from "../../../_helpers/history";
import Document from "../../../assets/Document.png";
import Deleteicon from "../../../assets/Deleteicon.png";
import Loader from "../../../_helpers/Loader";
import { admincourseservice } from "../../../_services/admincourse.service";
import DataTable from "react-data-table-component";
import moment from "moment";
import PopupModal from "../../../_helpers/PopupModal";
import Logout from "../../../_helpers/Logout";
import AdminFooter from "../../../_components/AdminFooter";
import { MessageType } from "../../../_helpers/Enums";
import { helperText } from "../../../_helpers/helpertext";

var now = new Date();
var currentDate = moment(now).format("YYYY-MM-DD");

const stripedDocumentDataTable = {
  rows: {
    stripedColor: "#fff",
    height: "35px",
    textoverflow: "clip",
    whitespace: "nowrap",
  },
  header: {
    height: "35px",
    textoverflow: "clip",
    whitespace: "nowrap",
    backgroundColor: "#e9ecef",
  },
};

export default class CourseIteration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      courseIterationList: [],
      errorMessage: {},
      checkedBoxes: [],
      courseID: "",
      showPopupModal: false,
      popUpModalText: "",
      messageType:0,
    };
  }

  /* <summary>
  date: 19-10-2021
  Name: VD
  description: To get the list of all course iteration based on the course id
  <summary>*/ 
  componentDidMount = async () => {
    try {
      var courseId = this.props.match.params.CourseId;

      this.setState({
        isLoading: true,
        courseID: courseId,
      });

      var response = await admincourseservice.GetCourseIterationList(courseId);
      if (response.status == 200) {
        this.setState({
          courseIterationList: response.data,
        });
      } else if (response.status == 401) {
        Logout();
      } else {
        this.setState({
          popUpModalText: helperText.DataLoadError.loadError,
          showPopupModal: true,
          messageType:MessageType.Failure,
        });
      }
      this.setState({
        isLoading: false,
      });
    } catch (e) { 
      return "";
    }
  };

  onTextChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      isChanged: true,
    });

    this.setState({
      errorMessage: {},
    });
  };

  /* <summary>
  date: 19-10-2021
  Name: VD
  description: To Validate the iteration before deleting the item
  <summary>*/ 
  validateCheckbox = () => {
    const { checkedBoxes } = this.state;
    try {
      var isValid = true;
      if (checkedBoxes == "") isValid = false;

      if (checkedBoxes == "") {
        var error = this.state.errorMessage;
        error["emptyCheckbox"] =helperText.courseIteration.emptyiterationCheckbox;
        this.setState({ errorMessage: error });
      }
      return isValid;
    } catch (error) {}
  };

  handleChange = (state) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data 
    this.setState({
      checkedBoxes: state.selectedRows,
      isChanged: true,
    });
  };

  header = [
    // {
    //   name: (
    //     <th className="text-center" scope="col">
    //       {" "}
    //     </th>
    //   ),
    //   //selector: "Title",
    //   cell: (row) => (
    //     <input
    //       type="checkbox"
    //       id="check"
    //       name="check"
    //       defaultValue="check"
    //       value={row.courseID}
    //       checked={this.state.checkedBoxes.find(
    //         (p) => p.id === row.courseIterationId
    //       )}
    //       onChange={(e) => this.toggleCheckbox(e, row)}
    //     />
    //   ),
    //   left: true,
    // },
    {
      name: (
        <th className="text-center" scope="col">
          {" "}
        </th>
      ),
      //selector: "Title",
      cell: (row) => (
        <img
          src={Document}
          width="30px"
          onClick={() =>
            history.push(
              `/EditCourseIteration/${row.courseIterationId}/${this.state.courseID}`
            )
          }
          style={{ cursor: "pointer" }}
        />
      ),
      left: true,
      width: "150px",
    },
    {
      name: (
        <th className="text-center" scope="col">
          {" "}
          Course title{" "}
        </th>
      ),
      selector: "courseName",
      cell: (row) => <div style={{ cursor: "pointer" }}>{row.courseName}</div>,
      left: true,
      //sortable: true,
      grow: 2,
    },
    {
      name: (
        <th className="text-center" scope="col">
          {" "}
          Iterations{" "}
        </th>
      ),
      selector: "courseIterationName",
      cell: (row) => (
        <div style={{ cursor: "pointer" }}>{row.courseIterationName}</div>
      ),
      center: true,
      grow: 2,
    },
    {
      name: (
        <th className="text-center" scope="col">
          {" "}
          Start date{" "}
        </th>
      ),
      selector: "startDate",
      cell: (row) => (
        <div style={{ cursor: "pointer" }}>
          {moment(row.startDate).format("DD-MM-YYYY")}
        </div>
      ),
      center: true,
      grow: 2,
    },
    {
      name: (
        <th className="text-center" scope="col">
          {" "}
          End date{" "}
        </th>
      ),
      selector: "endDate",
      cell: (row) => (
        <div style={{ cursor: "pointer" }}>
          {moment(row.endDate).format("DD-MM-YYYY")}
        </div>
      ),
      center: true,
      grow: 2,
    },
    // {
    //   name: (
    //     <th className="text-center" scope="col">
    //       {" "}
    //       Days remaining{" "}
    //     </th>
    //   ),
    //   selector: "Iterations",
    //   cell: (row) => <div style={{ cursor: "pointer" }}>{(row.endDate).diff(currentDate, 'days')}</div>,
    //   center: true,
    // },
  ];

  toggleCheckbox = async (e, item) => {
    if (e.target.checked) {
      let arr = this.state.checkedBoxes;
      arr.push(item.courseIterationId);

      this.setState({ checkedBoxes: arr });
    } else {
      let items = this.state.checkedBoxes.splice(
        this.state.checkedBoxes.indexOf(item.courseIterationId),
        1
      );

      this.setState({
        checkedBoxes: items,
      });
    }
  };

  /* <summary>
  date: 19-10-2021
  Name: VD
  description: To delete the selected iteration from the list
  <summary>*/ 
  deleteSelectedCourses = async () => {
    try{
      this.setState({
        isLoading: true,
      });
      const { checkedBoxes } = this.state;
  
      if (this.validateCheckbox()) {
        var response = await admincourseservice.DeleteCourseIterations(checkedBoxes);
        if (response.status == 200) {
          this.setState({
            courseIterationList: response.data,
            checkedBoxes: [],
            popUpModalText: helperText.courseIteration.courseDeleted,
            showPopupModal: true,
            messageType:MessageType.Success,
          });
        } else {
          this.setState({
            popUpModalText: helperText.DataLoadError.loadError,
            showPopupModal: true,
            messageType:MessageType.Failure,
          });
        }
      }
      this.setState({
        isLoading: false,
      });
    }catch (e) { 
      return "";
    }
  };

  closeModal = () => {
    this.setState({
      showPopupModal: false,
    });
  };

  render() {
    const { 
      isLoading, 
      showPopupModal, 
      popUpModalText,
      messageType,
     } = this.state;
    return (
      <div id="admin" style={{ backgroundColor: "#F1F4F6" }}>
        <AdminHeader currentTab="Courses" />
        {isLoading && <Loader />}
        {/* Begin page content */}
        <main
          role="main"
          className="container admin-main-body "
          style={{ paddingBottom: "60px", minHeight: "80vh" }}
        >
          <div className="row row-align">
            <div className="col-md-12">
              <h5 className="mt-3 mb-3 gray">Course Iterations</h5>
            </div>
            <div className="col-md-12" style={{ marginTop: "-1rem" }}>
              <h6 className="mt-3 mb-3 gray">
                Student Course Manager <strong> &gt; Enrolled Courses </strong>{" "}
                <strong>&gt; Course Iterations </strong>
              </h6>
            </div>
            <div className="col-md-9 col-sm-12">
              <p>
                Select a course below to manage its iterations and modules. You
                can create a new course by completing the form below
              </p>
            </div>
            <div className="col-md-3 col-sm-12">
              <button
                type="button"
                className="btn btn-dark button-color add-course"
                onClick={() =>
                  history.push(
                    `/EditCourseIteration/${0}/${this.state.courseID}`
                  )
                }
              >
                Add Course Iteration
              </button>
            </div>
            <div className="col-md-12">
              <button
                type="button"
                className="btn delete-button"
                onClick={this.deleteSelectedCourses}
              >
                <img src={Deleteicon} className="delete-icon" width="20px" />{" "}
                Delete
              </button>
            </div>
            <div
              style={{
                display: this.state.errorMessage.emptyCheckbox
                  ? "contents"
                  : "none",
                color: "red",
              }}
            >
              {this.state.errorMessage.emptyCheckbox}
            </div>
            <div className="container">
              <div className="table-responsive" style={{ paddingTop: "1em" }}>
                <DataTable
                  columns={this.header}
                  data={this.state.courseIterationList}
                  noHeader={true}
                  pagination
                  //customTheme={stripedDocumentDataTable}
                  //selectableRows
                  onSelectedRowsChange={this.handleChange}
                  selectableRows // add for checkbox selection
                />
              </div>
              <button
                type="button"
                className="btn back-button"
                style={{ marginTop: "20px" }}
                onClick={() => history.push("/CreateCourse")}
              >
                <i className="fa fa-angle-left" /> Back
              </button>
            </div>
          </div>
        </main>
        <AdminFooter />
        {showPopupModal && (
          <PopupModal closeModal={this.closeModal} Text={popUpModalText} messageType={messageType} />
        )}
      </div>
    );
  }
}
