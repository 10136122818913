import React, { Component } from "react";
import passspam from "../assets/pass spam.png";
import SucessModal from "../assets/SucessModal.png";
import { MessageType } from "./Enums";

export default class PopupModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messageType: "",
      seconds:3,
    };
  }

  
  componentDidMount = () => {
    var type = this.props.messageType;
    this.setState({
      messageType: type,
    });
    
    setTimeout(()=>{
      this.props.closeModal()
    },3000)
  };

  render() {
    const { Text, messageType } = this.props;
    return (
      (messageType == MessageType.Success ? (
        <div className="alert alert-dismissible alert-success-float">
          <button
            className="close"
            //data-dismiss="alert"
            //aria-label="close"
            style={{ color: "#1d1616", fontWeight: "bold" }}
            onClick={this.props.closeModal}
          >
            ×
          </button>
          <img className="correct" src={SucessModal} width="20px" />
          <span style={{ fontSize: "16px", marginLeft: "8px" }}>{Text}</span>
        </div>
      ) : (
        <div className="alert alert-dismissible alert-danger-float">
          <button
            className="close"
            //data-dismiss="alert"
            //aria-label="close"
            style={{ color: "#1d1616", fontWeight: "bold" }}
            onClick={this.props.closeModal}
          >
            ×
          </button>
          <img className="correct" src={passspam} width="20px" />
          <span style={{ fontSize: "16px", marginLeft: "8px" }}>{Text}</span>
        </div>
      ))
    );
  }
}
