import React, { Component } from "react";
import AdminHeader from "../../../_components/AdminHeader";
import { history } from "../../../_helpers/history";
import DataTable from "react-data-table-component";
import Loader from "../../../_helpers/Loader";
import { adminstudentservice } from "../../../_services/adminstudent.service";
import PopupModal from "../../../_helpers/PopupModal";
import Logout from "../../../_helpers/Logout";
import AdminFooter from "../../../_components/AdminFooter";
import { MessageType } from "../../../_helpers/Enums";
import { UIGridToSheet,SheetToArrayBuffer } from "../../../_helpers/utilities";
import { helperText } from "../../../_helpers/helpertext";

const XLSX = require("xlsx");
export default class StudentsEnquired extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      enquiredStudenList: [],
      unfilteredList: [],
      showPopupModal: false,
      popUpModalText: "",
      messageType: 0,
      Search: "",
    };
  }

  /* <summary>
  date: 15-11-2021
  Name: VD
  description: To get the list of students who have enquired about the course
  <summary>*/
  componentDidMount = async () => {
    try {
      this.setState({
        isLoading: true,
      });
      var response = await adminstudentservice.GetCategoryEnquiryList();
      if ((response.status = 200)) {
        this.setState({
          enquiredStudenList: response.data,
          unfilteredList: response.data,
        });
      } else if (response.status == 401) {
        Logout();
      } else {
        this.setState({
          popUpModalText: helperText.DataLoadError.loadError,
          showPopupModal: true,
          messageType: MessageType.Failure,
        });
      }

      this.setState({
        isLoading: false,
      });
    } catch (e) {
      return "";
    }
  };

  header = [
    {
      name: (
        <div className="table-header" scope="col">
          Name
        </div>
      ),
      selector: "name",
      cell: (row) => row.name,
      center: true,
    },
    {
      name: (
        <div className="table-header" scope="col">
          email
        </div>
      ),
      selector: "email",
      cell: (row) => row.email,
      center: true,
    },
    {
      name: (
        <div className="table-header" scope="col">
          Phone Number
        </div>
      ),
      selector: "phoneNumber",
      cell: (row) => row.phoneNumber,
      center: true,
    },
    {
      name: (
        <div className="table-header" scope="col">
          country
        </div>
      ),
      selector: "country",
      cell: (row) => row.country,
      center: true,
    },
    {
      name: (
        <div className="table-header" scope="col">
          Course enquired
        </div>
      ),
      selector: "courseEnquired",
      cell: (row) => row.courseEnquired,
      center: true,
    },
  ];

  closeModal = () => {
    this.setState({
      showPopupModal: false,
    });
  };

  onSearchTextChange = (event) => {
    var inputValue = event.target.value.toLowerCase();

    var filteredStudent = this.state.unfilteredList.filter(
      (p) =>
        p.name.toLowerCase().includes(inputValue) ||
        p.phoneNumber.toLowerCase().includes(inputValue) ||
        p.courseEnquired.toLowerCase().includes(inputValue)||
        p.email.toLowerCase().includes(inputValue)
    );

    this.setState({
      Search: event.target.value,
      enquiredStudenList: filteredStudent,
    });
  };

  downloadStudentData = () => {
    const { enquiredStudenList } = this.state;
    try {
      var dataToAdd = [...enquiredStudenList].map((item) => item);
      var sheetName = "sheet1";
      var wopts = { bookType: "xlsx", bookSST: true, type: "binary" };
      var fileName = "the_excel_file.xlsx";
      var columns = [
        "Student Name",
        "Email",
        "Phone",
        "Country",
        "Course Enquired",
      ];

      //width
      var wscols = [
        { wch: 25 },
        { wch: 25 },
        { wch: 15 },
        { wch: 15 },
        { wch: 35 },
      ];

      var data = [];
      dataToAdd.forEach((element) => {
        // const badgeNumberCommaString = element.map(
        //   (x) => x.badgeNumber
        // ).join(",");

        data.push([
          element["name"],
          element["email"],
          element["phoneNumber"],
          element["country"],
          element["courseEnquired"],
        ]);
      });
      var wb = XLSX.utils.book_new();
      var ws = UIGridToSheet(data, columns);

      //width
      ws["!cols"] = wscols;

      XLSX.utils.book_append_sheet(wb, ws, sheetName);
      var wbout = XLSX.write(wb, wopts);
      const url = window.URL.createObjectURL(
        new Blob([SheetToArrayBuffer(wbout)], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Course enquired student.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
      // saveAs(
      //   new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }),
      //   fileName
      // );
    } catch (error) {
      this.setState({
        showPopupModal: true,
        popUpModalText: helperText.DataLoadError.downloadError,
        messageType: MessageType.Failure,
      });
    }
  };


  render() {
    const { isLoading, popUpModalText, messageType, showPopupModal } =
      this.state;
    return (
      <div style={{ backgroundColor: "#F1F4F6" }}>
        <AdminHeader currentTab="Students" />
        {isLoading && <Loader />}
        {/* Begin page content */}
        <main role="main" className="container page-height admin-main-body ">
          <div className="row">
            <div className="col-md-12">
              <h5 className="mt-3 gray">Students Enquired</h5>
            </div>
            <div className="col-md-12">
              <h6 className="mb-3 gray">
                Student Course Manager&gt;Students Enquired
              </h6>
            </div>
            <div
              className="col-lg-5 flex-item-left"
              style={{ paddingLeft: "15px" }}
            >
              <button
                type="button"
                className="btn btn btn-primary purple"
                data-toggle="modal"
                data-target="#myModal"
                onClick={this.downloadStudentData}
              >
                Download Report
              </button>
            </div>
            <div
              className="col-lg-7 flex-item-right"
              style={{ padding: "0px" }}
            >
              <form className="example1" style={{ float: "right" }}>
                <input
                  type="text"
                  placeholder="Search by Name/ID"
                  value={this.state.Search}
                  onChange={this.onSearchTextChange.bind(this)}
                />
                <button type="submit">
                  <i className="fa fa-search" />
                </button>
              </form>
            </div>
            <div
              className="table-responsive"
              style={{ paddingTop: "2em", border: "none" }}
            >
              <table className="table table-striped">
                <DataTable
                  columns={this.header}
                  data={this.state.enquiredStudenList}
                  noHeader={true}
                  pagination
                />
              </table>
            </div>

            <div className="col-md-12" style={{ paddingTop: "20px" }}>
              <button
                type="button"
                className="btn back-button"
                onClick={() => history.push("/StudentCourseManager")}
              >
                <i className="fa fa-angle-left" />
                Back to Course Manager
              </button>
            </div>
          </div>
        </main>
        <AdminFooter />
        {showPopupModal && (
          <PopupModal
            closeModal={this.closeModal}
            Text={popUpModalText}
            messageType={messageType}
          />
        )}
      </div>
    );
  }
}
