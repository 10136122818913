export default class ModuleModel{
    constructor(
        moduleId,
        uniqueId,
        modulePartNumber,
        moduleName,
        shortDescription,
        description,
        unitCoverImagePath,
        downloadUnitText,
        categoryOneSelected,
        categoryTwoSelected,
        categoryThreeSelected,
        moduleTypeId,
        moduleFileMediaID,
    ){
        this.moduleId=moduleId== undefined ? 0 : moduleId;
        this.uniqueId=uniqueId== undefined ? 0 : uniqueId;
        this.modulePartNumber=modulePartNumber==uniqueId?0:modulePartNumber;
        this.moduleName=moduleName== undefined ? "" : moduleName;
        this.shortDescription=shortDescription== undefined ? "" : shortDescription;
        this.description=description== undefined ? "" : description;
        this.unitCoverImagePath=unitCoverImagePath== undefined ? "" : unitCoverImagePath;
        this.downloadUnitText=downloadUnitText==undefined?"":downloadUnitText;
        this.categoryOneSelected=categoryOneSelected==undefined?false:categoryOneSelected;
        this.categoryTwoSelected=categoryTwoSelected==undefined?false:categoryTwoSelected;
        this.categoryThreeSelected=categoryThreeSelected==undefined?false:categoryThreeSelected;
        this.moduleTypeId=moduleTypeId==undefined?1:moduleTypeId;
        this.moduleFileMediaID=moduleFileMediaID==undefined?0:moduleFileMediaID;
    }
}