export const Role={
    Admin:1,
    Student:2,
}

export const MessageType={
    Success:1,
    Failure:2,
}

export const FinalResult={
    PASS:1,
    FAIL:2,
    RESUBMIT:3,
    NOTAPPLICABLE:4,
    DISTINCTION:5,
    MERIT:6,
}