import React, { Component } from "react";

export default class StudentFooter extends Component {
  render() {
    return (
      <footer id="footer" className="student-footer">
        <div className="row">
          <div className="col-md-12 text-center">
          Copyright {(new Date().getFullYear())} <span>IIMS</span> &nbsp; | &nbsp;{" "}
            &nbsp; <a onClick={()=> window.open(`${window.location.origin}/pdf/Disclaimer and Site Terms.pdf` , '_blank')} style={{cursor:'pointer'}}>Disclaimer and Site Terms</a>
          </div>
        </div>
      </footer>
    );
  }
}
