import React,{ Component } from "react";
import distancelearning from "../assets/distancelearning.png";
import { history } from "../_helpers/history";
import phone from "../assets/phone.png";
import EmailIcon from "../assets/EmailIcon.png";
import Address from "../assets/EmailIcon.png";

class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <section className="vbox">
        <header className="bg-white header header-md navbar navbar-fixed-top-xs box-shadow">
          <div className="spacer-container">
            <div className="navbar-header aside-md dk">
              {" "}
              <a onClick={() => history.push("/LoginPage")} className="navbar-brand">
                <img
                  src={distancelearning}
                  className="image-small"
                />
              </a>{" "}
            </div>
            <ul className="nav navbar-right m-n nav-user sidenav">
            <li className="hidden-xs hide-small">
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={() => history.push("/Register")}
                  >
                    Register
                  </button>
                </li>
            <li className="hidden-xs hide-small">
                  <button
                    type="button"
                    className="btn btn-primary"
                    //data-toggle="modal" data-target="#myModal"
                    onClick={() => history.push("/LoginPage")}
                  >
                    Login
                  </button>
                </li>
            </ul>
          </div>
        </header>
        <section>
          <section className="hbox stretch">
            {/* .aside */}
            <aside
              className="bg-black aside-md hidden-print hidden-xs"
              id="nav"
              style={{ display: "none" }}
            >
              <section className="vbox">
                <section className="w-f scrollable">
                  <div
                    className="slim-scroll"
                    data-height="auto"
                    data-disable-fade-out="true"
                    data-distance={0}
                    data-size="10px"
                    data-railopacity="0.2"
                  >
                    {/* nav */}
                    <nav className="nav-primary hidden-xs">
                      <div className="text-muted text-sm hidden-nav-xs padder m-t-sm m-b-sm">
                        Menu
                      </div>
                      <ul className="nav nav-main" data-ride="collapse">
                        <li className="active">
                          {" "}
                          <a href="index.html" className="auto">
                            {" "}
                            Why Us?
                          </a>{" "}
                        </li>
                        <li>
                          <a className="auto">
                            Courses &amp; Fees{" "}
                          </a>
                        </li>
                        <li className>
                          {" "}
                          <a href="index.html" className="auto">
                            {" "}
                            FAQs{" "}
                          </a>{" "}
                        </li>
                        <li className>
                          {" "}
                          <a href="index.html" className="auto">
                            Contact Us
                          </a>{" "}
                        </li>
                        <li className style={{ display: "inline-flex" }}>
                          <button
                            type="button"
                            className="btn btn-success"
                            style={{ margin: "10px" }}
                          >
                            Register
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-toggle="modal"
                            data-target="#myModal"
                            style={{ margin: "10px" }}
                          >
                            Login
                          </button>
                        </li>
                      </ul>
                    </nav>
                    {/* / nav */}
                  </div>
                </section>
                <footer className="footer hidden-xs no-padder text-center-nav-xs">
                  {" "}
                  <a
                    href="modal.lockme.html"
                    data-toggle="ajaxModal"
                    className="btn btn-icon icon-muted btn-inactive pull-right m-l-xs m-r-xs hidden-nav-xs"
                  >
                    {" "}
                    <i className="i i-logout" />{" "}
                  </a>{" "}
                  <a
                    href="#nav"
                    data-toggle="class:nav-xs"
                    className="btn btn-icon icon-muted btn-inactive m-l-xs m-r-xs"
                  >
                    {" "}
                    <i className="i i-circleleft text" />{" "}
                    <i className="i i-circleright text-active" />{" "}
                  </a>{" "}
                </footer>
              </section>
            </aside>
            {/* /.aside */}
            <section className="cover" style={{ marginTop: "1em" }}>
              <div className="row" style={{ paddingTop: "24px" }}>
                <div className="col-md-6">
                  <div
                    className="heading-background"
                    style={{ width: "160px" }}
                  >
                    <h1 className="heading-text">Why Us?</h1>
                  </div>
                  <div className="heading-background">
                    <h2 className="heading-text">
                      Distance learn with the International Institute of Marine
                      Surveying, the leading professional body for surveyors.
                    </h2>
                    <h2></h2>
                  </div>
                  <div className="heading-background">
                    <p className="heading-text">
                      {" "}
                      <strong>
                        Study with the only worldwide professional body to award
                        professional qualifications in marine surveying.
                      </strong>
                    </p>
                  </div>
                  <button
                    type="button"
                    style={{ fontWeight: 600 }}
                    className="btn registerbutton button-font"
                  >
                    Scroll down and search for the qualification that is right
                    for you.
                  </button>
                </div>
                <div className="col-md-6" style={{ paddingTop: "19px" }}>
                  <iframe
                    width="100%"
                    height={200}
                    src="https://www.youtube.com/embed/tgbNymZ7vqY"
                  ></iframe>
                  <div className="video-caption">
                    <p style={{ color: "#EDEDED" }}>
                      {" "}
                      <strong>
                        Click to learn more about IIMS education and
                        professional qualifications.
                      </strong>
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <section id="content1" className="course1">
              <div className="row">
                <div className="col-md-12">
                  <h1>Courses</h1>
                  <h2>
                    Professional Qualification in Commercial Ship Marine
                    Surveying
                  </h2>
                </div>
                <div style={{ padding: "15px",width:'100%' }}>
                  <div
                    className="col-md-12 courses-banner1"
                    style={{ padding: "20px" }}
                  >
                    <div className="col-md-5  course-card">
                      <div className="col-md-12">
                        <h4>
                          <strong>Explore this course</strong>
                        </h4>
                      </div>
                      <div className="col-md-12">
                        <a className="link-underline">
                          View course details and Modules{" "}
                        </a>
                      </div>
                      <div
                        className="col-md-12"
                        style={{ paddingTop: "7px", paddingBottom: "9px" }}
                      >
                        <a className="link-underline">
                          Download the course prospectus
                        </a>
                        <button
                          type="button"
                          className="btn btn-sm reg-btn btn-alignment1"
                        >
                          Register your interest
                        </button>
                      </div>
                      <div
                        className="col-md-12"
                        style={{ paddingBottom: "9px" }}
                      >
                        <a className="link-underline">
                          Read the course FAQs{" "}
                        </a>
                        <button
                          type="button"
                          className="btn btn-sm apply btn-alignment"
                        >
                          Apply to study this course
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7"></div>
                </div>
              </div>
            </section>
            <section id="content1" className="course2">
              <div className="row">
                <div className="col-md-12">
                  <h2>
                    Professional Qualification in Yacht &amp; Small Craft Marine
                    Surveying
                  </h2>
                </div>
                <div style={{ padding: "15px" ,width:'100%'}}>
                  <div
                    className="col-md-12 courses-banner2"
                    style={{ padding: "20px" }}
                  >
                    <div className="col-md-5  course-card">
                      <div className="col-md-12">
                        <h4>
                          <strong>Explore this course</strong>
                        </h4>
                      </div>
                      <div className="col-md-12">
                        <a className="link-underline">
                          View course details and Modules{" "}
                        </a>
                      </div>
                      <div
                        className="col-md-12"
                        style={{ paddingTop: "7px", paddingBottom: "9px" }}
                      >
                        <a className="link-underline">
                          Download the course prospectus
                        </a>
                        <button
                          type="button"
                          className="btn btn-sm reg-btn btn-alignment1"
                        >
                          Register your interest
                        </button>
                      </div>
                      <div
                        className="col-md-12"
                        style={{ paddingBottom: "9px" }}
                      >
                        <a className="link-underline">
                          Read the course FAQs{" "}
                        </a>
                        <button
                          type="button"
                          className="btn btn-sm apply btn-alignment"
                        >
                          Apply to study this course
                        </button>
                      </div>
                    </div>
                    <div className="col-md-7"></div>
                  </div>
                </div>
              </div>
            </section>
            <section id="content1" className="course3">
              <div className="row">
                <div className="col-md-12">
                  <h2>
                    Professional Qualification in Yacht &amp; Small Craft Marine
                    Surveying
                  </h2>
                </div>
                <div style={{ padding: "15px" ,width:'100%'}}>
                  <div
                    className="col-md-12 courses-banner3"
                    style={{ padding: "20px" }}
                  >
                    <div className="col-md-5 course-card">
                      <div className="col-md-12">
                        <h4>
                          <strong>Explore this course</strong>
                        </h4>
                      </div>
                      <div className="col-md-12">
                        <a className="link-underline">
                          View course details and Modules{" "}
                        </a>
                      </div>
                      <div
                        className="col-md-12"
                        style={{ paddingTop: "7px", paddingBottom: "9px" }}
                      >
                        <a className="link-underline">
                          Download the course prospectus
                        </a>
                      </div>
                      <div
                        className="col-md-12"
                        style={{ paddingBottom: "9px" }}
                      >
                        <a className="link-underline">
                          Read the course FAQs{" "}
                        </a>
                        <button
                          type="button"
                          className="btn btn-sm apply btn-alignment"
                        >
                          Apply to study this course
                        </button>
                      </div>
                    </div>
                    <div className="col-md-7"></div>
                  </div>
                </div>
              </div>
            </section>
            <footer id="footer">
              <div className="row">
                <div className="col-md-7 col-sm-12 col-xs-12">
                  <span className="text-color">
                    © International Institute of Marine Surveying 2021
                  </span>
                </div>
                <div className="col-md-5 col-sm-12 col-xs-12">
                  <ul className="links">
                    <li className="title">Contact Us</li>
                    <li>
                      <img src={phone} alt="phone" width="25px" />
                      <a
                        href="tel:+44 23 9238 5223"
                        className="footer-text-color"
                      >
                        {" "}
                        +44 23 9238 5223
                      </a>
                    </li>
                    <li>
                      {" "}
                      <img
                        src={EmailIcon}
                        alt="email"
                        width="25px"
                      />
                      <a
                        href="mailto: education@iims.org.uk"
                        className="footer-text-color"
                      >
                        {" "}
                        education@iims.org.uk
                      </a>
                    </li>
                    <li>
                      {" "}
                      <img
                        src={Address}
                        alt="email"
                        width="25px"
                      />{" "}
                      Murrills House, 48 East Street, Portchester, Fareham,
                      Hampshire PO16 9XS, UK
                    </li>
                  </ul>
                </div>
              </div>
            </footer>
            {/* Login Modal */}
            <div id="myModal" className="modal fade" role="dialog">
              <div className="modal-dialog">
                {/* Modal content*/}
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                    >
                      ×
                    </button>
                    <h4 className="modal-title">LOGIN</h4>
                  </div>
                  <div className="modal-body">
                    <form>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Username or Email Address
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Enter email"
                        />
                        <small
                          id="emailHelp"
                          className="form-text text-muted"
                          style={{ color: "red" }}
                        >
                          Enter Valid Email address/ Username
                        </small>
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">Password</label>
                        <input
                          type="password"
                          className="form-control"
                          id="exampleInputPassword1"
                          placeholder="Password"
                        />
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-6">
                          <div className="form-group form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="exampleCheck1"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleCheck1"
                            >
                              Remember Me
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6 text-right">
                          <button type="submit" className="btn btn-primary">
                            LOGIN
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </section>
    );
  }
}

export default LandingPage;
