import React, { Component } from "react";
import { xeroservice } from "../../_services/xero.service";

export default class XeroInvoices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
    };
  }

  componentDidMount = async () => {
    try{
      var response=await xeroservice.getInvoiceSync();
      if(response.status==200){
          this.setState({
              list:response.data,
          })
      }
    }catch (ex) { 
      return "";
    }
  };

  render() {
    return (
      <div>
        <h2>Xero Invoices in the Last 7 Days</h2>

        <table border="1">
          <tr>
            <th>Invoice #</th>
            <th>To</th>
            <th>Date</th>
            <th>Due Date</th>
            <th>Total Due</th>
          </tr>
          {this.state.list.map((invoice)=>{
         return(
            <tr>
              <td>{invoice.InvoiceNumber}</td>
              <td>{invoice.Contact.Name}</td>
              <td>{invoice.Date}</td>
              <td>{invoice.DueDate}</td>
              <td>{invoice.Total}</td>
            </tr>
         )})}
        </table>
      </div>
    );
  }
}
