import React, { Component } from "react";
import AdminHeader from "../../_components/AdminHeader";
import { history } from "../../_helpers/history";
import { UIGridToSheet, SheetToArrayBuffer } from "../../_helpers/utilities";
import { admindashboardservice } from "../../_services/admindashboard.service";
import PopupModal from "../../_helpers/PopupModal";
import Loader from "../../_helpers/Loader";
import AddStudent from "../../assets/Add Student.png";
import module from "../../assets/Modules.png";
import AddModule from "../../assets/Add module.png";
import OverDue from "../../assets/Over-due.png";
import Enrolled from "../../assets/Enrolled.png";
import Enqiury from "../../assets/Enqiury.png";
import StudentReport from "../../assets/Student Report.png";
import CourseReport from "../../assets/Course Report.png";
import Logout from "../../_helpers/Logout";
import AdminFooter from "../../_components/AdminFooter";
import { MessageType } from "../../_helpers/Enums";
import { helperText } from "../../_helpers/helpertext";
import UpcomingPayment from "../../assets/UpcomingPayment.png";

const XLSX = require("xlsx");
class DashboardPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      overdueFeesCount: 0,
      studentEnrolledCount: 0,
      newCoursesCount: 0,
      courseEnqiuryCount: 0,
      isLoading: false,
      enrolledStudentList: [],
      showPopupModal: false,
      popUpModalText: "",
      messageType: 0,
      newCourseList: [],
      studentReportCount: 0,
      studentResportList: [],
      overdueFeesList: [],
      studentOnHoldCount: 0,
      unitCount: 0,
      studentPerModuleCount: [],
      studentOnHoldList: [],
      studentNotCompletedIterationCount: 0,
      studentNotCompletedIteration: [],
      studentUnitFailedCount: 0,
      studentUnitFail: [],
      courseReportCount: 0,
      courseReport: [],
      upcomingPaymentCount: 0,
      upcomingPaymentList: [],
    };
  }

  /* <summary>
  date: 15-11-2021
  Name: VD
  description: This is used to get all the Dashboard Data
  <summary>*/
  componentDidMount = async () => {
    try {
      this.setState({
        isLoading: true,
      });
      var response = await admindashboardservice.GetDashboardData();
      if (response.status == 200) {
        this.setState({
          enrolledStudentList: response.data.studentModels,
          studentEnrolledCount: response.data.enrolledStudentCount,
          newCoursesCount: response.data.newCoursesCount,
          newCourseList: response.data.courseModels,
          courseEnqiuryCount: response.data.courseEnqiuryCount,
          studentReportCount: response.data.studentReportCount,
          studentResportList: response.data.studentAssignmentBridgeModels,
          overdueFeesCount: response.data.overdueFeesCount,
          overdueFeesList: response.data.overdueFees,
          studentOnHoldCount: response.data.studentOnHoldCount,
          unitCount: response.data.unitCount,
          studentPerModuleCount: response.data.studentPerModuleCount,
          studentOnHoldList: response.data.studentOnHoldModel,
          studentNotCompletedIterationCount:
            response.data.studentNotCompletedIterationCount,
          studentNotCompletedIteration:
            response.data.studentNotCompletedIteration,
          studentUnitFailedCount: response.data.studentUnitFailedCount,
          studentUnitFail: response.data.studentUnitFail,
          courseReportCount: response.data.courseReportCount,
          courseReport: response.data.studentIterationModels,
          upcomingPaymentCount: response.data.totalpaymentCount,
          upcomingPaymentList: response.data.upcomingPaymentDetailsModels,
        });
      } else if (response.status == 401) {
        Logout();
      }

      this.setState({
        isLoading: false,
      });
    } catch (e) {
      return "";
    }
  };

  ExportClicked = () => {
    try {
      if (this.state.enrolledStudentList.length !== 0) {
        this.download(this.state.enrolledStudentList);
      } else {
        this.setState({
          showPopupModal: true,
          popUpModalText: helperText.Dashboard.emptyExport,
          messageType: MessageType.Failure,
        });
      }
    } catch (error) {}
  };

  /* <summary>
  date: 27-07-2021
  Name: VD
  description: To Download the enrolled student list
  <summary>*/
  download(studentList) {
    try {
      var dataToAdd = [...studentList].map((item) => item);
      var sheetName = "sheet1";
      var wopts = { bookType: "xlsx", bookSST: true, type: "binary" };
      var fileName = "the_excel_file.xlsx";
      var columns = [
        "Membership Number",
        "Student Name",
        "Email",
        "Phone",
        "City",
        "Course",
        "Country",
      ];

      //width
      var wscols = [
        { wch: 18 },
        { wch: 25 },
        { wch: 30 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 15 },
      ];

      var data = [];
      dataToAdd.forEach((element) => {
        // const badgeNumberCommaString = element.map(
        //   (x) => x.badgeNumber
        // ).join(",");

        data.push([
          element["membershipNumber"],
          element["studentName"],
          element["email"],
          element["phone"],
          element["city"],
          element["course"],
          element["country"],
        ]);
      });
      var wb = XLSX.utils.book_new();
      var ws = UIGridToSheet(data, columns);

      //width
      ws["!cols"] = wscols;

      XLSX.utils.book_append_sheet(wb, ws, sheetName);
      var wbout = XLSX.write(wb, wopts);
      const url = window.URL.createObjectURL(
        new Blob([SheetToArrayBuffer(wbout)], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Student Enrolled.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
      // saveAs(
      //   new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }),
      //   fileName
      // );
    } catch (error) {
      this.setState({
        showPopupModal: true,
        popUpModalText: helperText.DataLoadError.downloadError,
        messageType: MessageType.Failure,
      });
    }
  }

  /* <summary>
  date: 21-08-2021
  Name: VD
  description: To export the course of last 6 months
  <summary>*/
  ExportCourseClicked = () => {
    try {
      if (this.state.newCourseList.length !== 0) {
        this.downloadNewCourse(this.state.newCourseList);
      } else {
        this.setState({
          showPopupModal: true,
          popUpModalText: helperText.Dashboard.emptyExport,
          messageType: MessageType.Failure,
        });
      }
    } catch (error) {}
  };

  /* <summary>
  date: 21-08-2021
  Name: VD
  description: To export the course of last 6 months
  <summary>*/
  downloadNewCourse(newCourseList) {
    try {
      var dataToAdd = [...newCourseList].map((item) => item);
      var sheetName = "sheet1";
      var wopts = { bookType: "xlsx", bookSST: true, type: "binary" };
      var fileName = "the_excel_file.xlsx";
      var columns = ["Category name", "Course name", "Created on"];
      var data = [];
      dataToAdd.forEach((element) => {
        data.push([
          element["courseCategoryTitle"],
          element["courseName"],
          element["createdOn"],
        ]);
      });

      //width
      var wscols = [{ wch: 55 }, { wch: 30 }, { wch: 20 }];

      var wb = XLSX.utils.book_new();
      var ws = UIGridToSheet(data, columns);

      //width
      ws["!cols"] = wscols;

      XLSX.utils.book_append_sheet(wb, ws, sheetName);
      var wbout = XLSX.write(wb, wopts);
      const url = window.URL.createObjectURL(
        new Blob([SheetToArrayBuffer(wbout)], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "New Courses.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
      // saveAs(
      //   new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }),
      //   fileName
      // );
    } catch (error) {
      this.setState({
        showPopupModal: true,
        popUpModalText: helperText.DataLoadError.downloadError,
        messageType: MessageType.Failure,
      });
    }
  }

  closeModal = () => {
    this.setState({
      showPopupModal: false,
    });
  };

  /* <summary>
  date: 21-08-2021
  Name: VD
  description: To Download the Student resport
  <summary>*/
  exportStudentReporClicked = () => {
    try {
      if (this.state.studentResportList.length !== 0) {
        this.downloadStudentReport(this.state.studentResportList);
      } else {
        this.setState({
          showPopupModal: true,
          popUpModalText: helperText.Dashboard.emptyExport,
          messageType: MessageType.Failure,
        });
      }
    } catch (error) {}
  };

  /* <summary>
  date: 21-08-2021
  Name: VD
  description: To Download the Student resport
  <summary>*/
  downloadStudentReport(studentResportList) {
    try {
      var dataToAdd = [...studentResportList].map((item) => item);
      var sheetName = "sheet1";
      var wopts = { bookType: "xlsx", bookSST: true, type: "binary" };
      var fileName = "the_excel_file.xlsx";
      var columns = [
        "Student Name",
        "Module Name",
        // "File Name",
        "Date submitted",
      ];
      var data = [];

      //width
      var wscols = [
        { wch: 55 },
        { wch: 30 },
        // {wch:20},
        { wch: 20 },
      ];

      dataToAdd.forEach((element) => {
        data.push([
          element["studentName"],
          element["moduleName"],
          // element["fileName"],
          element["createdOn"],
        ]);
      });
      var wb = XLSX.utils.book_new();
      var ws = UIGridToSheet(data, columns);

      //width
      ws["!cols"] = wscols;

      XLSX.utils.book_append_sheet(wb, ws, sheetName);
      var wbout = XLSX.write(wb, wopts);
      const url = window.URL.createObjectURL(
        new Blob([SheetToArrayBuffer(wbout)], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Student Report.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
      // saveAs(
      //   new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }),
      //   fileName
      // );
    } catch (error) {
      this.setState({
        showPopupModal: true,
        popUpModalText: helperText.DataLoadError.downloadError,
        messageType: MessageType.Failure,
      });
    }
  }

  exportOverDueFeesClicked = () => {
    try {
      if (this.state.overdueFeesList.length !== 0) {
        this.downloadOverDueStudentList(this.state.overdueFeesList);
      } else {
        this.setState({
          showPopupModal: true,
          popUpModalText: helperText.Dashboard.emptyExport,
          messageType: MessageType.Failure,
        });
      }
    } catch (error) {}
  };

  /* <summary>
  date: 15-11-2021
  Name: VD
  description: To export the list of student who have missed thier installment
  <summary>*/
  downloadOverDueStudentList = async (overdueFeesList) => {
    try {
      var dataToAdd = [...overdueFeesList].map((item) => item);
      var sheetName = "sheet1";
      var wopts = { bookType: "xlsx", bookSST: true, type: "binary" };
      var fileName = "the_excel_file.xlsx";
      var columns = ["Student Name", "Course", "Email address"];

      //width
      var wscols = [{ wch: 15 }, { wch: 20 }, { wch: 30 }];

      var data = [];
      dataToAdd.forEach((element) => {
        data.push([
          element["studentName"],
          element["course"],
          element["email"],
        ]);
      });
      var wb = XLSX.utils.book_new();
      var ws = UIGridToSheet(data, columns);

      //width
      ws["!cols"] = wscols;

      XLSX.utils.book_append_sheet(wb, ws, sheetName);
      var wbout = XLSX.write(wb, wopts);
      const url = window.URL.createObjectURL(
        new Blob([SheetToArrayBuffer(wbout)], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Overdue Fees.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
      // saveAs(
      //   new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }),
      //   fileName
      // );
    } catch (error) {
      this.setState({
        showPopupModal: true,
        popUpModalText: helperText.DataLoadError.downloadError,
        messageType: MessageType.Failure,
      });
    }
  };

  /* <summary>
  date: 06-10-2021
  Name: VD
  description: This method is called when the admin wants to know how many students are studing which Module
  <summary>*/
  ExportModuleStudentCountClicked = () => {
    try {
      if (this.state.studentPerModuleCount.length !== 0) {
        this.downloadStudentModuleCount(this.state.studentPerModuleCount);
      } else {
        this.setState({
          showPopupModal: true,
          popUpModalText: helperText.Dashboard.emptyExport,
          messageType: MessageType.Failure,
        });
      }
    } catch (error) {}
  };

  /* <summary>
  date: 06-10-2021
  Name: VD
  description: This method is called when the admin wants to know how many students are studing which Module
  <summary>*/
  downloadStudentModuleCount(studentPerModuleCount) {
    try {
      var dataToAdd = [...studentPerModuleCount].map((item) => item);
      var sheetName = "sheet1";
      var wopts = { bookType: "xlsx", bookSST: true, type: "binary" };
      var fileName = "the_excel_file.xlsx";
      var columns = ["Category name", "Module name", "Student count"];
      var data = [];

      //width
      var wscols = [{ wch: 55 }, { wch: 40 }, { wch: 12 }];

      dataToAdd.forEach((element) => {
        data.push([
          element["categoryName"],
          element["moduleName"],
          element["studentCount"],
        ]);
      });
      var wb = XLSX.utils.book_new();
      var ws = UIGridToSheet(data, columns);

      //width
      ws["!cols"] = wscols;

      XLSX.utils.book_append_sheet(wb, ws, sheetName);
      var wbout = XLSX.write(wb, wopts);
      const url = window.URL.createObjectURL(
        new Blob([SheetToArrayBuffer(wbout)], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Student count per module.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
      // saveAs(
      //   new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }),
      //   fileName
      // );
    } catch (error) {
      this.setState({
        showPopupModal: true,
        popUpModalText: helperText.DataLoadError.downloadError,
        messageType: MessageType.Failure,
      });
    }
  }

  /* <summary>
  date: 07-10-2021
  Name: VD
  description: This function is called when the admin wants the list of the number of students that have been put on hold
  <summary>*/
  exportOnHoldStudentList = () => {
    try {
      if (this.state.studentOnHoldList.length !== 0) {
        this.downloadOnHoldStudentList(this.state.studentOnHoldList);
      } else {
        this.setState({
          showPopupModal: true,
          popUpModalText: helperText.Dashboard.emptyExport,
          messageType: MessageType.Failure,
        });
      }
    } catch (error) {}
  };

  /* <summary>
  date: 07-10-2021
  Name: VD
  description: This function is called when the admin wants the list of the number of students that have been put on hold
  <summary>*/
  downloadOnHoldStudentList(studentOnHoldList) {
    try {
      var dataToAdd = [...studentOnHoldList].map((item) => item);
      var sheetName = "sheet1";
      var wopts = { bookType: "xlsx", bookSST: true, type: "binary" };
      var fileName = "the_excel_file.xlsx";
      var columns = ["student name", "Course", "Email address"];
      var data = [];

      //width
      var wscols = [{ wch: 20 }, { wch: 20 }, { wch: 30 }];

      dataToAdd.forEach((element) => {
        data.push([
          element["studentName"],
          element["course"],
          element["email"],
        ]);
      });
      var wb = XLSX.utils.book_new();
      var ws = UIGridToSheet(data, columns);

      //width
      ws["!cols"] = wscols;

      XLSX.utils.book_append_sheet(wb, ws, sheetName);
      var wbout = XLSX.write(wb, wopts);
      const url = window.URL.createObjectURL(
        new Blob([SheetToArrayBuffer(wbout)], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Students on hold.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
      // saveAs(
      //   new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }),
      //   fileName
      // );
    } catch (error) {
      this.setState({
        showPopupModal: true,
        popUpModalText: helperText.DataLoadError.downloadError,
        messageType: MessageType.Failure,
      });
    }
  }

  /* <summary>
  date: 07-10-2021
  Name: VD
  description: This is to print the list of all student who's course has exprired but they have not finished their iteration
  <summary>*/
  exportStudentIterationExpiredList = () => {
    try {
      if (this.state.studentNotCompletedIteration.length !== 0) {
        this.downloadStudentIterationExpiredList(
          this.state.studentNotCompletedIteration
        );
      } else {
        this.setState({
          showPopupModal: true,
          popUpModalText: helperText.Dashboard.emptyExport,
          messageType: MessageType.Failure,
        });
      }
    } catch (error) {}
  };

  /* <summary>
  date: 07-10-2021
  Name: VD
  description: This is to print the list of all student who's course has exprired but they have not finished their iteration
  <summary>*/
  downloadStudentIterationExpiredList(studentNotCompletedIteration) {
    try {
      var dataToAdd = [...studentNotCompletedIteration].map((item) => item);
      var sheetName = "sheet1";
      var wopts = { bookType: "xlsx", bookSST: true, type: "binary" };
      var fileName = "the_excel_file.xlsx";
      var columns = ["student name", "Email address", "Course"];
      var data = [];

      //width
      var wscols = [{ wch: 20 }, { wch: 30 }, { wch: 20 }];

      dataToAdd.forEach((element) => {
        data.push([
          element["studentName"],
          element["email"],
          element["course"],
        ]);
      });
      var wb = XLSX.utils.book_new();
      var ws = UIGridToSheet(data, columns);

      //width
      ws["!cols"] = wscols;

      XLSX.utils.book_append_sheet(wb, ws, sheetName);
      var wbout = XLSX.write(wb, wopts);
      const url = window.URL.createObjectURL(
        new Blob([SheetToArrayBuffer(wbout)], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Non iteration completed students.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
      // saveAs(
      //   new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }),
      //   fileName
      // );
    } catch (error) {
      this.setState({
        showPopupModal: true,
        popUpModalText: helperText.DataLoadError.downloadError,
        messageType: MessageType.Failure,
      });
    }
  }

  exportStudentUnitFailList = () => {
    try {
      if (this.state.studentUnitFail.length !== 0) {
        this.downloadStudentUnitFailList(this.state.studentUnitFail);
      } else {
        this.setState({
          showPopupModal: true,
          popUpModalText: helperText.Dashboard.emptyExport,
          messageType: MessageType.Failure,
        });
      }
    } catch (error) {}
  };

  /* <summary>
  date: 15-11-2021
  Name: VD
  description: To get the list of student who have failed their iteration
  <summary>*/
  downloadStudentUnitFailList(studentUnitFail) {
    try {
      var dataToAdd = [...studentUnitFail].map((item) => item);
      var sheetName = "sheet1";
      var wopts = { bookType: "xlsx", bookSST: true, type: "binary" };
      var fileName = "the_excel_file.xlsx";
      var columns = ["student name", "Course", "Intake", "Email address"];
      var data = [];
      dataToAdd.forEach((element) => {
        data.push([
          element["studentName"],
          element["course"],
          element["intake"],
          element["email"],
        ]);
      });
      var wb = XLSX.utils.book_new();
      var ws = UIGridToSheet(data, columns);

      XLSX.utils.book_append_sheet(wb, ws, sheetName);
      var wbout = XLSX.write(wb, wopts);
      const url = window.URL.createObjectURL(
        new Blob([SheetToArrayBuffer(wbout)], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Students Failed.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
      // saveAs(
      //   new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }),
      //   fileName
      // );
    } catch (error) {
      this.setState({
        showPopupModal: true,
        popUpModalText: helperText.DataLoadError.downloadError,
        messageType: MessageType.Failure,
      });
    }
  }

  /* <summary>
  date: 05-11-2021
  Name: VD
  description: This is to download the course report
  <summary>*/
  ExportCoureReportClicked = () => {
    try {
      if (this.state.courseReport.length !== 0) {
        this.downloadCourseReportList(this.state.courseReport);
      } else {
        this.setState({
          showPopupModal: true,
          popUpModalText: helperText.Dashboard.emptyExport,
          messageType: MessageType.Failure,
        });
      }
    } catch (error) {}
  };

  /* <summary>
  date: 05-11-2021
  Name: VD
  description: This is to download the course report
  <summary>*/
  downloadCourseReportList(courseReport) {
    try {
      var dataToAdd = [...courseReport].map((item) => item);
      var sheetName = "sheet1";
      var wopts = { bookType: "xlsx", bookSST: true, type: "binary" };
      var fileName = "the_excel_file.xlsx";
      var columns = ["Iteration Name", "Student Count"];
      var data = [];

      //width
      var wscols = [{ wch: 30 }, { wch: 12 }];

      dataToAdd.forEach((element) => {
        data.push([element["courseIterationTitle"], element["studentCount"]]);
      });
      var wb = XLSX.utils.book_new();
      var ws = UIGridToSheet(data, columns);

      //width
      ws["!cols"] = wscols;

      XLSX.utils.book_append_sheet(wb, ws, sheetName);
      var wbout = XLSX.write(wb, wopts);
      const url = window.URL.createObjectURL(
        new Blob([SheetToArrayBuffer(wbout)], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Iteration Report.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
      // saveAs(
      //   new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }),
      //   fileName
      // );
    } catch (error) {
      this.setState({
        showPopupModal: true,
        popUpModalText: helperText.DataLoadError.downloadError,
        messageType: MessageType.Failure,
      });
    }
  }

  /* <summary>
  date: 01-06-2022
  Name: VD
  description: This gets called when the user wants know the how much amount he is about to be recieved from which student
  <summary>*/
  ExportUpcomingPaymentClicked = () => {
    try {
      if (this.state.upcomingPaymentList.length !== 0) {
        this.downloadUpcomingPaymentList(this.state.upcomingPaymentList);
      } else {
        this.setState({
          showPopupModal: true,
          popUpModalText: helperText.Dashboard.emptyExport,
          messageType: MessageType.Failure,
        });
      }
    } catch (error) {}
  };

  /* <summary>
 date: 01-06-2022
 Name: VD
 description: This is used to download the file that contains the list of all payment related details
 <summary>*/
  downloadUpcomingPaymentList(newCourseList) {
    try {
      var dataToAdd = [...newCourseList].map((item) => item);
      var sheetName = "sheet1";
      var wopts = { bookType: "xlsx", bookSST: true, type: "binary" };
      var fileName = "the_excel_file.xlsx";
      var columns = [
        "Student Name",
        "Email",
        "Instalment Due Date",
        "Instalment amount",
      ];
      var data = [];
      dataToAdd.forEach((element) => {
        data.push([
          element["studentName"],
          element["email"],
          element["installmentDueDate"],
          element["remainingCost"],
        ]);
      });

      //width
      var wscols = [{ wch: 30 }, { wch: 30 }, { wch: 30 }, { wch: 20 }];

      var wb = XLSX.utils.book_new();
      var ws = UIGridToSheet(data, columns);

      //width
      ws["!cols"] = wscols;

      XLSX.utils.book_append_sheet(wb, ws, sheetName);
      var wbout = XLSX.write(wb, wopts);
      const url = window.URL.createObjectURL(
        new Blob([SheetToArrayBuffer(wbout)], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Upcoming Payments.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
      // saveAs(
      //   new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }),
      //   fileName
      // );
    } catch (error) {
      this.setState({
        showPopupModal: true,
        popUpModalText: helperText.DataLoadError.downloadError,
        messageType: MessageType.Failure,
      });
    }
  }

  render() {
    const {
      overdueFeesCount,
      studentEnrolledCount,
      newCoursesCount,
      courseEnqiuryCount,
      showPopupModal,
      popUpModalText,
      messageType,
      isLoading,
      studentReportCount,
      studentOnHoldCount,
      studentNotCompletedIterationCount,
      studentUnitFailedCount,
      unitCount,
      courseReportCount,
      upcomingPaymentCount,
    } = this.state;
    return (
      <div
        className="overall-font admin"
        style={{ backgroundColor: "#F1F4F6" }}
      >
        <AdminHeader currentTab="Home" />
        {showPopupModal && (
          <PopupModal
            closeModal={this.closeModal}
            Text={popUpModalText}
            messageType={messageType}
          />
        )}
        {isLoading && <Loader />}
        {/* Begin page content */}
        <main role="main" className="container Dashboard-main admin-main-body ">
          <div className="row">
            <div className="col-md-12">
              <div
                className="row dashboard-sec1"
                style={{ marginBottom: "12px" }}
              >
                <div className="col-12 col-md-6">
                  <h4 className="mt-3 mb-3 heading style">
                    International Institute of Marine Surveying Administration
                    Area
                  </h4>
                </div>
                <div className="col-12 col-md-6 dashboard-sec1-btn">
                  <button
                    type="button"
                    className="btn btn-primary upload-btn"
                    onClick={() => history.push("/ManageStudyMaterial")}
                  >
                    Manage Study Material
                  </button>

                  <button
                    type="button"
                    className="btn btn-primary upload-btn"
                    onClick={() => history.push("/ManageAutoNotification")}
                  >
                    Manage Auto Notifications
                  </button>

                  <button
                    type="button"
                    className="btn btn-primary upload-btn"
                    onClick={() => history.push("/UploadStudentData")}
                  >
                    Upload Student Data
                  </button>
                  {/* <a
                    style={{ paddingTop: "12px" }}
                    className="info-icon"
                    title="More informtion"
                  >
                    <img src={info} />
                  </a> */}
                </div>
              </div>
              {/*the row ends here*/}
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="boxed">
                <div className="table-responsive-sm">
                  <table className="table dashboard">
                    <tbody
                      onClick={this.exportOverDueFeesClicked}
                      style={{ cursor: "pointer" }}
                    >
                      <tr>
                        <td colSpan={1} style={{ paddingBottom: "27px" }}>
                          <img src={OverDue} />
                        </td>
                        <td colSpan={1} />
                      </tr>
                      <tr>
                        <td className="boldtext">
                          Overdue Fees
                          <br />
                          <span>Students</span>
                        </td>
                        <td
                          className="red-color"
                          style={{ textAlign: "center" }}
                          width="40%"
                        >
                          <a>
                            <span> {overdueFeesCount} </span>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="boxed">
                <div className="table-responsive-sm">
                  <table className="table dashboard">
                    <tbody>
                      <tr>
                        <td
                          colSpan={1}
                          style={{ paddingBottom: "24px", cursor: "pointer" }}
                          onClick={this.ExportClicked}
                        >
                          <img
                            src={Enrolled}
                            style={{ position: "relative", top: "-10px" }}
                          />
                        </td>
                        <td
                          colSpan={1}
                          style={{ paddingBottom: 0, textAlign: "center" }}
                          className="add-boxed-img"
                          onClick={() => history.push("/UploadStudentData")}
                        >
                          <img src={AddStudent} style={{ cursor: "pointer" }} />{" "}
                          <br />
                          <span>Add Student</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="boldtext">
                          Student Enrolled
                          <br />
                          <span>Enrolled till date</span>
                        </td>
                        <td
                          className="green-text"
                          style={{ textAlign: "center" }}
                          width="40%"
                        >
                          <a>
                            <span>{studentEnrolledCount} </span>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="boxed">
                <div className="table-responsive-sm">
                  <table className="table dashboard">
                    <tbody>
                      <tr>
                        <td colSpan={1} style={{ paddingBottom: "23px" }}>
                          <img
                            src={module}
                            style={{ position: "relative", top: "-10px" }}
                          />
                        </td>
                        <td
                          onClick={() => history.push("/CreateCourse")}
                          colSpan={1}
                          style={{ paddingBottom: 0, textAlign: "center" }}
                          className="add-boxed-img"
                        >
                          <img src={AddModule} style={{ cursor: "pointer" }} />{" "}
                          <br />
                          <span>Add Course</span>
                        </td>
                      </tr>
                      <tr
                        onClick={this.ExportCourseClicked}
                        style={{ cursor: "pointer" }}
                      >
                        <td className="boldtext">
                          New Courses
                          <br />
                          <span>Last six months</span>
                        </td>
                        <td
                          className="yellow"
                          style={{ textAlign: "center" }}
                          width="40%"
                        >
                          <a>
                            <span>{newCoursesCount} </span>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="boxed">
                <div className="table-responsive-sm">
                  <table className="table dashboard">
                    <tbody
                      onClick={this.ExportUpcomingPaymentClicked}
                      style={{ cursor: "pointer" }}
                    >
                      <tr>
                        <td colSpan={1} className="report-height">
                          <img src={UpcomingPayment} />
                        </td>
                        <td colSpan={1} style={{ textAlign: "center" }} />
                      </tr>
                      <tr>
                        <td className="boldtext">
                          Upcoming Payments
                          <br />
                          <span>Students</span>
                        </td>
                        <td
                          className="yellow"
                          style={{ textAlign: "center" }}
                          width="20%"
                        >
                          <a>
                            <span>{upcomingPaymentCount}</span>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="boxed">
                <div className="table-responsive-sm">
                  <table className="table dashboard">
                    <tbody
                      onClick={this.ExportCoureReportClicked}
                      style={{ cursor: "pointer" }}
                    >
                      <tr>
                        <td colSpan={1} className="report-height">
                          <img src={CourseReport} />
                        </td>
                        <td colSpan={1} style={{ textAlign: "center" }} />
                      </tr>
                      <tr>
                        <td className="boldtext">
                          Iteration Report
                          <br />
                          <span>Last one Year</span>
                        </td>
                        <td
                          className="yellow"
                          style={{ textAlign: "center" }}
                          width="20%"
                        >
                          <a>
                            <span>{courseReportCount}</span>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="boxed">
                <div className="table-responsive-sm">
                  <table className="table dashboard">
                    <tbody onClick={() => history.push("/StudentsEnquired")}>
                      <tr>
                        <td colSpan={1} style={{ paddingBottom: "0px" }}>
                          <img width="60px !important;" src={Enqiury} />
                        </td>
                        <td colSpan={1} style={{ textAlign: "center" }} />
                      </tr>
                      <tr>
                        <td className="boldtext">
                          Course Enquiry
                          <br />
                          <span>Last six months</span>
                        </td>
                        <td
                          className="blue-text"
                          style={{ textAlign: "center" }}
                          width="20%"
                        >
                          <a>
                            <span>{courseEnqiuryCount} </span>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="col-lg-12 col-md-12">
              <div className="boxed">
                <div className="table-responsive-sm">
                  <table className="table dashboard">
                    <tbody>
                      <tr>
                        <td
                          onClick={this.exportStudentReporClicked}
                          style={{ cursor: "pointer" }}
                        >
                          <img src={StudentReport} />
                        </td>
                        {/*added two more tds here*/}
                        <td
                          className="boldtext"
                          colSpan={1}
                          style={{
                            fontSize: "12px",
                            color: "",
                            cursor: "pointer",
                          }}
                          onClick={this.exportStudentReporClicked}
                        >
                          <span>Submission from students </span>
                        </td>
                        {/* <td
                          className="boldtext"
                          colSpan={1}
                          style={{ fontSize: "12px",cursor:'pointer' }}
                        >
                          <span>
                            Remarks <br /> from Assessors{" "}
                          </span>
                        </td> */}
                        <td
                          className="boldtext"
                          colSpan={1}
                          style={{ fontSize: "12px", cursor: "pointer" }}
                          onClick={this.exportOnHoldStudentList}
                        >
                          <span>
                            Students <br /> On-Hold{" "}
                          </span>
                        </td>
                        <td
                          className="boldtext"
                          colSpan={1}
                          style={{ fontSize: "12px", cursor: "pointer" }}
                          onClick={this.ExportModuleStudentCountClicked}
                        >
                          <span>
                            Students <br /> per Module{" "}
                          </span>
                        </td>
                        <td
                          className="boldtext"
                          colSpan={1}
                          style={{ fontSize: "12px", cursor: "pointer" }}
                          onClick={this.exportStudentIterationExpiredList}
                        >
                          <span>
                            {" "}
                            Non completed students <br /> following course
                            expiry{" "}
                          </span>
                        </td>
                        {/* <td
                          className="boldtext"
                          colSpan={1}
                          style={{ fontSize: "12px",cursor:'pointer' }}
                          onClick={this.exportStudentUnitFailList}
                        >
                          <span>
                            Student <br /> Failed Module{" "}
                          </span>
                        </td> */}
                      </tr>
                      <tr>
                        <td className="boldtext">
                          Student Report
                          <br />
                          <span>Students</span>
                        </td>
                        {/*added two more tds here*/}
                        <td
                          className="green-text"
                          style={{ textAlign: "center", cursor: "pointer" }}
                          width="12%"
                          onClick={this.exportStudentReporClicked}
                        >
                          <a>
                            <span>{studentReportCount}</span>
                          </a>
                        </td>
                        {/* <td
                          className="green-text"
                          style={{ textAlign: "center",cursor:'pointer' }}
                          width="12%"
                        >
                          <a>
                            <span>0</span>
                          </a>
                        </td> */}
                        <td
                          className="green-text"
                          style={{ textAlign: "center", cursor: "pointer" }}
                          width="12%"
                          onClick={this.exportOnHoldStudentList}
                        >
                          <a>
                            <span>{studentOnHoldCount}</span>
                          </a>
                        </td>
                        <td
                          className="green-text"
                          style={{ textAlign: "center", cursor: "pointer" }}
                          width="12%"
                          onClick={this.ExportModuleStudentCountClicked}
                        >
                          <a>
                            <span>{unitCount}</span>
                          </a>
                        </td>
                        <td
                          className="green-text"
                          style={{ textAlign: "center", cursor: "pointer" }}
                          width="12%"
                          onClick={this.exportStudentIterationExpiredList}
                        >
                          <a>
                            <span>{studentNotCompletedIterationCount}</span>
                          </a>
                        </td>
                        {/* <td
                          className="green-text"
                          style={{ textAlign: "center",cursor:'pointer' }}
                          width="12%"
                          onClick={this.exportStudentUnitFailList}
                        >
                          <a>
                            <span>{studentUnitFailedCount}</span>
                          </a>
                        </td> */}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="col-md-12 alert-col">
              <div className="alert alert-danger alert-dismissible">
                <a
                  className="close"
                  data-dismiss="alert"
                  aria-label="close"
                  style={{ color: "#fff !important", fontWeight: "bold" }}
                >
                  ×
                </a>
                <span style={{ fontSize: "16px" }}>
                  Welcome to the IIMS website administration area. If you are
                  not a member of the administration team, please leave this
                  site now. Your IP Address has been logged and we will trace
                  all unauthorised access to this area
                </span>
              </div>
            </div>
          </div>
        </main>
        <AdminFooter />
      </div>
    );
  }
}

export default DashboardPage;
