import React, { Component } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertFromHTML, EditorState } from "draft-js";
import { Input } from "reactstrap";
import Loader from "../../../_helpers/Loader";
import PopupModal from "../../../_helpers/PopupModal";
import { uploadfileservice } from "../../../_services/uploadfile.service";
import { ConvertToHtml, ConvertFromHTML } from "../../../_helpers/utilities";
import { MessageType } from "../../../_helpers/Enums";
import Deleteicon from "../../../assets/RedDeleteIcon.png";
import { adminservice } from "../../../_services/admin.service";
import { studentService } from "../../../_services/student.service";
import { isStringEmpty } from "../../../_helpers/string_helper";
import DataTable from "react-data-table-component";
import { helperText } from "../../../_helpers/helpertext";

export default class AddEditTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      templateName: "",
      subject: "",
      message: EditorState.createEmpty(),
      fileInputKey: this.generateRandomString(),
      errorMessage: {},
      hasMessage: false,
      showPopupModal: false,
      popUpModalText: "",
      messageType: 0,
      documentFile: null,
      courseEnquiryEmailTemplateId: 0,
      templateFileName: "",
      categoryList: [],
      templateList: [],
      AttachedFilesList: [],
      DeleteAttachedFilesList: [],
    };
  }

  componentDidMount = () => {
    this.setState({
      templateName: this.props.templateName == undefined ? "" : this.props.templateName,
      subject: this.props.templateSubject == undefined ? ""  : this.props.templateSubject,
      message: this.props.templateMessage == undefined ? EditorState.createEmpty() : ConvertFromHTML(this.props.templateMessage),
      courseEnquiryEmailTemplateId: this.props.courseEnquiryEmailTemplateId == undefined ? 0 : this.props.courseEnquiryEmailTemplateId,
      templateFileName: this.props.templateFileName == undefined ? "" : this.props.templateFileName,
    });

    var id =this.props.courseEnquiryEmailTemplateId == undefined ? 0 : this.props.courseEnquiryEmailTemplateId
    this.CourseEnquiryEmailTemplateAttachmentBridgeDataBasedOncourseEnquiryEmailTemplateId(id);

    this.getEmailTemplate();
    if (this.props.templateMessage == undefined) {
    } else {
      this.setState({ hasMessage: true });
    }
  };

  CourseEnquiryEmailTemplateAttachmentBridgeDataBasedOncourseEnquiryEmailTemplateId =
    async (courseEnquiryEmailTemplateId) => {
      try {
        if (courseEnquiryEmailTemplateId != 0) {
          var response = await adminservice.CourseEnquiryEmailTemplateAttachmentBridgeDataBasedOncourseEnquiryEmailTemplateId(
            courseEnquiryEmailTemplateId
            );
          if (response.status == 200) {
            this.setState({
              AttachedFilesList:response.data,
            })
          }
        }
      } catch (ex) {
        return "";
      }
    };

  generateRandomString = () => {
    return Math.random().toString(36);
  };

  CancelTemplate = () => {
    this.props.CancelTemplate(0);
  };

  subjectText = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      errorMessage: {},
    });
  };

  HandleTextChange = (editorState, name) => {
    var empty = editorState.getCurrentContent().hasText();
    this.setState({
      [name]: editorState,
      errorMessage: {},
      hasMessage: empty,
    });
  };

  DeleteFile = (row) => {   
    let attachedlist = this.state.AttachedFilesList;
     var ins = attachedlist.length;   
      for (var x = 0; x < ins; x++) {
          if (attachedlist[x].name == row.name) {
              if (attachedlist[x].size != null && attachedlist[x].size != undefined) { 
                  const items = this.state.AttachedFilesList.filter(AttachedFilesList => AttachedFilesList.name !== row.name);
                  this.setState({ AttachedFilesList: items });
              }
              else {
                  this.setState((prevState) => ({
                      DeleteAttachedFilesList: [...prevState.DeleteAttachedFilesList, row.courseEnquiryEmailTemplateAttachmentBridgeId],
                  }));
                  const items = this.state.AttachedFilesList.filter(AttachedFilesList => AttachedFilesList.name !== row.name);
                  this.setState({ AttachedFilesList: items });
              }
          }
    }
      
  }

  AttachedFilesColumns = [
    {
      cell: (row) => <td className="center">{row.name}</td>,
      center: true,
      grow: 1,
    },
    {
      cell: (row) => (
        <td
          className="center material-tab"
          onClick={() => this.DeleteFile(row)}
          style={{ cursor: "pointer" }}
        >
         <img
                      src={Deleteicon}
                      className="manage-notification-deleteIcon"
                  />
        </td>
      ),
      center: true,
    },
  ];

  onFileChange = (e) => {
    if (!e.target.value) {
      this.setState({
        fileInputKey: this.generateRandomString(),
        documentFile: null,
        AttachedFilesList: null,
      });
      return;
    }

    let files = e.target.files;

    var ins = files.length;

    for (var i = 0; i < ins; i++) {
      let attachedFile = files[i]; 
      this.setState((prevState) => ({
        AttachedFilesList: [...prevState.AttachedFilesList, attachedFile],
      }));
    }

    this.setState({
      documentFile: files,
    });
  };

  sendEmail = async (e) => {
    try {
      e.preventDefault();
      const {
        templateName,
        subject,
        message,
        documentFile,
        courseEnquiryEmailTemplateId,
          AttachedFilesList,
          DeleteAttachedFilesList
      } = this.state; 
      var value = ConvertToHtml(message);
      if (this.validate()) {
        this.setState({
          isLoading: true,
        }); 
        let data = new FormData();
        data.append("name", templateName);
        data.append("subject", subject);
          data.append("message", value);
          data.append("AttachementsToDeleted", DeleteAttachedFilesList);
        var ins = AttachedFilesList.length;   
          for (var x = 0; x < ins; x++) {
              if (AttachedFilesList[x].size != null && AttachedFilesList[x].size != undefined) { 
                  data.append(`file_${x}`, AttachedFilesList[x]);
              }
        }
        data.append(
          "courseEnquiryEmailTemplateId",
          courseEnquiryEmailTemplateId
        );

        var response = await uploadfileservice.AddEditTemplate(data);
        if (response.status == 200) {
          this.setState({
            templateName: "",
            subject: "",
            message: EditorState.createEmpty(),
          });
          this.props.CancelTemplate(1);
        }
        this.setState({
          isLoading: false,
        });
      }
    } catch (ex) {
      return "";
    }
  };

  validate = () => {
    const { templateName, subject, message, hasMessage } = this.state;

    try {
      var isValid = true;
      if (templateName == "" || subject == "" || hasMessage == false)
        isValid = false;

      if (templateName == "") {
        var error = this.state.errorMessage;
        error["emptyname"] = "*Please enter the templateName";
        this.setState({ errorMessage: error });
      }
      if (subject == "") {
        var error = this.state.errorMessage;
        error["emptysubject"] = "*Please enter the Subject";
        this.setState({ errorMessage: error });
      }
      if (hasMessage == false) {
        var error = this.state.errorMessage;
        error["emptymessage"] = "*Please type a message to send.";
        this.setState({ errorMessage: error });
      }

      return isValid;
    } catch (error) {}
  };

  closeModal = () => {
    this.setState({
      showPopupModal: false,
    });
  };

  OnEventChange(event) {
    this.setState({
      templateName: event.target.value,
      errorMessage: {},
    });
    this.getSelectedTemplateData(event.target.value);
  }

  getSelectedTemplateData = async (templateName) => {
    var response = await adminservice.GetSelectedTemplateData(templateName);
    if (response.status == 200) {
      this.setState({
        subject: isStringEmpty(response.data.emailSubject)
          ? ""
          : response.data.emailSubject,
        message: isStringEmpty(response.data.emailMessage)
          ? EditorState.createEmpty()
          : ConvertFromHTML(response.data.emailMessage),
        templateFileName: isStringEmpty(response.data.attachmentileMediaName)
          ? ""
          : response.data.attachmentileMediaName,
        courseEnquiryEmailTemplateId:
          response.data.courseEnquiryEmailTemplateID,
      });
    } else {
      this.setState({
        popUpModalText: helperText.DataLoadError.loadError,
        showPopupModal: true,
        messageType: MessageType.Failure,
      });
    }
  };

  getEmailTemplate = async () => {
    try {
      var response = await studentService.GetEmailTemplateList();
      if (response.status == 200) {
        this.setState({
          templateList: response.data,
        });
      }
    } catch (error) {}
  };

  render() {
    const {
      isLoading,
      templateName,
      subject,
      message,
      fileInputKey,
      popUpModalText,
      messageType,
      showPopupModal,
      templateFileName,
      courseEnquiryEmailTemplateId,
      categoryList,
      templateList,
      AttachedFilesList,
    } = this.state;
    return (
      <section className="read-msg-sec">
        {isLoading && <Loader />}
        {showPopupModal && (
          <PopupModal
            closeModal={this.closeModal}
            Text={popUpModalText}
            messageType={messageType}
          />
        )}
        <div className="container">
          <div className="row">
            <div className=" col-12 col-md-12">
              <h5 className="mt-3 mb-3 gray">Message Template</h5>
            </div>
            <div className="col-12 col-md-10">
              <form className="send-msg-form">
                <div className="form-group row">
                  <span
                    htmlFor
                    className="col-sm-1 col-form-label"
                    style={{ paddingTop: "6px" }}
                  >
                    Name
                  </span>
                  <div className="col-sm-11">
                    <div>
                      {/* <select
                            className="form-control1 courses"
                            id="courses"
                            name="templateName"
                            value={templateName}
                            onChange={e=>this.subjectText(e)}
                            style={{
                              
                              marginRight: "20px",
                              width: "100%",
                            }}
                          >
                            <option value="" selected disabled> Select Category </option>
                            <option value="Professional Qualification in Yacht & Small Craft Marine Surveying">Professional Qualification in Yacht & Small Craft Marine Surveying </option>
                            <option value="Professional Qualification in Commercial Ship Marine Surveying">Professional Qualification in Commercial Ship Marine Surveying </option>
                            <option value="Professional Qualification in Marine Corrosion">Professional Qualification in Marine Corrosion</option>
                          </select> */}
                      <input
                        list="ShowDataList"
                        placeholder="Search / Select"
                        className="input101"
                        name="templateName"
                        id="templateName"
                        value={templateName}
                        onChange={this.OnEventChange.bind(this)}
                        style={{ width: "100%", height: "28px" }}
                      />

                      <datalist id="ShowDataList">
                        {templateList != undefined &&
                          templateList.map((item, i) => {
                            return (
                              <option value={item.templateName} key={i}>
                                {item.templateName}
                              </option>
                            );
                          })}
                      </datalist>
                    </div>
                    <div
                      style={{
                        display: this.state.errorMessage.emptyname
                          ? "contents"
                          : "none",
                        color: "red",
                      }}
                    >
                      {this.state.errorMessage.emptyname}
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <span
                    htmlFor
                    className="col-sm-1 col-form-label"
                    style={{ paddingTop: "6px" }}
                  >
                    Subject
                  </span>
                  <div className="col-sm-11">
                    <div>
                      <input
                        type="text"
                        name="subject"
                        className="form-control "
                        value={subject}
                        onChange={(e) => this.subjectText(e)}
                      />
                    </div>
                    <div
                      style={{
                        display: this.state.errorMessage.emptysubject
                          ? "contents"
                          : "none",
                        color: "red",
                      }}
                    >
                      {this.state.errorMessage.emptysubject}
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <span
                    htmlFor="inputPassword"
                    className="col-sm-1 col-form-label"
                    style={{ paddingTop: "40px" }}
                  >
                    Message
                  </span>
                  <div className="col-sm-11">
                    {/* <textarea className="form-control" id="exampleFormControlTextarea1" rows={3} defaultValue={""} /> */}

                    <Editor
                      editorStyle={{
                        minHeight: 170,
                        background: "#F1F4F6",
                        padding: "0 8px",
                      }}
                      editorState={message}
                      wrapperClassName="demo-wrapper sendtext-area-bg"
                      editorClassName="editer-content"
                      toolbarClassName="toolbar-class"
                      onEditorStateChange={(editorState) =>
                        this.HandleTextChange(editorState, "message")
                      }
                      toolbar={{
                        options: ["inline"],
                        inline: {
                          inDropdown: false,
                          options: ["bold", "italic", "underline"],
                        },
                      }}
                    />
                    <div
                      style={{
                        display: this.state.errorMessage.emptymessage
                          ? "contents"
                          : "none",
                        color: "red",
                      }}
                    >
                      {this.state.errorMessage.emptymessage}
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <span
                    htmlFor="inputPassword"
                    className="col-sm-1 col-form-label"
                  >
                    Attach
                  </span>
                  {/* {templateFileName == "" ? ( */}
                    <div className=" col-12 col-sm-6">
                      <Input
                        type="file"
                        accept=".pdf,.xlsx, .xls,.doc,.docx"
                        // accept=" .doc, .docx, .pdf"
                        name="documentFile"
                        multiple="multiple"
                        onChange={this.onFileChange}
                        key={fileInputKey || ""}
                        style={{
                          width: "95%",
                          overflow: "revert",
                          whiteSpace: "break-spaces",
                          color: "transparent",
                          cursor:'pointer',
                        }}
                      />
                      <div id="selectedRefereceLetters" />
                      <DataTable
                        columns={this.AttachedFilesColumns}
                        data={AttachedFilesList}
                        noHeader={true}
                      />
                    </div>
                  {/*) : (
                    <div>
                    {templateFileName}
                    <img
                      src={Deleteicon}
                      className="manage-notification-deleteIcon"
                      onClick={()=>this.setState({templateFileName:"",courseEnquiryEmailTemplateId:0})}
                  />
                  <DataTable
                        columns={this.AttachedFilesColumns}
                        data={AttachedFilesList}
                        noHeader={true}
                      />
                  </div>
                  )}*/}
                </div>
                <div className="form-group row">
                  <div className="col-sm-12">
                    <button
                      type="submit"
                      className="btn btn-primary mb-2 custom-send-btn"
                      onClick={this.CancelTemplate}
                    >
                      Cancel
                    </button>
                    {courseEnquiryEmailTemplateId == 0 ? (
                      <button
                        type="submit"
                        className="btn btn-primary mb-2 custom-send-btn"
                        style={{ marginLeft: "10px" }}
                        onClick={this.sendEmail}
                      >
                        Save
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-primary mb-2 custom-send-btn"
                        style={{ marginLeft: "10px" }}
                        onClick={this.sendEmail}
                      >
                        Update
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
