import React, { Component } from "react";
import Header from "../../../_components/Header";
import { courseService } from "../../../_services/Course.service";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Loader from "../../../_helpers/Loader";
import PopupModal from "../../../_helpers/PopupModal";
import Logout from "../../../_helpers/Logout";
import StudentFooter from "../../../_components/StudentFooter";
import { MessageType } from "../../../_helpers/Enums";
import { helperText } from "../../../_helpers/helpertext";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
  },
};

class Courses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      courseData: [],
      showPopupModal: false,
      popUpModalText: "",
      messageType: 0,
    };
  }

  /* <summary>
  date: 15-11-2021
  Name: VD
  description: To get the list of all courses
  <summary>*/
  componentDidMount() {
    try {
      this.setState({ isLoading: true }, async () => {
        var response = await courseService.GetAllCourseList();
        if (response.status === 200) {
          this.setState({
            courseData: response.data,
          });
        } else if (response.status == 401) {
          Logout();
        } else {
          this.setState({
            popUpModalText: helperText.DataLoadError.loadError,
            showPopupModal: true,
            messageType: MessageType.Failure,
          });
        }
      });
      this.setState({
        isLoading: false,
      });
    } catch (ex) {
      return "";
    }
  }

  closeModal = () => {
    this.setState({
      showPopupModal: false,
    });
  };

  render() {
    const {
      isLoading,
      courseData,
      popUpModalText,
      showPopupModal,
      messageType,
    } = this.state;
    return (
      <div>
        <section className="vbox">
          <Header currentPage="Courses" />
          {isLoading && <Loader />}
          <section>
            <section className="hbox stretch">
              <section id="coursenbanner">
                <div className="row">
                  <div className="col-md-12">
                    <h2>
                      Professional Qualification in Commercial Ship Marine
                      Surveying
                    </h2>
                    <h2>
                      <p>Core Module 1</p>
                    </h2>
                  </div>
                </div>
              </section>
              <section className="courselist" id="content">
                {/* Module 1 */}
                <div className="row">
                  <div className="col-md-12">
                    <h4>
                    Module 1 - <small>Expires on 23rd Oct 2021</small>
                    </h4>
                  </div>

                  <div className="col-md-12">
                    <div className="courseCards">
                      <div style={{ width: "100%", display: "inline-block" }}>
                        <Carousel
                          ref={(el) => (this.Carousel = el)}
                          swipeable={false}
                          draggable={false}
                          showDots={false}
                          responsive={responsive}
                          ssr={true} // means to render carousel on server-side.
                          infinite={false}
                          focusOnSelect={true}
                          // autoPlay={
                          //   this.props.deviceType !== "mobile" ? true : false
                          // }

                          //autoPlaySpeed={1000}
                          keyBoardControl={true}
                          customTransition="all .5"
                          transitionDuration={500}
                          containerClass="carousel-container"
                          //removeArrowOnDeviceType={["tablet", "mobile"]}
                          deviceType={this.props.deviceType}
                          dotListClass="custom-dot-list-style"
                          itemClass="carousel-item-padding-40-px"
                        >
                          {courseData.map((item) => {
                            return (
                              <div className="columno">
                                <div className="post-module hover">
                                  <div className="thumbnail img-bg"></div>
                                  <div className="post-content">
                                    <h3 className="title">{item.courseName}</h3>
                                    <img src="images/task-complete.png" />
                                    5hr 30min
                                    <p className="description crop">
                                      {item.courseDescription}
                                    </p>
                                    <div className="clearfix" />
                                    <div className="clearfix" />
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </Carousel>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Module 2 */}
                <div className="row">
                  <br />
                  <div className="col-md-12">
                    <h4>
                    Module 2 - <small>Expires on 30th Nov 2021</small>
                    </h4>
                  </div>
                  <div className="col-md-3 col-sm-3 col-xs-6">
                    <div className="courseCards">
                      <div style={{ width: "100%", display: "inline-block" }}>
                        <div className="columno">
                          <div className="post-module hover">
                            <div className="thumbnail img-bg"></div>
                            <div className="post-content">
                              <h3 className="title">Specialist Module 1</h3>
                              <img src="images/task-complete.png" />
                              5hr 30min
                              <p className="description">
                                Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry. Lorem Ipsum has been
                                the industry's standard dummy
                              </p>
                              <div className="clearfix" />
                              <div className="clearfix" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-3 col-xs-6">
                    <div className="courseCards">
                      <div style={{ width: "100%", display: "inline-block" }}>
                        <div className="columno">
                          <div className="post-module hover">
                            <div className="thumbnail img-bg"></div>
                            <div className="post-content">
                              <h3 className="title">Specialist Module 2</h3>
                              <img
                                className="reload"
                                src="images/reload-circular-arrow-symbol.png"
                              />
                              5hr 30min
                              <p className="description">
                                Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry. Lorem Ipsum has been
                                the industry's standard dummy
                              </p>
                              <div className="clearfix" />
                              <div className="clearfix" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-3 col-xs-6">
                    <div className="courseCards">
                      <div style={{ width: "100%", display: "inline-block" }}>
                        <div className="columno">
                          <div className="post-module hover">
                            <div className="thumbnail img-bg">
                              <img
                                className="floatingcalender"
                                src="images/new-tab.png"
                              />
                            </div>
                            <div className="post-content">
                              <h3 className="title">Specialist Module 3</h3>
                              <img
                                className="reload"
                                src="images/reload-circular-arrow-symbol.png"
                              />
                              5hr 30min
                              <p className="description">
                                Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry. Lorem Ipsum has been
                                the industry's standard dummy
                              </p>
                              <div className="clearfix" />
                              <div className="clearfix" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-3 col-xs-6">
                    <div className="courseCards">
                      <div style={{ width: "100%", display: "inline-block" }}>
                        <div className="columno">
                          <div className="post-module hover">
                            <div className="thumbnail img-bg">
                              <img
                                className="floatingcalender"
                                src="images/new-tab.png"
                              />
                            </div>
                            <div className="post-content">
                              <h3 className="title">Specialist Module 4</h3>
                              <img className="spam" src="images/spam.png" />
                              5hr 30min
                              <p className="description">
                                Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry. Lorem Ipsum has been
                                the industry's standard dummy
                              </p>
                              <div className="clearfix" />
                              <div className="clearfix" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ul className="pagination m-t-sm m-b-sm">
                  <li>
                    <a>
                      <i className="fa fa-chevron-left" />
                    </a>
                  </li>
                  <li className="active">
                    <a>1</a>
                  </li>
                  <li>
                    <a>2</a>
                  </li>
                  <li>
                    <a>3</a>
                  </li>
                  <li>
                    <a>4</a>
                  </li>
                  <li>
                    <a>5</a>
                  </li>
                  <li>
                    <a>
                      <i className="fa fa-chevron-right" />
                    </a>
                  </li>
                </ul>
              </section>
            </section>
          </section>
        </section>
        <StudentFooter />
        {showPopupModal && (
          <PopupModal
            closeModal={this.closeModal}
            Text={popUpModalText}
            messageType={messageType}
          />
        )}
      </div>
    );
  }
}

export default Courses;
