import React, { Component } from "react";

export default class AdminFooter extends Component {
  render() {
    return (
      <footer className="admin-footer-bar ">
        <div className="container">
          <span className="text-muted  small-text">
            © International Institute of Marine Surveying {(new Date().getFullYear())}
          </span>
        </div>
      </footer>
    );
  }
}
