import React, { Component } from "react";
import AdminHeader from "../../../_components/AdminHeader";
import Loader from "../../../_helpers/Loader";
import eye from "../../../assets/eye.png";
import PopupModal from "../../../_helpers/PopupModal";
import { adminservice } from "../../../_services/admin.service";
import Logout from "../../../_helpers/Logout";
import { history } from "../../../_helpers/history";
import DataTable from "react-data-table-component";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import AdminFooter from "../../../_components/AdminFooter";
import { MessageType } from "../../../_helpers/Enums";
import { helperText } from "../../../_helpers/helpertext";

class ManageStudyMaterial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      moduleList: [],
      showPopupModal: false,
      popUpModalText: "",
      messageType:0,
      checkedBoxes: [],
    };
  }

  componentDidMount = async () => {
    this.setState({
      isLoading: true,
    });
    this.LoadData();
  };

  /* <summary>
  date: 15-11-2021
  Name: RA
  description: To Get all Modules
  <summary>*/ 
  LoadData = async () => {
    try{
      var response = await adminservice.GetAllModuleList();
      if (response.status == 200) {
        this.setState({
          moduleList: response.data,
          isLoading: false,
        });
      } else if (response.status == 401) {
        Logout();
      } else {
        this.setState({
          popUpModalText: helperText.DataLoadError.loadError,
          showPopupModal: true,
          messageType:MessageType.Failure,
          isLoading: false,
        });
      }
    }catch (e) {
      return "";
    }
  };

  header = [
    {
      name: (
        <th className="text-center" scope="col">
          {" "}
          Module ID{" "}
        </th>
      ),
      selector: "uniqueId",
      cell: (row) => <div>{row.uniqueId}</div>,
      center: true,
      width: "100px",
    },
    {
      name: (
        <th className="text-center" scope="col">
          {" "}
          Unique Identifier{" "}
        </th>
      ),
      selector: "moduleId",
      cell: (row) => <div>{row.moduleId}</div>,
      center: true,
      width: "100px",
    },
    {
      name: (
        <th className="text-center" scope="col">
          {" "}
          Module Part{" "}
        </th>
      ),
      selector: "modulePartNumber",
      cell: (row) => <div>{row.modulePartNumber}</div>,
      center: true,
      width: "100px",
    },
    {
      name: (
        <th className="text-center" scope="col">
          {" "}
          Module Name{" "}
        </th>
      ),
      selector: "Title",
      cell: (row) => (
        <div
          
        >
          {row.moduleName}
        </div>
      ),
      left: true,
      width: "530px",
    },
    {
      name: (
        <th className="text-center" scope="col">
          {" "}
          Last Updated On{" "}
        </th>
      ),
      selector: "Title",
      cell: (row) => <div>{row.editedOnDatePart}</div>,
      center: true,
      width: "200px",
    },
    {
      name: (
        <th className="text-center" scope="col">
          {" "}
          Preview{" "}
        </th>
      ),
      cell: (row) => (
        <img
          src={eye}
          width="30px"
          //onClick={() => history.push(`/AddEditUnitAddDynamicContent/${row.moduleId}`)}
          onClick={() => history.push(`/CourseDetails/${row.moduleId}/${true}`)}
          style={{ cursor: "pointer" }}
        />
      ),
      center: true,
      width: "80px",
    },
  ];

  handleChange = (state) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data 
    this.setState({
      checkedBoxes: state.selectedRows,
      isChanged: true,
    });
  };

  validateCheckbox = () => {
    const { checkedBoxes } = this.state;
    try {
      var isValid = true;
      if (checkedBoxes.length==0) isValid = false;

      if (checkedBoxes.length==0) {
        this.setState({
          popUpModalText: helperText.ManageStudyMaterial.selectOneCheckboxToDelete,
          showPopupModal: true,
          messageType:MessageType.Failure,
        })
      }
      return isValid;
    } catch (error) { 
    }
  };

  createUnit = () => {
    
    history.push(`/AddEditUnitAddDynamicContent/${0}`);
  };

  updateExistingUnit = async (moduleID) => {
   
    history.push(`/AddEditUnitAddDynamicContent/${moduleID}`);
  };

  /* <summary>
  date: 15-11-2021
  Name: RA
  description: To create a new version of the same Module
  <summary>*/ 
  createNewVersionOfUnit = async (moduleID) => {
    try{
      var newmoduleresponse = await adminservice.CreateNewVersionofModuleBasedOnModuleID(moduleID);
      if (newmoduleresponse.status == 200) {
        var newmoduleID = newmoduleresponse.data;
        history.push(`/AddEditUnitAddDynamicContent/${newmoduleID}`);
      } else if (newmoduleresponse.status == 401) {
        Logout();
      } else {
        this.setState({
          popUpModalText: helperText.AddEditUnitAddDynamicContent.moduleLoadError,
          showPopupModal: true,
          messageType:MessageType.Failure,
        });
      }
    } catch (e) {
      return "";
    }
  };

  /* <summary>
  date: 15-11-2021
  Name: RA
  description: This is used to delete the Module
  <summary>*/ 
  deleteUnit = async () => {
    try{
      this.setState({
        isLoading: true,
      });
      const { checkedBoxes } = this.state;
      if (this.validateCheckbox()) {
        var response = await adminservice.DeleteSelectedModules(checkedBoxes);
        if (response.status == 200) {
          this.setState({
            courseList: response.data,
            checkedBoxes: [],
            popUpModalText: helperText.ManageStudyMaterial.moduleDeleted,
            showPopupModal: true,
            messageType:MessageType.Success,
          });
          this.LoadData();
        } else {
          this.setState({
            popUpModalText: helperText.ManageStudyMaterial.moduleDeleteError,
            showPopupModal: true,
            messageType:MessageType.Failure,
          });
        }
      }
      this.setState({
        isLoading: false,
      });
    } catch (e) {
      return "";
    }
  };

  closeModal = () => {
    this.setState({
      showPopupModal: false,
    });
  };

  UpdateUnitConfirm = async (e) => {
    const { checkedBoxes } = this.state;
    if (checkedBoxes.length == 1) {
      var moduleID = checkedBoxes[0].moduleId;
      confirmAlert({
        title: "Confirm",
        message:
          "Do you want to create a new version of this Module? Click on 'Yes' if you want to create a new version, else click on 'No' to continue update current version.",
        buttons: [
          {
            label: "Yes",
            onClick: () => this.createNewVersionOfUnit(moduleID),
          },
          {
            label: "No",
            onClick: () => this.updateExistingUnit(moduleID),
          },
        ],
        closeOnEscape: true,
        closeOnClickOutside: true,
      });
    } else {
      this.setState({
        popUpModalText: helperText.ManageStudyMaterial.selectModuleToUpdate,
        showPopupModal: true,
        messageType:MessageType.Failure,
      });
    }
  };

  render() {
    const { 
      isLoading, 
      popUpModalText, 
      showPopupModal,
      messageType,
    } = this.state;
    return (
      <div className="overall-font" style={{ backgroundColor: "#F1F4F6" }}>
        <AdminHeader currentTab="Students" />
        {isLoading && <Loader />}
        {/* Begin page content */}
        <main role="main" className="container admin-main-body ">
          <div class="row">
            <div class="col-md-12">
              <div class="row dashboard-sec1" style={{ marginBottom: "12px" }}>
                <div class="col-12 col-md-10">
                  <h3 class="mt-3 mb-3 heading style">Manage Study Material</h3>
                </div>
                <div class="col-12 col-md-2">
                  {/* <a title="More informtion">
                    <img src={info} style={{ marginTop: "32px" }} />{" "}
                  </a> */}
                </div>
              </div>
            </div>

            <div class="col-md-12" style={{paddingLeft:'0px',marginLeft:'-25px'}}>
                
                <div class="col-12 col-md-3">
                  <button
                    type="button"
                    onClick={this.createUnit}
                    class="btn btn-dark button-color button-left create-new-unit"
                  >
                    Create New Module
                  </button>
                </div>
            </div>

            <div class=" col-12 col-md-12" style={{ paddingTop: "20px",paddingLeft:'0px' }}>
              <button
                type="button"
                onClick={this.deleteUnit}
                class="btn delete-button"
              >
                <img
                  src="images/Delete icon.png"
                  class="delete-icon"
                  width="20px"
                />{" "}
                Delete
              </button>
              <button
                type="button"
                onClick={(e) => {
                  this.UpdateUnitConfirm();
                }}
                class="btn btn-dark button-color update-unit"
              >
                Update Module
              </button>
            </div>

            <div
              className="table-responsive modulelist"
              style={{ paddingTop: "1em", border: "none" }}
            >
              <DataTable
                columns={this.header}
                data={this.state.moduleList}
                noHeader={true}
                pagination 
                onSelectedRowsChange={this.handleChange}
                selectableRows // add for checkbox selection
              />
            </div>

            <div class="col-md-12">
              <button type="button"
               class="btn back-button"
              onClick={() => history.push("/DashboardPage")}>
                <i class="fa fa-angle-left"></i>Back{" "}
              </button>
            </div>
          </div>
        </main>
        <AdminFooter />
        {showPopupModal && (
          <PopupModal closeModal={this.closeModal} Text={popUpModalText} messageType={messageType} />
        )}
      </div>
    );
  }
}

export default ManageStudyMaterial;
