import React, { Component } from "react";
import studentimg from "../../../assets/student-img.png";
import { adminstudentservice } from "../../../_services/adminstudent.service";
import DataTable from "react-data-table-component";
import PopupModal from "../../../_helpers/PopupModal";
import eye from "../../../assets/eye.png";
import CommonUploadForm from "../../../_helpers/CommonUploadForm";
import AdminHeader from "../../../_components/AdminHeader";
import Loader from "../../../_helpers/Loader";
import { studentService } from "../../../_services/student.service";
import { history } from "../../../_helpers/history";
import { helperText } from "../../../_helpers/helpertext";
import AdminFooter from "../../../_components/AdminFooter";
import { MessageType } from "../../../_helpers/Enums";
import { adminservice } from "../../../_services/admin.service";
import StudentAssignmentFilesBridgeModel from "../../../Models/StudentAssignmentFilesBridge.js";
import AssignmentRemarksPopupModal from "../../../_helpers/AssignmentRemarksPopupModal";

export default class AssignmentSubCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assignmentList: [],
      showPopupModal: false,
      popUpModalText: "",
      messageType: 0,
      showCommomUploadModel: false,
      courseIterationId: 0,
      moduleId: 0,
      folderName: "Remarks",
      studentId: 0,
      isLoading: false,
      userId: 0,
      membershipNumber: "",
      firstName: "",
      lastName: "",
      emailAddress: "",
      contactNo: "",
      coursesList: [],
      assignmentLength: 0,
      lastRowId: 0,
      remarkFileMediaId: 0,
      moduleName: "",
      firstAssignmentAttemptList: [],
      showFirstAttemptList: true,
      secondAssignmentAttemptList: [],
      showSecondAttemptList: false,
      thirdAssignmentAttemptList: [],
      showThirdAttemptList: false,
      fourthAssignmentAttemptList: [],
      showFourthAttemptList: false,
      fifthAssignmentAttemptList: [],
      showFifthAttemptList: false,
      firstAttemptResult: 4,
      secondAttemptResult: 4,
      thirdAttemptResult: 4,
      fourthAttemptResult: 4,
      fifthAttemptResult: 4,
      //To make the result drop down disabled for the prev attempt
      hidefirstResultDropDown: true,
      hidesecondResultDropDown: false,
      hideThirdResultDropDown: false,
      hidefourthResultDropDown: false,
      hidefifthResultDropDown: false,
      remarksId: 0,
      firstAttemptRemarkPath: "",
      secondAttemptRemarkPath: "",
      thirdAttemptRemarkPath: "",
      fourthAttemptRemarkPath: "",
      fifthAttemptRemarkPath: "",
      showAssignmentRemarksPopupModal:false,
      attemptNumber:1,
    };
  }

  /* <summary>
  date: 15-11-2021
  Name: VD
  description: To get the Assignment Details based on particular student id and iteration id
  <summary>*/
  componentDidMount = async () => {
    try {
      const ModuleId = this.props.match.params.moduleId;
      const userId = this.props.match.params.userID;
      const courseIterationID = this.props.match.params.courseIterationId;

      this.setState({
        userId: userId,
      });
      this.getUserData(userId);
      var response = await adminstudentservice.GetAssignmentListBasedOnModuleID(
        ModuleId,
        userId,
        courseIterationID
      );
      if (response.status == 200) {
        this.setState(
          {
            assignmentList: response.data,
            courseIterationId: courseIterationID,
            moduleId: ModuleId,
            studentId: userId,
            assignmentLength: response.data.length,
          },
          async () => {
            this.filterAssignmentListByGrid();
          }
        );
      }

      this.getModuleName(ModuleId);
    } catch (e) {
      return "";
    }
  };

  /* <summary>
  date: 03-02-2022
  Name: VD
  description: This is used to Filter the assignment list based on their attempt and to also save their attempt result
  <summary>*/
  filterAssignmentListByGrid = () => {
    const { assignmentList } = this.state;
    var filterFirstAnswerList = assignmentList.filter(
      (x) => x.assignmentSubmitNumber == 1
    );
    var filterSecondAnswerList = assignmentList.filter(
      (x) => x.assignmentSubmitNumber == 2
    );
    var filterThirdAnswerList = assignmentList.filter(
      (x) => x.assignmentSubmitNumber == 3
    );
    var filterFourthAnswerList = assignmentList.filter(
      (x) => x.assignmentSubmitNumber == 4
    );
    var filterFifthAnswerList = assignmentList.filter(
      (x) => x.assignmentSubmitNumber == 5
    );

    this.setState({
      firstAssignmentAttemptList: filterFirstAnswerList,
      secondAssignmentAttemptList: filterSecondAnswerList,
      thirdAssignmentAttemptList: filterThirdAnswerList,
      fourthAssignmentAttemptList: filterFourthAnswerList,
      fifthAssignmentAttemptList: filterFifthAnswerList,

      firstAttemptResult:
        filterFirstAnswerList.length != 0 ? filterFirstAnswerList[0].result : 4,
      secondAttemptResult: filterSecondAnswerList.length != 0 ? filterSecondAnswerList[0].result : 4,
      thirdAttemptResult: filterThirdAnswerList.length != 0 ? filterThirdAnswerList[0].result : 4,
      fourthAttemptResult: filterFourthAnswerList.length != 0 ? filterFourthAnswerList[0].result : 4,
      fifthAttemptResult: filterFifthAnswerList.length != 0 ? filterFifthAnswerList[0].result : 4,

      hidefirstResultDropDown: filterSecondAnswerList.length != 0 ? true : false,
      hidesecondResultDropDown: filterThirdAnswerList.length != 0 ? true : false,
      hidethirdResultDropDown: filterFourthAnswerList.length != 0 ? true : false,
      hidefourthResultDropDown: filterFifthAnswerList.length != 0 ? true : false,

      showSecondAttemptList: filterSecondAnswerList.length != 0 ? true : false,
      showThirdAttemptList: filterThirdAnswerList.length != 0 ? true : false,
      showFourthAttemptList: filterFourthAnswerList.length != 0 ? true : false,
      showFifthAttemptList: filterFifthAnswerList.length != 0 ? true : false,

      firstAttemptRemarkPath: filterFirstAnswerList.length != 0 ? filterFirstAnswerList[0].remarksPath : "",
      secondAttemptRemarkPath: filterSecondAnswerList.length != 0 ? filterSecondAnswerList[0].remarksPath : "",
      thirdAttemptRemarkPath: filterThirdAnswerList.length != 0 ? filterThirdAnswerList[0].remarksPath : "",
      fourthAttemptRemarkPath: filterFourthAnswerList.length != 0 ? filterFourthAnswerList[0].remarksPath : "",
      fifthAttemptRemarkPath: filterFifthAnswerList.length != 0 ? filterFifthAnswerList[0].remarksPath : "",
    });
  };

  /* <summary>
  date: 07-12-2021 
  Name: VD
  description: To get the Module Name based on the module Id so the admin can know on which module he is working on
  <summary>*/
  getModuleName = async (moduleId) => {
    try {
      if (moduleId != undefined) {
        var response = await adminservice.GetModuleDataBasedOnModuleID( moduleId );
        if (response.status == 200) {
          this.setState({
            moduleName: response.data[0].moduleName,
          });
        }
      }
    } catch (e) {
      return "";
    }
  };

  getUserData = async (userId) => {
    try {
      var response = await studentService.getStudentProfile(userId);
      if (response.status == 200) {
        this.setState({
          membershipNumber: response.data.studentData.membershipNumber,
          firstName: response.data.studentData.firstName,
          lastName: response.data.studentData.lastName,
          emailAddress: response.data.studentData.emailAddress,
          contactNo: response.data.studentData.contactNo,
          coursesList: response.data.studentCourseData,
        });
      }
    } catch (e) {
      return "";
    }
  };

  columns = [
    {
      name: (
        <th className="text-center" scope="col" style={{ fontSize: "16px" }}>
          Student Assignment
        </th>
      ),
      //selector: "title",
      selector: (row) => (
        <td className="center" style={{ cursor: "pointer", fontSize: "13px" }}>
          <a onClick={() => this.showStudentAnswer(row)}>{row.fileName}</a>
        </td>
      ),
      left: true,
    },
    {
      name: (
        <th className="text-center" scope="col" style={{ fontSize: "16px" }}>
          Resit Number
        </th>
      ),
      //selector: "title",
      selector: (row) => (
        <td className="center" style={{ fontSize: "13px" }}>
          {row.assignmentSubmitNumber}
        </td>
      ),
      center: true,
    },
  ];

  showRemarks = (row) => {
    window.open(row.remarksPath);
  };

  OnEventChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      errorMessage: {},
    });
  }

  showStudentAnswer = async (row) => {
    var filePath = row.assignmentFilePath;
    if (filePath == null) {
      this.setState({
        popUpModalText: helperText.EditCourseIteration.FileNotUploaded,
        showPopupModal: true,
        messageType: MessageType.Failure,
      });
    } else {
      // this.setState({ documentUrlPath: `${filePath}` });

      window.open(filePath);
    }
  };

  closePopupModal = () => {
    this.setState({
      showPopupModal: false,
    });
  };

  showUploadModel = async (attempt) => {
    this.setState({
      showAssignmentRemarksPopupModal: true,
      attemptNumber:attempt,
    });
  };

  closeModal = () => {
    this.setState({
      showAssignmentRemarksPopupModal: false,
    });
  };

  /* <summary>
  date: 15-11-2021
  Name: VD
  description: To assign the id to the latest row
  <summary>*/
  getFileMediaId = (data) => {
    const fileId = data.fileMediaID;

    this.setState({
      remarksId: fileId,
    });
  };

  /* <summary>
  date: 22-10-2021
  Name: VD
  description: This function is used to update the asignment remarks and results
  <summary>*/
  updateAssignmentResult = async () => {
    try {
      const {
        courseIterationId,
        moduleId,
        studentId,
        showSecondAttemptList,
        showThirdAttemptList,
        showFourthAttemptList,
        showFifthAttemptList,
        secondAssignmentAttemptList,
        thirdAssignmentAttemptList,
        fourthAssignmentAttemptList,
        fifthAssignmentAttemptList,
        remarksId,
      } = this.state;
      this.setState({
        isLoading: true,
      });

      var StudentAssignmentFilesBridge = new StudentAssignmentFilesBridgeModel();
      StudentAssignmentFilesBridge.studentId = studentId;
      StudentAssignmentFilesBridge.moduleId = moduleId;
      StudentAssignmentFilesBridge.courseIterationId = courseIterationId;
      StudentAssignmentFilesBridge.remarksFileMediaId = remarksId;

      if(fifthAssignmentAttemptList.length != 0){
        StudentAssignmentFilesBridge.assignmentSubmitNumber = 5;
      }else if(fourthAssignmentAttemptList.length !=0 ){
        StudentAssignmentFilesBridge.assignmentSubmitNumber = 4;
      } else if (thirdAssignmentAttemptList.length != 0) {
        StudentAssignmentFilesBridge.assignmentSubmitNumber = 3;
      } else if (secondAssignmentAttemptList.length != 0) {
        StudentAssignmentFilesBridge.assignmentSubmitNumber = 2;
      } else {
        StudentAssignmentFilesBridge.assignmentSubmitNumber = 1;
      }

      if(showFifthAttemptList){
        StudentAssignmentFilesBridge.result = this.state.fifthAttemptResult;
      }else if(showFourthAttemptList){
        StudentAssignmentFilesBridge.result = this.state.fourthAttemptResult;
      } else if (showThirdAttemptList) {
        StudentAssignmentFilesBridge.result = this.state.thirdAttemptResult;
      } else if (showSecondAttemptList) {
        StudentAssignmentFilesBridge.result = this.state.secondAttemptResult;
      } else {
        StudentAssignmentFilesBridge.result = this.state.firstAttemptResult;
      }

      var response = await adminstudentservice.addEditAssignmentResult( StudentAssignmentFilesBridge );
      if (response.status == 200) {
        this.setState({
          popUpModalText: helperText.AssignmentSubCategory.saveResultSucess,
          showPopupModal: true,
          messageType: MessageType.Success,
        });
        this.componentDidMount();
      } else {
        this.setState({
          popUpModalText: helperText.AssignmentSubCategory.saveResultError,
          showPopupModal: true,
          messageType: MessageType.Failure,
        });
      }

      this.setState({
        isLoading: false,
      });
    } catch (e) {
      return "";
    }
  };

  showAnswerPreview = (filePath) => {
    window.open(filePath, "_blank");
  };

  render() {
    const {
      isLoading,
      popUpModalText,
      showPopupModal,
      messageType,
      folderName,
      showCommomUploadModel,
      membershipNumber,
      firstName,
      lastName,
      emailAddress,
      contactNo,
      moduleName,
      firstAssignmentAttemptList,
      showFirstAttemptList,
      secondAssignmentAttemptList,
      showSecondAttemptList,
      thirdAssignmentAttemptList,
      fourthAssignmentAttemptList,
      fifthAssignmentAttemptList,
      showThirdAttemptList,
      showFourthAttemptList,
      showFifthAttemptList,
      firstAttemptResult,
      secondAttemptResult,
      thirdAttemptResult,
      fourthAttemptResult,
      fifthAttemptResult,
      hidefirstResultDropDown,
      hidesecondResultDropDown,
      firstAttemptRemarkPath,
      secondAttemptRemarkPath,
      thirdAttemptRemarkPath,
      fourthAttemptRemarkPath,
      fifthAttemptRemarkPath,
      showAssignmentRemarksPopupModal,
      attemptNumber,
      moduleId,
      studentId,
      courseIterationId,
    } = this.state;
    return (
      <div>
        <div className="bg-gray">
          <AdminHeader currentTab="Students" />
          {isLoading && <Loader />}
          <div className="menu-overlay admin-main-body "> &nbsp;</div>
          {/* Begin page content */}
          <main role="main" className="container">
            <div className="row">
              <div className="col-md-12">
                <h5 className="mt-3 mb-3 gray">
                  Assignment Submissions
                  {/* <a className="info-icon" title="More informtion">
                    <img src={info} />
                  </a> */}
                </h5>
              </div>
              <div
                className="col-md-12"
                style={{ marginTop: "-1rem", marginBottom: "5px" }}
              >
                <h6 className="mt-3 mb-3 gray">
                  Students Manage &nbsp; &gt;{" "}
                  <span> Enrolled Courses &nbsp; &gt; </span>{" "}
                  <span> Assignment Submissions &nbsp; &gt; </span>
                  <strong>{moduleName}</strong>
                </h6>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6  col-sm-12">
                <div className="student-info">
                  <div className="table-responsive" style={{ border: "none" }}>
                    <table>
                      <tbody>
                        <tr>
                          <td className="cell-size">
                            <strong>Membership Number: </strong>
                          </td>
                          <td className="leftPadding cell-size">
                            {membershipNumber}
                          </td>
                        </tr>
                        <tr>
                          <td className="cell-size">
                            <strong>First Name:</strong>
                          </td>
                          <td className="leftPadding cell-size">{firstName}</td>
                        </tr>
                        <tr>
                          <td className="cell-size">
                            <strong>Last Name:</strong>
                          </td>
                          <td className="leftPadding cell-size">{lastName}</td>
                        </tr>
                        <tr>
                          <td className="cell-size">
                            <strong>Email:</strong>
                          </td>
                          <td className="leftPadding cell-size">
                            {emailAddress}
                          </td>
                        </tr>
                        <tr>
                          <td className="cell-size">
                            <strong>Phone:</strong>
                          </td>
                          <td className="leftPadding cell-size">
                            {contactNo}{" "}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <h5 className="mt-3 mb-3 gray">Submitted Module Assignments</h5>
              </div>
              <div className="col-md-8 mt-3 module-assignment-span">
                <span className="mt-3">
                  <img src={studentimg} width="35px;" />
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      history.push(`/EmailToStudent/${this.state.userId}`)
                    }
                  >
                    {" "}
                    Student Email
                  </a>
                </span>
                &nbsp;
                <span className="mt-3">
                  <img src={studentimg} width="35px;" />{" "}
                  <a
                    onClick={() => history.push(`/EmailToAssessor`)}
                    style={{ cursor: "pointer" }}
                  >
                    {" "}
                    Assessor Email
                  </a>
                </span>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={this.updateAssignmentResult}
                >
                  Save
                </button>
              </div>
            </div>

            <div className=" manage-assigment">
            {showFifthAttemptList && (
                <div>
                  <div
                    className="row"
                    style={{ paddingTop: "8px", paddingBottom: "8px" }}
                  >
                    <div className="col-md-6">
                      <h4 className="mt-3 mb-3" style={{ fontWeight: "bold" }}>
                        Fifth Attempt
                      </h4>
                    </div>
                    <div className="col-md-4">
                      <select
                        className="form-control1 style-select"
                        id
                        value={fifthAttemptResult}
                        onChange={(e) => this.OnEventChange(e)}
                        name="fifthAttemptResult"
                      >
                        <option value={4} selected="true" disabled="disabled">
                          N/A
                        </option>
                        <option value={5}>D</option>
                        <option value={6}>M</option>
                        <option value={1}>P</option>
                        <option value={2}>F</option>
                      </select>
                    </div>
                    <div className="col-md-2">
                      <a
                        className="btn-green"
                        onClick={() => this.showUploadModel(5)}
                      >
                        Remarks
                      </a>{" "}
                      {fifthAttemptRemarkPath == "" ? (
                        ""
                      ) : (
                        <img
                          src={eye}
                          width="30px;"
                          style={{ cursor: "pointer" }}
                          onClick={() => this.showUploadModel(5)}
                        />
                      )}
                    </div>
                  </div>
                  <div
                    className="table-responsive assignment-tb"
                    style={{ paddingTop: "8px" }}
                  >
                    <DataTable
                      columns={this.columns}
                      data={fifthAssignmentAttemptList}
                      noHeader={true}
                      pagination
                    />
                  </div>
                </div>
              )}
            {showFourthAttemptList && (
                <div>
                  <div
                    className="row"
                    style={{ paddingTop: "8px", paddingBottom: "8px" }}
                  >
                    <div className="col-md-6">
                      <h4 className="mt-3 mb-3" style={{ fontWeight: "bold" }}>
                        Fourth Attempt
                      </h4>
                    </div>
                    <div className="col-md-4">
                      <select
                        className="form-control1 style-select"
                        id
                        value={fourthAttemptResult}
                        onChange={(e) => this.OnEventChange(e)}
                        name="fourthAttemptResult"
                      >
                        <option value={4} selected="true" disabled="disabled">
                          N/A
                        </option>
                        <option value={5}>D</option>
                        <option value={6}>M</option>
                        <option value={1}>P</option>
                        <option value={3}>R</option>
                      </select>
                    </div>
                    <div className="col-md-2">
                      <a
                        className="btn-green"
                        onClick={() => this.showUploadModel(4)}
                      >
                        Remarks
                      </a>{" "}
                      {fourthAttemptRemarkPath == "" ? (
                        ""
                      ) : (
                        <img
                          src={eye}
                          width="30px;"
                          style={{ cursor: "pointer" }}
                          onClick={() => this.showUploadModel(4)}
                        />
                      )}
                    </div>
                  </div>
                  <div
                    className="table-responsive assignment-tb"
                    style={{ paddingTop: "8px" }}
                  >
                    <DataTable
                      columns={this.columns}
                      data={fourthAssignmentAttemptList}
                      noHeader={true}
                      pagination
                    />
                  </div>
                </div>
              )}
              {showThirdAttemptList && (
                <div>
                  <div
                    className="row"
                    style={{ paddingTop: "8px", paddingBottom: "8px" }}
                  >
                    <div className="col-md-6">
                      <h4 className="mt-3 mb-3" style={{ fontWeight: "bold" }}>
                        Third Attempt
                      </h4>
                    </div>
                    <div className="col-md-4">
                      <select
                        className="form-control1 style-select"
                        id
                        value={thirdAttemptResult}
                        onChange={(e) => this.OnEventChange(e)}
                        name="thirdAttemptResult"
                      >
                        <option value={4} selected="true" disabled="disabled">
                          N/A
                        </option>
                        <option value={5}>D</option>
                        <option value={6}>M</option>
                        <option value={1}>P</option>
                        <option value={3}>R</option>
                      </select>
                    </div>
                    <div className="col-md-2">
                      <a
                        className="btn-green"
                        onClick={() => this.showUploadModel(3)}
                      >
                        Remarks
                      </a>{" "}
                      {thirdAttemptRemarkPath == "" ? (
                        ""
                      ) : (
                        <img
                          src={eye}
                          width="30px;"
                          style={{ cursor: "pointer" }}
                          onClick={() => this.showUploadModel(3)}
                        />
                      )}
                    </div>
                  </div>
                  <div
                    className="table-responsive assignment-tb"
                    style={{ paddingTop: "8px" }}
                  >
                    <DataTable
                      columns={this.columns}
                      data={thirdAssignmentAttemptList}
                      noHeader={true}
                      pagination
                    />
                  </div>
                </div>
              )}
              {showSecondAttemptList && (
                <div>
                  <div
                    className="row"
                    style={{ paddingTop: "8px", paddingBottom: "8px" }}
                  >
                    <div className="col-md-6">
                      <h4 className="mt-3 mb-3" style={{ fontWeight: "bold" }}>
                        Second Attempt
                      </h4>
                    </div>
                    <div className="col-md-4">
                      <select
                        className="form-control1 style-select"
                        id
                        value={secondAttemptResult}
                        onChange={(e) => this.OnEventChange(e)}
                        name="secondAttemptResult"
                        disabled={hidesecondResultDropDown}
                      >
                        <option value={4} selected="true" disabled="disabled">
                          N/A
                        </option>
                        <option value={5}>D</option>
                        <option value={6}>M</option>
                        <option value={1}>P</option>
                        <option value={3}>R</option>
                      </select>
                    </div>
                    <div className="col-md-2">
                      <a
                        className=" btn-green"
                        onClick={() => this.showUploadModel(2)}
                      >
                        Remarks
                      </a>{" "}
                      {secondAttemptRemarkPath == "" ? (
                        ""
                      ) : (
                        <img
                          src={eye}
                          width="30px;"
                          style={{ cursor: "pointer" }}
                          onClick={() =>this.showUploadModel(2) }
                        />
                      )}
                    </div>
                  </div>
                  <div
                    className="table-responsive assignment-tb"
                    style={{ paddingTop: "8px" }}
                  >
                    <DataTable
                      columns={this.columns}
                      data={secondAssignmentAttemptList}
                      noHeader={true}
                      pagination
                    />
                  </div>
                </div>
              )}
              {showFirstAttemptList && (
                <div>
                  <div
                    className="row"
                    style={{ paddingTop: "8px", paddingBottom: "8px" }}
                  >
                    <div className="col-md-6">
                      <h4 className="mt-3 mb-3" style={{ fontWeight: "bold" }}>
                        First Attempt
                      </h4>
                    </div>
                    <div className="col-md-4">
                      <select
                        className="form-control1 style-select"
                        id
                        value={firstAttemptResult}
                        onChange={(e) => this.OnEventChange(e)}
                        name="firstAttemptResult"
                        disabled={hidefirstResultDropDown}
                      >
                        <option value={4} selected="true" disabled="disabled">
                          N/A
                        </option>
                        <option value={5}>D</option>
                        <option value={6}>M</option>
                        <option value={1}>P</option>
                        <option value={3}>R</option>
                      </select>
                    </div>
                    <div className="col-md-2">
                      <a
                        className=" btn-green"
                        onClick={() => this.showUploadModel(1)}
                      >
                        Remarks
                      </a>{" "}
                      {firstAttemptRemarkPath == "" ? (
                        ""
                      ) : (
                        <img
                          src={eye}
                          width="30px;"
                          style={{ cursor: "pointer" }}
                          onClick={() => this.showUploadModel(1)}
                        />
                      )}
                    </div>
                  </div>
                  <div
                    className="table-responsive assignment-tb"
                    style={{ paddingTop: "8px" }}
                  >
                    <DataTable
                      columns={this.columns}
                      data={firstAssignmentAttemptList}
                      noHeader={true}
                      pagination
                    />
                  </div>
                </div>
              )}

              <div style={{ marginTop: "10px" }}>
                <button
                  type="button"
                  style={{ marginBottom: "4rem" }}
                  className="btn back-button"
                  onClick={() => history.goBack()}
                >
                  <i className="fa fa-angle-left" /> Back
                </button>
              </div>
            </div>
          </main>
        </div>
        <AdminFooter />
        {showPopupModal && (
          <PopupModal
            closeModal={this.closePopupModal}
            Text={popUpModalText}
            messageType={messageType}
          />
        )}
        {showCommomUploadModel && (
          <CommonUploadForm
            closeModal={this.closeModal}
            getFileMediaId={this.getFileMediaId}
            folderName={folderName}
            currentPage="AssignmentSubCategory"
          />
        )}
        {showAssignmentRemarksPopupModal && (
          <AssignmentRemarksPopupModal 
            closeModal={this.closeModal}
            attemptNumber={attemptNumber}
            moduleId={moduleId}
            studentId={studentId}
            courseIterationId={courseIterationId}
            adminLogin={true}
          />
        )}
      </div>
    );
  }
}
