import React, { Component } from "react";
import DataTable from "react-data-table-component";
import Input from "reactstrap/lib/Input";
import { admincourseservice } from "../_services/admincourse.service";
import Loader from "./Loader";
import moment from "moment";
import DeleteIcon from "../assets/RedDeleteIcon.png";
import PopupModal from "./PopupModal";
import { helperText } from "./helpertext";
import { MessageType } from "./Enums";
import eye from "../assets/eye.png";

const validFileFormats = ["pdf", "xls", "xlsx", "txt","doc","docx"];
const dateFormat = "DD-MM-YYYY";
var now = new Date();
var currentDate = moment(now).format("DD-MM-YYYY");
export default class AssignmentRemarksPopupModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
        isLoading:false,
        remarksList: [],
        fileInputKey: this.generateRandomString(),
        attemptNumber:this.props.attemptNumber,
        moduleId:this.props.moduleId,
        studentId:this.props.studentId,
        courseIterationId:this.props.courseIterationId,
        deleteAttachedFilesList: [],
        showPopupModal: false,
        popUpModalText: "",
        messageType: 0,
    };
  }

  componentDidMount=()=>{
    this.getRemarksList();
  }

  /* <summary>
  date: 21-02-2022
  Name: VD
  description: This is used to get the list of all the remarks that has been uploaded to that particular attempt for a particular student 
  <summary>*/ 
  getRemarksList=async()=>{
      const{
          attemptNumber,
          moduleId,
          studentId,
          courseIterationId,
        }=this.state;
    try{
        this.setState({
            isLoading:true
        })
        var response=await admincourseservice.getRemarksListBasedOnStudentIdAndAttemptNumber(attemptNumber,moduleId,studentId,courseIterationId);
        if(response.status===200){
          this.setState({
            remarksList:response.data,
          })
        }
        this.setState({
            isLoading:false
        })

    }catch(error){
        return "";
    }
  }

  columns = [
    {
      name: (
        <th className="text-center" scope="col" style={{ fontSize: "16px" }}>
          File Name
        </th>
      ),
      //selector: "title",
      selector: (row) => (
        <td
          className="center"
          style={{ cursor: "pointer", fontSize: "13px" }}
          onClick={()=>this.openFile(row)}
        >
            {row.name}
        </td>
      ),
      left: true,
      grow: 2,
    },
    {
      name: (
        <th className="text-center" scope="col" style={{ fontSize: "16px" }}>
          Uploaded on
        </th>
      ),
      //selector: "title",
      selector: (row) => (
        <td className="center" style={{ fontSize: "13px" }}>
          {row.createdOn===""?currentDate:moment(row.createdOn).format(dateFormat)}
        </td>
      ),
      center: true,
    },
    this.props.adminLogin?
    {
      name: (
        <th className="text-center" scope="col" style={{ fontSize: "16px" }}>
          Delete
        </th>
      ),
      //selector: "title",
      selector: (row) => (
        <td className="center" style={{ fontSize: "13px",cursor: "pointer" }} onClick={() => this.DeleteFile(row)} >
          <img src={DeleteIcon} className="manage-notification-deleteIcon" alt="Delete" />
        </td>
      ),
      center: true,
    }
    :
    {
      name: (
        <th className="text-center" scope="col" style={{ fontSize: "16px" }}>
          Preview
        </th>
      ),
      //selector: "title",
      selector: (row) => (
        <td className="center" style={{ fontSize: "13px",cursor: "pointer" }} >
          <img src={eye}
                          width="30px;"
                          style={{ cursor: "pointer" }}
                          onClick={()=>this.openFile(row)}
                        />
        </td>
      ),
      center: true,
    }
  ];

  DeleteFile = (row) => {   
    let attachedlist = this.state.remarksList;
     var ins = attachedlist.length;   
      for (var x = 0; x < ins; x++) {
          if (attachedlist[x].name === row.name) {
              if (attachedlist[x].size !== null && attachedlist[x].size != undefined) { 
                  const items = this.state.remarksList.filter(remarksList => remarksList.name !== row.name);
                  this.setState({ remarksList: items });
              }
              else {
                  this.setState((prevState) => ({
                      deleteAttachedFilesList: [...prevState.deleteAttachedFilesList, row.studentAssignmentRemarksId],
                  }));
                  const items = this.state.remarksList.filter(remarksList => remarksList.name !== row.name);
                  this.setState({ remarksList: items });
              }
          }
    }
      
  }

  onFileChange = (e) => { 
    if (!this.isValidFile(e.target.value)) {
      this.setState({
        fileInputKey: this.generateRandomString(),
        documentFile: null,
        errorFile: true,
        remarksList:null,
      });
      return;
    }

    let file ;
      file = e.target.files; 
      var ins = file.length;

      for (var i = 0; i < ins; i++) {
        let attachedFile = file[i]; 
        this.setState((prevState) => ({
            remarksList: [...prevState.remarksList, attachedFile],
        }));
      } 
    this.setState({
      errorFile: false,
      documentFile: file,
    });
  };

  isValidFile = (value) => {
    //check if value is blank
    if (!value) return false;

    //regex to get file extension
    var re = /(?:\.([^.]+))?$/;

    //second item in the array will be the extension
    let ext = re.exec(value)[1];
    return validFileFormats.some((x) => x === ext);
  };

  generateRandomString = () => {
    return Math.random().toString(36);
  };

  /* <summary>
  date: 22-02-2022
  Name: VD
  description: This is used to save the remarks that have been uploaded by the admin
  <summary>*/ 
  saveRemarks=async()=>{
    const{
      attemptNumber,
      moduleId,
      studentId,
      courseIterationId,
      remarksList,
      deleteAttachedFilesList
    }=this.state;
    try{
      this.setState({isLoading:true});
      let data=new FormData();
      data.append("attemptNumber",attemptNumber);
      data.append("moduleId", moduleId);
      data.append("studentId", studentId);
      data.append("courseIterationId", courseIterationId);
      data.append("remarksToDelete", deleteAttachedFilesList);

      var ins = remarksList.length;   
          for (var x = 0; x < ins; x++) {
              if (remarksList[x].size !== null && remarksList[x].size !== undefined) { 
                  data.append(`file_${x}`, remarksList[x]);
              }
        }

      var response=await admincourseservice.addEditRemarksFileBasedOnStudentIdAndAttemptNumber(data);
      if(response.status===200){
        if(response.data===true){
          this.setState({
            popUpModalText: helperText.AssignmentRemarksPopupModal.remarksUpdateSucess,
            showPopupModal: true,
            messageType: MessageType.Success,
          });
          this.getRemarksList();
        }
      }else{
        this.setState({
          popUpModalText: helperText.AssignmentRemarksPopupModal.remarksUpdateFailure,
          showPopupModal: true,
          messageType: MessageType.Failure,
        });
      }
      this.setState({isLoading:false});
    }catch(error){
      return "";
    }
  }

  closePopupModal = () => {
    this.setState({
      showPopupModal: false,
    });
  };
  
  openFile=(row)=>{
    let filePath=row.remarksFilePath;
    if(filePath!==""){
      window.open(filePath, "_blank");
    }
  }
  render() {
    const { 
        remarksList,
        fileInputKey,
        isLoading,
        popUpModalText,
        showPopupModal,
        messageType,
     } = this.state;
    return (
      <div
        className="modal AdvanceCourseModal"
        id="exampleModalCenter"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
      >
        <div className="modal-dialog modal-dialog-centered remarks-Model" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="exampleModalLongTitle">
              {this.props.adminLogin?"Upload Remarks":"Remarks"}
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.props.closeModal}
              >
                <span>×</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                {this.props.adminLogin?
                <div className="form-group">
                <Input
                  type="file"
                  accept=".pdf,.xlsx, .xls,.doc,.docx"
                  name="documentFile"
                  onChange={this.onFileChange}
                  key={fileInputKey || ""}
                  multiple={"multiple"}
                  style={{
                    marginTop: "10px",
                    width: "95%",
                    overflow: "revert",
                    whiteSpace: "break-spaces",
                  }}
                />
                </div>:""}
                <div className="form-group">
                  <DataTable
                    columns={this.columns}
                    data={remarksList}
                    noHeader={true}
                    pagination
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={this.props.closeModal}
              >
                Cancel
              </button>
              {this.props.adminLogin?
              <button
                type="button"
                className="btn btn-primary"
                onClick={()=>this.saveRemarks()}
              >
                Upload
              </button>
              :""}
            </div>
          </div>
        </div>
        {showPopupModal && (
          <PopupModal closeModal={()=>this.closePopupModal()} Text={popUpModalText} messageType={messageType}  />
        )}
        {isLoading && <Loader /> }
      </div>
    );
  }
}
