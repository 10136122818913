import React, { Component } from "react";
import AdminHeader from "../../../_components/AdminHeader";
import PopupModal from "../../../_helpers/PopupModal";
import Loader from "../../../_helpers/Loader";
import { admindashboardservice } from "../../../_services/admindashboard.service";
import info from "../../../assets/info.png";
import { history } from "../../../_helpers/history";
import AdminFooter from "../../../_components/AdminFooter";
import { MessageType } from "../../../_helpers/Enums";
import { helperText } from "../../../_helpers/helpertext";

export default class ManageAutoNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      feesPaymentReminder: false,
      feesPaymentDays: 0,
      feesPaymentMonths: 0,
      offerHelpBeforeCourseCompletionReminder: false,
      offerHelpBeforeCourseCompletionDays: 0,
      offerHelpBeforeCourseCompletionMonths: 0,
      advanceCourseOptInNotification: false,
      assignmentNotification: false,
      courseExpirationReminder: false,
      showPopupModal: false,
      popUpModalText: "",
      messageType:0,
      courseExpirationDays: 0,
      courseExpirationMonths: 0,
    };
  }

  /* <summary>
  date: 19-10-2021
  Name: VD
  description: To get the details about all the auto notifications
  <summary>*/ 
  componentDidMount = async () => {
    try {
      this.setState({ isLoading: true });
      var response = await admindashboardservice.GetAutonotification();
      if (response.status == 200) {
        this.setState({
          feesPaymentReminder: response.data.feePaymentReminder,
          feesPaymentDays: response.data.feePaymentDays,
          feesPaymentMonths: response.data.feesPaymentMonths,
          offerHelpBeforeCourseCompletionReminder:response.data.offerHelpBeforeCourseCompletionReminder,
          offerHelpBeforeCourseCompletionDays:response.data.offerHelpBeforeCourseCompletionDays,
          offerHelpBeforeCourseCompletionMonths:response.data.offerHelpBeforeCourseCompletionMonths,
          advanceCourseOptInNotification:response.data.advanceCourseOptInNotification,
          assignmentNotification: response.data.assignmentNotification,
          courseExpirationReminder: response.data.courseExpirationReminder,
          courseExpirationDays: response.data.courseExpirationDays,
          courseExpirationMonths: response.data.courseExpirationMonths,
        });
      }
      this.setState({ isLoading: false });
    } catch (e) { 
      return "";
    }
  };

  checBoxChange = (e) => {
    let { name, checked } = e.target;
    this.setState({
      [name]: checked,
    });
  };

  onDateChanged = (e) => {
    let { name, value } = e.target;
    this.setState({
      [name]: value,
    });

    if (name == "feesPaymentDays") {
      this.setState({
        feesPaymentMonths: 0,
      });
    } else if (name == "feesPaymentMonths") {
      this.setState({
        feesPaymentDays: 0,
      });
    } else if (name == "offerHelpBeforeCourseCompletionDays") {
      this.setState({
        offerHelpBeforeCourseCompletionMonths: 0,
      });
    } else if (name == "offerHelpBeforeCourseCompletionMonths") {
      this.setState({
        offerHelpBeforeCourseCompletionDays: 0,
      });
    } else if (name == "courseExpirationDays") {
      this.setState({
        courseExpirationMonths: 0,
      });
    } else if (name == "courseExpirationMonths") {
      this.setState({
        courseExpirationDays: 0,
      });
    }
  };

  /* <summary>
  date: 19-10-2021
  Name: VD
  description: To save the Auto notification email changes that have been done
  <summary>*/ 
  SaveAutoNotification = async () => {
    try{
      const {
        feesPaymentReminder,
        feesPaymentDays,
        feesPaymentMonths,
        offerHelpBeforeCourseCompletionReminder,
        offerHelpBeforeCourseCompletionDays,
        offerHelpBeforeCourseCompletionMonths,
        advanceCourseOptInNotification,
        assignmentNotification,
        courseExpirationReminder,
        courseExpirationDays,
        courseExpirationMonths,
      } = this.state;
      var response = await admindashboardservice.AddEditAutoNotification(
        feesPaymentReminder,
        feesPaymentDays,
        feesPaymentMonths,
        offerHelpBeforeCourseCompletionReminder,
        offerHelpBeforeCourseCompletionDays,
        offerHelpBeforeCourseCompletionMonths,
        advanceCourseOptInNotification,
        assignmentNotification,
        courseExpirationReminder,
        courseExpirationDays,
        courseExpirationMonths
      );
  
      if (response.status == 200) {
        this.setState({
          popUpModalText: helperText.ManageAutoNotification.emailUpdated,
          showPopupModal: true,
          messageType:MessageType.Success,
        });
      } else {
        this.setState({
          popUpModalText: helperText.DataLoadError.updateError,
          showPopupModal: true,
          messageType:MessageType.Failure,
        });
      }
    }catch (e) { 
      return "";
    }
  };

  closeModal = () => {
    this.setState({
      showPopupModal: false,
    });
  };
  render() {
    const {
      showPopupModal,
      popUpModalText,
      messageType,
      isLoading,
      feesPaymentReminder,
      offerHelpBeforeCourseCompletionReminder,
      advanceCourseOptInNotification,
      assignmentNotification,
      feesPaymentDays,
      feesPaymentMonths,
      offerHelpBeforeCourseCompletionDays,
      offerHelpBeforeCourseCompletionMonths,
      courseExpirationReminder,
      courseExpirationDays,
      courseExpirationMonths,
    } = this.state;
    return (
      <div className="">
        <AdminHeader currentTab="Home" />
        {showPopupModal && (
          <PopupModal closeModal={this.closeModal} Text={popUpModalText} messageType={messageType} />
        )}
        {isLoading && <Loader />}
        <main role="main" className="container admin-main-body">
          <div className="row">
            <div className="col-md-12">
              <h5 className="mt-3 mb-3 gray">
                Manage Auto Notifications
                {/* <a className="info-icon" title="More informtion">
                  <img src={info} />
                </a> */}
              </h5>
            </div>
          </div>
          <div className="row edit-course-table auto-notify-sec">
            <div className="col-lg-6">
              <div className="table-responsive-sm">
                <table className="table table-striped">
                  <tbody>
                    <tr>
                      <td className="blue-color left border-color" colSpan={4}>
                      <h5 className="manageEnrollment-h5">Master Controls - Courses</h5>
                      </td>
                      
                    </tr>
                    <tr>
                      <td className="blue-color left  border-color">
                        <label>Set Fee Payment Reminder:</label>
                      </td>
                      <td className="white-color">
                        {/* <div className="form-group">
                            <input
                              type="checkbox"
                              id="check1"
                              name="feesPaymentReminder"
                              checked={feesPaymentReminder}
                              onChange={(e) => this.checBoxChange(e)}
                            />
                            <label htmlFor="check1" />
                          </div> */}
                        <label className="conta">
                          <input
                            type="checkbox"
                            id="check"
                            name="feesPaymentReminder"
                            checked={feesPaymentReminder}
                            onChange={(e) => this.checBoxChange(e)}
                          />
                          <span
                            className="checkbox center-align"
                            style={{ marginTop: "-3px" }}
                          />
                        </label>
                      </td>
                      <td className="white-color left">
                        {" "}
                        Days before Payment Dates
                        <form className="auto-notify-select">
                          <select
                            name="feesPaymentDays"
                            onChange={(e) => this.onDateChanged(e)}
                            value={feesPaymentDays}
                          >
                            <option value={0} selected disabled>
                              0
                            </option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                            <option value={7}>7</option>
                            <option value={8}>8</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                            <option value={11}>11</option>
                            <option value={12}>12</option>
                            <option value={13}>13</option>
                            <option value={14}>14</option>
                            <option value={15}>15</option>
                            <option value={16}>16</option>
                            <option value={17}>17</option>
                            <option value={18}>18</option>
                            <option value={19}>19</option>
                            <option value={20}>20</option>
                            <option value={21}>21</option>
                            <option value={22}>22</option>
                            <option value={23}>23</option>
                            <option value={24}>24</option>
                            <option value={25}>25</option>
                            <option value={26}>26</option>
                            <option value={27}>27</option>
                            <option value={28}>28</option>
                            <option value={29}>29</option>
                            <option value={30}>30</option>
                            <option value={31}>31</option>
                          </select>
                        </form>
                      </td>
                      <td className="white-color left">
                        {" "}
                        Months before Payment Dates
                        <form className="auto-notify-select">
                          <select
                            name="feesPaymentMonths"
                            onChange={(e) => this.onDateChanged(e)}
                            value={feesPaymentMonths}
                          >
                            <option value={0} selected disabled>
                              0
                            </option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                            <option value={7}>7</option>
                            <option value={8}>8</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                            <option value={11}>11</option>
                            <option value={12}>12</option>
                          </select>
                        </form>
                      </td>
                    </tr>
                    <tr>
                      <td className="blue-color left  border-color">
                        <label>Course expiration Notification:</label>
                      </td>
                      <td className="white-color">
                        {/*  <div className="form-group">
                           <input
                              type="checkbox"
                              id="check1"
                              name="offerHelpBeforeCourseCompletionReminder"
                              checked={offerHelpBeforeCourseCompletionReminder}
                              onChange={(e) => this.checBoxChange(e)}
                            />
                            <label htmlFor="check2" />
                          </div> */}
                        <label className="conta">
                          <input
                            type="checkbox"
                            id="check"
                            name="offerHelpBeforeCourseCompletionReminder"
                            checked={offerHelpBeforeCourseCompletionReminder}
                            onChange={(e) => this.checBoxChange(e)}
                          />
                          <span
                            className="checkbox center-align"
                            style={{ marginTop: "-3px" }}
                          />
                        </label>
                      </td>
                      <td className="white-color left">
                        Days before course completion date
                        <form className="auto-notify-select">
                          <select
                            name="offerHelpBeforeCourseCompletionDays"
                            onChange={(e) => this.onDateChanged(e)}
                            value={offerHelpBeforeCourseCompletionDays}
                          >
                            <option value={0} selected disabled>
                              0
                            </option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                            <option value={7}>7</option>
                            <option value={8}>8</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                            <option value={11}>11</option>
                            <option value={12}>12</option>
                            <option value={13}>13</option>
                            <option value={14}>14</option>
                            <option value={15}>15</option>
                            <option value={16}>16</option>
                            <option value={17}>17</option>
                            <option value={18}>18</option>
                            <option value={19}>19</option>
                            <option value={20}>20</option>
                            <option value={21}>21</option>
                            <option value={22}>22</option>
                            <option value={23}>23</option>
                            <option value={24}>24</option>
                            <option value={25}>25</option>
                            <option value={26}>26</option>
                            <option value={27}>27</option>
                            <option value={28}>28</option>
                            <option value={29}>29</option>
                            <option value={30}>30</option>
                            <option value={31}>31</option>
                          </select>
                        </form>
                      </td>
                      <td className="white-color left">
                        Months before course completion date
                        <form className="auto-notify-select">
                          <select
                            name="offerHelpBeforeCourseCompletionMonths"
                            onChange={(e) => this.onDateChanged(e)}
                            value={offerHelpBeforeCourseCompletionMonths}
                          >
                            <option value={0} selected disabled>
                              0
                            </option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                            <option value={7}>7</option>
                            <option value={8}>8</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                            <option value={11}>11</option>
                            <option value={12}>12</option>
                          </select>
                        </form>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="table-responsive-sm">
                <table className="table table-striped">
                  <tbody>
                    <tr>
                      <td className="blue-color left border-color td-width1" colSpan={4}>
                      <h5 className="manageEnrollment-h5">Master Controls - Students</h5>
                      </td>
                      
                      
                    </tr>
                    <tr>
                      <td className="blue-color left  border-color" width="20%">
                        <label>
                          Advanced Course
                          <br /> 'Opt-In' Notification:
                        </label>
                      </td>
                      <td className="white-color left" colSpan={4}>
                        {/*<div className="form-group">
                             <input
                              type="checkbox"
                              id="check1"
                              name="advanceCourseOptInNotification"
                              checked={advanceCourseOptInNotification}
                              onChange={(e) => this.checBoxChange(e)}
                            />
                            <label htmlFor="check3" />
                          </div> */}
                        <label className="conta">
                          <input
                            type="checkbox"
                            id="check"
                            name="advanceCourseOptInNotification"
                            checked={advanceCourseOptInNotification}
                            onChange={(e) => this.checBoxChange(e)}
                          />
                          <span
                            className="checkbox check-left-align auto-align"
                            style={{ marginTop: "-3px" }}
                          />
                        </label>
                      </td>
                      
                    </tr>
{/*                     
                    <tr>
                      <td className="blue-color left  border-color">
                        <label>Course expiration Notification:</label>
                      </td>
                      <td className="white-color">
                        <label className="conta">
                          <input
                            type="checkbox"
                            id="check"
                            name="courseExpirationReminder"
                            checked={courseExpirationReminder}
                            onChange={(e) => this.checBoxChange(e)}
                          />
                          <span
                            className="checkbox center-align"
                            style={{ marginTop: "-3px" }}
                          />
                        </label>
                      </td>
                      <td className="white-color left">
                        Days before course expiration date
                        <form className="auto-notify-select">
                          <select
                            name="courseExpirationDays"
                            onChange={(e) => this.onDateChanged(e)}
                            value={courseExpirationDays}
                          >
                            <option value={0} selected disabled>
                              0
                            </option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                            <option value={7}>7</option>
                            <option value={8}>8</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                            <option value={11}>11</option>
                            <option value={12}>12</option>
                            <option value={13}>13</option>
                            <option value={14}>14</option>
                            <option value={15}>15</option>
                            <option value={16}>16</option>
                            <option value={17}>17</option>
                            <option value={18}>18</option>
                            <option value={19}>19</option>
                            <option value={20}>20</option>
                            <option value={21}>21</option>
                            <option value={22}>22</option>
                            <option value={23}>23</option>
                            <option value={24}>24</option>
                            <option value={25}>25</option>
                            <option value={26}>26</option>
                            <option value={27}>27</option>
                            <option value={28}>28</option>
                            <option value={29}>29</option>
                            <option value={30}>30</option>
                            <option value={31}>31</option>
                          </select>
                        </form>
                      </td>
                      <td className="white-color left">
                        Months before course completion date
                        <form className="auto-notify-select">
                          <select
                            name="courseExpirationMonths"
                            onChange={(e) => this.onDateChanged(e)}
                            value={courseExpirationMonths}
                          >
                            <option value={0} selected disabled>
                              0
                            </option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                            <option value={7}>7</option>
                            <option value={8}>8</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                            <option value={11}>11</option>
                            <option value={12}>12</option>
                          </select>
                        </form>
                      </td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-md-12">
              <center>
                <button
                  type="button"
                  className="btn btn-dark button-color autoNotification-Save"
                  onClick={this.SaveAutoNotification}
                >
                  Save
                </button>
              </center>
            </div>
            <div className="col-md-12">
              <button
                type="button"
                className="btn back-button"
                onClick={() => history.push("/DashboardPage")}
              >
                <i className="fa fa-angle-left" />
                Back{" "}
              </button>
            </div>
          </div>
        </main>
        <AdminFooter />
      </div>
    );
  }
}
