/* <summary>
date: 09-06-2021
Name: VD
description:To check if the input is emppty or not 
<summary>*/ 
export function isStringEmpty(text) {
    return !text || text.trim().length === 0;
  }

  /* <summary>
  date: 09-06-2021
  Name: VD
  description: to validate all the email address that have been entered
  <summary>*/ 
  export const isEmailValid = (emailAddress) => {
    const emailRegexString = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
    return emailRegexString.test(emailAddress);
  };

  export function getFileName(path) {
    return path.substring(path.lastIndexOf("/") + 1);
  }