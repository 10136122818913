import React, { Component } from "react";
import AdminHeader from "../../../_components/AdminHeader";
import Loader from "../../../_helpers/Loader";
import DataTable from "react-data-table-component";
import { studentService } from "../../../_services/student.service";
import { adminstudentservice } from "../../../_services/adminstudent.service";
import { history } from "../../../_helpers/history";
import AdminFooter from "../../../_components/AdminFooter";

const ExpandableComponent = ({ data }) => {
  return <p style={{ color: "red", height: "20px" }}>{data.data.moduleId}</p>;
};
export default class AssignmentSubmissions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      userId: 0,
      membershipNumber: "",
      firstName: "",
      lastName: "",
      emailAddress: "",
      contactNo: "",
      coursesList: [],
      moduleList: [],
      courseIterationId: 0,
    };
  }

  /* <summary>
  date: 15-11-2021
  Name: VD
  description: To get the student profile based on the student id and to get the Module list based on the student id
  <summary>*/ 
  componentDidMount = async () => {
    try {
      const userID = this.props.match.params.userID;
      const courseIterationID = this.props.match.params.courseIterationId;
      this.setState({
        isLoading: true,
        courseIterationId: courseIterationID,
      });
      var response = await studentService.getStudentProfile(userID);
      if (response.status == 200) {
        this.setState({
          membershipNumber: response.data.studentData.membershipNumber,
          firstName: response.data.studentData.firstName,
          lastName: response.data.studentData.lastName,
          emailAddress: response.data.studentData.emailAddress,
          contactNo: response.data.studentData.contactNo,
          coursesList: response.data.studentCourseData,
          userId: userID,
        });
      }
      var getUnitList = await adminstudentservice.GetModuleEnrolledToStudent(
        userID
      );
      if (getUnitList.status == 200) {
        this.setState({
          moduleList: getUnitList.data,
        });
      }
      this.setState({ isLoading: false });
    } catch (e) {
      return "";
    }
  };
 
  columns = [
    {
      name: (
        <th className scope="col" style={{ width: "85px" }}>
          Module ID
        </th>
      ),
      selector: "moduleId",
      cell: (row) => <td className="center text-color">{row.moduleId}</td>,
      left: true,
    },
    {
      name: (
        <th className scope="col" colSpan={4}>
          Module Title
        </th>
      ),
      selector: "title",
      cell: (row) => <td className="center">{row.moduleName}</td>,
      left: true,
      grow: 2,
    },
    {
      name: (
        <th className scope="col" style={{ width: "64px" }}>
          Assignment
        </th>
      ),
      cell: (row) => (
        <td className="center text-color">
          <button
            onClick={() =>
              history.push(
                `/AssignmentSubCategory/${this.state.userId}/${this.state.courseIterationId}/${row.moduleId}`
              )
            }
            style={{ cursor: "pointer" }}
            className="btn btn-link"
            type="button"
          >
            Manage{" "}
          </button>
        </td>
      ),
      center: true,
    },
  ];

  saveAssignment = (row) => {
    const moduleId = row.moduleId;
  };

  render() {
    const {
      isLoading,
      membershipNumber,
      firstName,
      lastName,
      emailAddress,
      contactNo,
    } = this.state;
    return (
      <div className="overall-font" style={{ backgroundColor: "#E9FBFC" }}>
        <AdminHeader currentTab="Students" />
        {isLoading && <Loader />}
        {/* Begin page content */}
        <main role="main" className="container admin-main-body ">
          <div className="row">
            <div className="col-md-12">
              <h5 className="mt-3 mb-3 gray">
                Assignment Submissions
                {/* <a className="info-icon" title="More informtion">
                  <img src={info} />
                </a> */}
              </h5>
            </div>
            <div
              className="col-md-12"
              style={{ marginTop: "-1rem", marginBottom: "5px" }}
            >
              <h6 className="mt-3 mb-3 gray">
                Students Manage &nbsp; &gt;{" "}
                <span> Enrolled Courses &nbsp; &gt; </span>{" "}
                <strong> Assignment Submissions </strong>
              </h6>
            </div>
            <div className="col-md-6  col-sm-12">
              <div className="student-info">
                <div className="table-responsive" style={{ border: "none" }}>
                <table>
                    <tbody>
                      <tr>
                        <td className="cell-size">
                          <strong>Membership Number: </strong>
                        </td>
                        <td className="leftPadding cell-size">
                          {membershipNumber}
                        </td>
                      </tr>
                      <tr>
                        <td className="cell-size">
                          <strong>First Name:</strong>
                        </td>
                        <td className="leftPadding cell-size">{firstName}</td>
                      </tr>
                      <tr>
                        <td className="cell-size">
                          <strong>Last Name:</strong>
                        </td>
                        <td className="leftPadding cell-size">{lastName}</td>
                      </tr>
                      <tr>
                        <td className="cell-size">
                          <strong>Email:</strong>
                        </td>
                        <td className="leftPadding cell-size">
                          {emailAddress}
                        </td>
                      </tr>
                      <tr>
                        <td className="cell-size">
                          <strong>Phone:</strong>
                        </td>
                        <td className="leftPadding cell-size">{contactNo} </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <h5 className="mt-3 mb-3 gray">Submitted Module Assignments</h5>
            </div>
            <div className="container">
              <div
                className="table-responsive"
                style={{ padding: "10px", backgroundColor: "#fff" }}
              >
                <DataTable
                  columns={this.columns}
                  data={this.state.moduleList}
                  noHeader={true}
                  pagination
                  // expandableRows
                  // expandableRowsComponent={(data) => <AssignmentSubCategory
                  //   moduleId={data.data.moduleId}
                  //   userId={userId}
                  //   courseIterationId={courseIterationId}
                  //   getLatestRow={this.getLatestRow}
                  // />}

                  keyField="title"
                />
              </div>
              <div style={{ marginTop: "10px" }}>
                <button
                  type="button"
                  className="btn back-button"
                  onClick={() => history.goBack()}
                >
                  <i className="fa fa-angle-left" /> Back
                </button>
              </div>
            </div>
          </div>
        </main>
        <AdminFooter />
      </div>
    );
  }
}
