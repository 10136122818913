export default class RegisterStudentModel{
    constructor(
        userID,
        firstName,
        emailAddress,
        address,
        contactNo,
        selectedOption,
    ){
        this.userID=userID== undefined ? 0 : userID;
        this.firstName=firstName== undefined ? "" : firstName;
        this.emailAddress=emailAddress== undefined ? "" : emailAddress;
        this.address=address== undefined ? "" : address;
        this.contactNo=contactNo== undefined ? "" : contactNo;
        this.selectedOption=selectedOption==undefined?"":selectedOption;
    }
}