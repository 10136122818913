import React, { Component } from "react";
import AdminHeader from "../../../_components/AdminHeader";
import DataTable from "react-data-table-component";
import { adminstudentservice } from "../../../_services/adminstudent.service";
import Loader from "../../../_helpers/Loader";
import PopupModal from "../../../_helpers/PopupModal";
import Logout from "../../../_helpers/Logout";
import { history } from "../../../_helpers/history";
import AdminFooter from "../../../_components/AdminFooter";
import { MessageType } from "../../../_helpers/Enums";
import { helperText } from "../../../_helpers/helpertext";

export default class RegisteredStudents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      resgisterStudentList: [],
      unfilteredList: [],
      Search: "",
      showPopupModal: false,
      popUpModalText: "",
      messageType: 0,
    };
  }

  /* <summary>
  date: 15-11-2021
  Name: VD
  description: This is used to get the  list of student who have been registered to the course
  <summary>*/
  componentDidMount = async () => {
    try {
      this.setState({
        isLoading: true,
      });

      var response = await adminstudentservice.RegisteredStudentsList();
      if (response.status == 200) {
        this.setState({
          isLoading: false,
          resgisterStudentList: response.data,
          unfilteredList: response.data,
        });
      } else if (response.status == 401) {
        Logout();
      } else {
        this.setState({
          popUpModalText: helperText.RegisteredStudent.errorStudentList,
          showPopupModal: true,
          messageType: MessageType.Failure,
        });
      }
      this.setState({
        isLoading: false,
      });
    } catch (e) {
      return "";
    }
  };

  onSearchTextChange = (event) => {
    var inputValue = event.target.value.toLowerCase();

    var filteredStudent = this.state.unfilteredList.filter(
      (p) =>
        p.displayName.toLowerCase().includes(inputValue) ||
        p.contactNo.toLowerCase().includes(inputValue) ||
        p.emailAddress.toLowerCase().includes(inputValue)
      //p.membershipNumber.toLowerCase().includes(inputValue))
    );

    this.setState({
      Search: event.target.value,
      resgisterStudentList: filteredStudent,
    });
  };

  header = [
    {
      name: (
        <th className="text-center" scope="col">
          Sr No.
        </th>
      ),
      selector: "srNo",
      cell: (row) => <td className="center">{row.srNo}</td>,
      center: true,
    },
    {
      name: (
        <th className="text-center" scope="col">
          Name
        </th>
      ),
      selector: "displayName",
      cell: (row) => <td className="center">{row.displayName}</td>,
      center: true,
    },
    {
      name: (
        <th className="text-center" scope="col">
          Phone No
        </th>
      ),
      selector: "contactNo",
      cell: (row) => <td className="center">{row.contactNo}</td>,
      center: true,
    },
    {
      name: (
        <th className="text-center" scope="col">
          Email
        </th>
      ),
      selector: "emailAddress",
      cell: (row) => <td className="center">{row.emailAddress}</td>,
      center: true,
      grow: 2,
    },
    {
      name: (
        <th className="text-center" scope="col">
          Country
        </th>
      ),
      selector: "address",
      cell: (row) => <td className="center">{row.address}</td>,
      center: true,
    },
    {
      name: (
        <th className="text-center" scope="col">
          Status
        </th>
      ),
      selector: "status",
      cell: (row) => {
        if (row.status == 1) {
          return (
            <td className="center">
              <button
                type="button"
                className="btn"
                style={{
                  backgroundColor: "#FF7272",
                  color: "#ffffff",
                  padding: "5px 16px",
                }}
              >
                Removed
              </button>
            </td>
          );
        } else if (row.status == 2) {
          return (
            <td className="center">
              <button type="button" className="btn save-button">
                Active
              </button>
            </td>
          );
        } else {
          return (
            <td className="center">
              <button type="button" className="btn enroll-button">
                Enroll
              </button>
            </td>
          );
        }
      },
      //<td className="center">{row.status}</td>,
      center: true,
    },
  ];

  closeModal = () => {
    this.setState({
      showPopupModal: false,
    });
  };

  render() {
    const { isLoading, popUpModalText, showPopupModal, messageType } =
      this.state;
    return (
      <div style={{ backgroundColor: "#F1F4F6" }}>
        <AdminHeader currentTab="Students" />
        {isLoading && <Loader />}
        {/* Begin page content */}
        <main role="main" className="container page-height admin-main-body ">
          <div className="row">
            <div className="col-md-12">
              <h5 className="mt-3 gray">Students Registered</h5>
            </div>
            <div className="col-md-12">
              <h6 className="mb-3 gray">
                Student Course Manager &gt; Registered Students
              </h6>
            </div>
            <div
              className="col-lg-5 flex-item-left"
              style={{ paddingLeft: "15px" }}
            ></div>
            <div
              className="col-lg-7 flex-item-right"
              style={{ padding: "0px" }}
            >
              <form className="example1" style={{ float: "right" }}>
                <input
                  type="text"
                  placeholder="Search"
                  name="search"
                  value={this.state.Search}
                  onChange={this.onSearchTextChange.bind(this)}
                />
                <button type="submit">
                  <i className="fa fa-search" />
                </button>
              </form>
            </div>
            <div
              className="table-responsive"
              style={{ paddingTop: "2em", border: "none" }}
            >
              <DataTable
                columns={this.header}
                data={this.state.resgisterStudentList}
                noHeader={true}
                pagination
              />
            </div>

            <div className="col-md-12" style={{ paddingTop: "20px" }}>
              <button
                type="button"
                className="btn back-button"
                onClick={() => history.push(`/StudentCourseManager`)}
              >
                <i className="fa fa-angle-left" />
                Back to Course Manager
              </button>
            </div>
          </div>
        </main>
        <AdminFooter />
        {showPopupModal && (
          <PopupModal
            closeModal={this.closeModal}
            Text={popUpModalText}
            messageType={messageType}
          />
        )}
      </div>
    );
  }
}
