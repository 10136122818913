export default class ResubmissionAssignmentPaymentModel{
    constructor(
        studentModuleBridgeID,
        resubmits,
        assignmentOneSubmittedOn,
        assignmentTwoAmount,
        assignmentTwoDueDate,
        assignmentTwoSubmittedOn,
        assignmentTwoWaveOffCharges,
        assignmentThreeAmount,
        assignmentThreeDueDate,
        assignmentThreeSubmittedOn,
        assignmentThreeWaveOffCharges,
        assignmentTwoAmountPaidDate,
        assignmentThreeAmountPaidDate
    ){
        this.studentModuleBridgeID=studentModuleBridgeID==undefined?0:studentModuleBridgeID;
        this.resubmits=resubmits==undefined?0:resubmits;
        this.assignmentOneSubmittedOn=assignmentOneSubmittedOn==undefined?"":assignmentOneSubmittedOn;
        this.assignmentTwoAmount=assignmentTwoAmount==undefined?"":assignmentTwoAmount;
        this.assignmentTwoDueDate=assignmentTwoDueDate==undefined?"":assignmentTwoDueDate;
        this.assignmentTwoSubmittedOn=assignmentTwoSubmittedOn==undefined?"":assignmentTwoSubmittedOn;
        this.assignmentTwoWaveOffCharges=assignmentTwoWaveOffCharges==undefined?false:assignmentTwoWaveOffCharges;
        this.assignmentThreeAmount=assignmentThreeAmount==undefined?"":assignmentThreeAmount;
        this.assignmentThreeDueDate=assignmentThreeDueDate==undefined?"":assignmentThreeDueDate;
        this.assignmentThreeSubmittedOn=assignmentThreeSubmittedOn==undefined?"":assignmentThreeSubmittedOn;
        this.assignmentThreeWaveOffCharges=assignmentThreeWaveOffCharges==undefined?false:assignmentThreeWaveOffCharges;
        this.assignmentTwoAmountPaidDate=assignmentTwoAmountPaidDate==undefined?"":assignmentTwoAmountPaidDate;
        this.assignmentThreeAmountPaidDate=assignmentThreeAmountPaidDate==undefined?"":assignmentThreeAmountPaidDate;
    }
}