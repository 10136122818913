import React, { Component } from "react";
import { studentService } from "../_services/student.service";
import Loader from "../_helpers/Loader";

export default class GraduateModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isGraduate: this.props.isGraduate,
            studentID: this.props.userID
        };
    }


    componentDidMount = () => {

    };

    UpdateGraduateColumn = async () => {
        this.setState({
            isLoading: true
        });
        try {
            var response = await studentService.UpdateGraduateColumn(this.state.studentID, this.state.isGraduate);
            if (response.status == 200) {
                window.location.reload();
            }
            else {
                //window.location.reload();
            }
            
        } catch (e) {
            return "";
        }
    }

    render() {
        const { isGraduate, studentID, isLoading } = this.state;
        return (
            <div
                className="modal"
                role="dialog"
                style={{
                    overflow: "auto",
                    display: "block",
                    marginTop: "5.5%",
                    opacity: 1,
                }}>
                {isLoading && <Loader />}
                <div className="modal-dialog">
                    {/* Modal content*/}
                    <div className="modal-content">
                        <div className="modal-body">
                            {isGraduate ?
                                "Do you want to change the student's status to 'Not Graduated'?" :
                                "Do you want to change the student's status to 'Graduated'?"
                            }
                            <br></br>
                <button
                    style={{ color: "#30a80c", fontWeight: "bold" }}
                    onClick={this.UpdateGraduateColumn}
                >
                    Yes
                            </button>
                            &nbsp;&nbsp;&nbsp;
                <button
                    style={{ color: "#cc2121", fontWeight: "bold" }}
                    onClick={this.props.closeGraduateModal}
                >
                    No
                </button>
                    </div>
                </div>
                </div>
            </div>
        );
    }
}
