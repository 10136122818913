
export default class StudentModuleBridgeModel{
    constructor(
        studentModuleBridgeID,
        studentID,
        moduleID,
        unitArchivedID,
        unitResitArchivedID,
        numResit,
        moduleVisible,
        tMAQVisible,
        fileMediaID,
        courseIterationId,
        resubmits,
        assignmentOneSubmittedOn,
        assignmentTwoAmount,
        assignmentTwoDueDate,
        assignmentTwoSubmittedOn,
        assignmentTwoWaveOffCharges,
        assignmentThreeAmount,
        assignmentThreeDueDate,
        assignmentThreeSubmittedOn,
        assignmentThreeWaveOffCharges,
    ){
        this.studentModuleBridgeID=studentModuleBridgeID==undefined?0:studentModuleBridgeID;
        this.studentID=studentID==undefined?0:studentID;
        this.moduleID=moduleID==undefined?0:moduleID;
        this.unitArchivedID=unitArchivedID==undefined?0:unitArchivedID;
        this.unitResitArchivedID=unitResitArchivedID==undefined?0:unitResitArchivedID;
        this.numResit=numResit==undefined?0:numResit;
        this.moduleVisible=moduleVisible==undefined?false:moduleVisible;
        this.tMAQVisible=tMAQVisible==undefined?false:tMAQVisible;
        this.fileMediaID=fileMediaID==undefined?0:fileMediaID;
        this.courseIterationId=courseIterationId==undefined?0:courseIterationId;
        this.resubmits=resubmits==undefined?0:resubmits;
        this.assignmentOneSubmittedOn=assignmentOneSubmittedOn==undefined?"":assignmentOneSubmittedOn;
        this.assignmentTwoAmount=assignmentTwoAmount==undefined?"":assignmentTwoAmount;
        this.assignmentTwoDueDate=assignmentTwoDueDate==undefined?"":assignmentTwoDueDate;
        this.assignmentTwoSubmittedOn=assignmentTwoSubmittedOn==undefined?"":assignmentTwoSubmittedOn;
        this.assignmentTwoWaveOffCharges=assignmentTwoWaveOffCharges==undefined?false:assignmentTwoWaveOffCharges;
        this.assignmentThreeAmount=assignmentThreeAmount==undefined?"":assignmentThreeAmount;
        this.assignmentThreeDueDate=assignmentThreeDueDate==undefined?"":assignmentThreeDueDate;
        this.assignmentThreeSubmittedOn=assignmentThreeSubmittedOn==undefined?"":assignmentThreeSubmittedOn;
        this.assignmentThreeWaveOffCharges=assignmentThreeWaveOffCharges==undefined?false:assignmentThreeWaveOffCharges;
    }
}