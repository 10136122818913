export default class ModuleDetailsModel{
    constructor(
        moduleID,
        moduleTabID,
        heading,
        description,
        link,
        userID,
        uploadedPdfId,
        pdfCoverImageId,
    ){
        this.moduleID=moduleID== undefined ? 0 : moduleID;
        this.moduleTabID=moduleTabID== undefined ? 0 : moduleTabID;
        this.heading=heading== undefined ? "" : heading;
        this.description=description== undefined ? "" : description;
        this.link=link== undefined ? "" : link;
        this.userID=userID== undefined ? 0 : userID;
        this.uploadedPdfId=uploadedPdfId==undefined?0:uploadedPdfId;
        this.pdfCoverImageId=pdfCoverImageId==undefined?0:pdfCoverImageId;
    }
}