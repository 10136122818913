
export default class CourseIterationModuleBridgeModel{
    constructor(
        courseIterationModuleBridgeID,
        courseIterationID,
        moduleID,
        moduleVisible,
        tMAQVisible,
        tmaqfileMediaId,
        unitFileMediaID,
    ){
        this.courseIterationModuleBridgeID=courseIterationModuleBridgeID==undefined?0:courseIterationModuleBridgeID;
        this.courseIterationID=courseIterationID==undefined?0:courseIterationID;
        this.moduleID=moduleID==undefined?0:moduleID;
        this.moduleVisible=moduleVisible==undefined?false:moduleVisible;
        this.tMAQVisible=tMAQVisible==undefined?false:tMAQVisible;
        this.tmaqfileMediaId=tmaqfileMediaId==undefined?0:tmaqfileMediaId;
        this.unitFileMediaID=unitFileMediaID==undefined?0:unitFileMediaID;
    }
}