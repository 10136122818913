import React, { Component } from "react";
import Header from "../../../_components/Header";
import { courseService } from "../../../_services/Course.service";
import Loader from "../../../_helpers/Loader";
import PopupModal from "../../../_helpers/PopupModal";
import Logout from "../../../_helpers/Logout";
import StudentFooter from "../../../_components/StudentFooter";
import { MessageType } from "../../../_helpers/Enums";
import { helperText } from "../../../_helpers/helpertext";

export default class CourseOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      courseID: 0,
      isLoading: false,
      showPopupModal: false,
      popUpModalText: "",
      messageType:0,
    };
  }

  componentDidMount = () => {
    var courseID = this.props.match.params.id;
    if (courseID == undefined || courseID == 0) {
      courseID = this.state.courseID;
    } else {
      this.getIndivisualCourse(courseID);
    }
  };

  /* <summary>
  date: 15-11-2021
  Name: VD
  description: To get the indivisual course based on the courseid
  <summary>*/ 
  getIndivisualCourse = async (courseID) => {
    try{
      this.setState({
        isLoading: true,
      });
      var response = await courseService.GetIndivisualCourse(courseID);
      if (response.status == 200) {
      } else if (response.status == 401) {
        Logout();
      } else {
        this.setState({
          popUpModalText: helperText.DataLoadError.loadError,
          showPopupModal: true,
          messageType:MessageType.Failure,
        });
      }
      this.setState({
        isLoading: false,
      });
    }catch (ex) { 
      return "";
    }
  };

  closeModal = () => {
    this.setState({
      showPopupModal: false,
    });
  };

  render() {
    const { 
      isLoading, 
      popUpModalText, 
      showPopupModal,
      messageType, 
    } = this.state;
    return (
      <div>
        <section className="vbox">
          <Header currentPage="CourseOverview" />
          {isLoading && <Loader />}
          <section>
            <section className="hbox stretch">
              {/* .aside */}
              {/* /.aside */}
              <section id="coursenbanner">
                <div className="row">
                  <div className="col-md-12">
                    <h2>Course Module Overview</h2>
                    <h2>
                      <p>&lt; Back</p>
                    </h2>
                  </div>
                </div>
              </section>
              <section className="courselist col-md-9" id="content">
                {/* Module 1 */}
                <div className="row">
                  <br />
                  <div className="col-md-12">
                    <h4>
                      <strong>
                        {" "}
                        An Introduction to the Marine Surveying Profession
                      </strong>
                      <div className="mt-15">
                        <div className="startRating">
                          <span className="fa fa-star checked" />
                          <span className="fa fa-star checked" />
                          <span className="fa fa-star checked" />
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                        </div>
                      </div>
                    </h4>
                  </div>
                  <div className="col-xs-12">
                    {/* START SLICK SLIDER */}
                    <p />
                    <p>{/* END SLICK SLIDER */}</p>
                  </div>
                </div>
                <div>
                  <img
                    style={{
                      float: "left",
                      marginRight: "15px",
                      maxWidth: "350px",
                    }}
                    src="images/PersonalSafety.png"
                    alt="course"
                  />
                </div>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad
                  aperiam autem cumque deleniti dicta iusto laboriosam
                  laudantium omnis, possimus praesentium provident quam quas,
                  sapiente sint, ut! Adipisci aliquid assumenda consequuntur
                  cupiditate deleniti dicta dolore dolorem dolores enim eos hic
                  illo inventore iure libero magnam minima minus obcaecati optio
                  pariatur porro quibusdam quos reiciendis, sapiente sint
                  veritatis. Eveniet in magni sunt? Lorem ipsum dolor sit amet,
                  consectetur adipisicing elit. Ad aperiam autem cumque deleniti
                  dicta iusto laboriosam laudantium omnis, possimus praesentium
                  provident quam quas, sapiente sint, ut! Adipisci aliquid
                  assumenda consequuntur cupiditate deleniti dicta dolore
                  dolorem dolores enim eos hic illo inventore iure libero magnam
                  minima minus obcaecati optio pariatur porro quibusdam quos
                  reiciendis, sapiente sint veritatis. Eveniet in magni sunt?
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad
                  aperiam autem cumque deleniti dicta iusto laboriosam
                  laudantium omnis, possimus praesentium provident quam quas,
                  sapiente sint, ut! Adipisci aliquid assumenda consequuntur
                  cupiditate deleniti dicta dolore dolorem dolores enim eos hic
                  illo inventore iure libero magnam minima minus obcaecati optio
                  pariatur porro quibusdam quos reiciendis, sapiente sint
                  veritatis. Eveniet in magni sunt?
                </p>
              </section>
              <section className="courselist col-md-3" id="content">
                <div className="col-md-12 col-sm-6">
                  <br />
                  <h4>
                    <strong> Message form Admin</strong>
                  </h4>
                  <iframe
                    width="100%"
                    height={280}
                    src="https://www.youtube.com/embed/tgbNymZ7vqY"
                  ></iframe>
                  <p>
                    <small>
                      Video clip explains you about taking the course.....
                    </small>
                  </p>
                </div>
                <div className="col-md-12 col-sm-6">
                  <br />
                  <h4>
                    <strong> Student Handbook</strong>
                  </h4>
                  <img
                    style={{ boxShadow: "0px 0px 6px 2px #c3c3c3" }}
                    src="images/handbook.jpg"
                  />
                  <p>
                    <small>
                      Download a guide to know more &nbsp;
                      <i className="fa fa-download" aria-hidden="true" />
                    </small>
                  </p>
                </div>
              </section>
            </section>
          </section>
        </section>
        <StudentFooter />
        {showPopupModal && (
          <PopupModal closeModal={this.closeModal} Text={popUpModalText} messageType={messageType} />
        )}
      </div>
    );
  }
}
