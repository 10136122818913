import React, { Component } from "react";
import { admincourseservice } from "../_services/admincourse.service";
import Loader from "./Loader";
import leftsortbtn from "../assets/left-sort-btn.png";
import rightsortbtn from "../assets/right-sort-btn.png";
import DataTable from "react-data-table-component";
import CourseIterationModuleBridgeModel from "../Models/CourseIterationModuleBridgeModel";
import PopupModal from "./PopupModal";
import { MessageType } from "./Enums";
import { helperText } from "./helpertext";

export default class UpdateUnitForIterationModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      courseID: 0,
      courseIterationId: 0,
      selectedUnitList: [],
      unFilteredselectedUnitList: [],
      UnitList: [],
      unFilteredUnitList: [],
      isLoading: false,
      totalSelectedUnitList: 0,
      totalUnitList: 0,
      appliedCheckedBoxes: [],
      allUnitCheckedBox: [],
      newModule: [],
      showPopupModal: false,
      popUpModalText: "",
      messageType:0,
    };
  }

  componentDidMount = async () => {
    try{
      var CourseID = this.props.courseID;
      var CourseIterationID = this.props.courseIterationId;
      var appliedUnit = this.props.selectedUnitList;
      this.setState({
        isLoading: true,
        courseID: CourseID,
        courseIterationId: CourseIterationID,
        selectedUnitList: appliedUnit,
        unFilteredselectedUnitList: JSON.parse(JSON.stringify(appliedUnit)),
        totalSelectedUnitList: appliedUnit.length,
      });
  
      var response = await admincourseservice.GetModuleListToAssignToStudent(CourseID,CourseIterationID);
      if (response.status == 200) {
        this.setState({
          UnitList: response.data,
          unFilteredUnitList: JSON.parse(JSON.stringify(response.data)),
          totalUnitList: response.data.length,
        });
      }
  
      this.setState({
        isLoading: false,
      });
    }catch (ex) {
      return "";
    }
  };

  appliedUnitHeader = [
    {
      name: <th className="align-middle text-center">ID</th>,
      cell: (row) => <td className="center">{row.uniqueId}</td>,
      //left: true,
      selector: (row) => row.uniqueId,
      grow: 1,
      width: "50px",
    },
    //   {
    //     name: <th className="align-middle text-center">ID</th>,
    //     cell: (row) => <td className="center">{row.uniqueId}</td>,
    //     //left: true,
    //     selector: (row) => row.uniqueId,
    //     grow: 1,
    //   },
    {
      name: <th className="align-middle text-center">Title</th>,
      cell: (row) => <td className="center">{row.moduleName}</td>,
      center: true,
      selector: (row) => row.moduleName,
      grow: 3,
    },
  ];

  toggleAppliedUnitCheckbox = async (e, item) => {
    if (e.target.checked) {
      let arr = this.state.appliedCheckedBoxes;
      arr.push(item.moduleId);

      this.setState({ appliedCheckedBoxes: arr });
    } else {
      this.state.appliedCheckedBoxes.splice(
        this.state.appliedCheckedBoxes.indexOf(item.moduleId),
        1
      );

      this.setState({
        appliedCheckedBoxes: [...this.state.appliedCheckedBoxes],
      });
    }
  };

  allUnitHeader = [
    {
      name: <th className="align-middle text-center">ID</th>,
      cell: (row) => <td className="center">{row.uniqueId}</td>,
      //left: true,
      selector: (row) => row.uniqueId,
      grow: 1,
      width: "50px",
    },
    //   {
    //     name: <th className="align-middle text-center">ID</th>,
    //     cell: (row) => <td className="center">{row.uniqueId}</td>,
    //     //left: true,
    //     selector: (row) => row.uniqueId,
    //     grow: 1,
    //   },
    {
      name: <th className="align-middle text-center">Title</th>,
      cell: (row) => <td className="center">{row.moduleName}</td>,
      center: true,
      selector: (row) => row.moduleName,
      grow: 3,
    },
  ];

  toggleAllUnitCheckbox = async (e, item) => {
    if (e.target.checked) {
      let arr = this.state.allUnitCheckedBox;
      arr.push(item.moduleId);

      this.setState({ allUnitCheckedBox: arr });
    } else {
      this.state.allUnitCheckedBox.splice(
        this.state.allUnitCheckedBox.indexOf(item.moduleId),
        1
      );

      this.setState({
        allUnitCheckedBox: [...this.state.allUnitCheckedBox],
      });
    }
  };

  addUnitToAllUnitList = () => {
    this.setState({
      isLoading: true,
    });
    const {
      allUnitCheckedBox,
      appliedCheckedBoxes,
      UnitList,
      selectedUnitList,
    } = this.state;

    let arr = [...this.state.UnitList];
    let unitListFilter = [...this.state.UnitList];

    var unitstoAdd = [];
    var deleteditems;
    var selectedIndex;
    var filterList = unitListFilter.map((item) => {
      var findUnitToAdd = allUnitCheckedBox.filter((row) => row.moduleId == item.moduleId);
      if (findUnitToAdd.length > 0) {
        unitstoAdd.push(findUnitToAdd[0]);
      }
      return item;
    });

    var remove = unitListFilter.map((item) => {
      var findUnitToAdd = allUnitCheckedBox.filter((row) => row.moduleId == item.moduleId);
      if (findUnitToAdd.length > 0) {
        selectedIndex = arr.findIndex((x) => x.moduleId == findUnitToAdd[0].moduleId);
        arr.splice(selectedIndex, 1);
      }
      return item;
    });

    //   const filterArray = (arr1, arr2) => {
    //     const filtered = arr1.filter(el => {
    //        return arr2.indexOf(el) === -1;
    //     });
    //     return filtered;
    //  };

    this.setState({
      selectedUnitList: [...this.state.selectedUnitList, ...unitstoAdd],
      UnitList: arr,
      isLoading: false,
      allUnitCheckedBox: [],
    });
  };

  addUnitToSelectedUnitList = () => {
    this.setState({
      isLoading: true,
    });
    const {
      allUnitCheckedBox,
      appliedCheckedBoxes,
      UnitList,
      selectedUnitList,
    } = this.state;

    let arr = [...this.state.selectedUnitList];
    let selectedUnitListFilter = [...this.state.selectedUnitList];

    var unitstoAdd = [];
    var selectedIndex;
    var filterList = selectedUnitListFilter.map((item) => {
      var findUnitToAdd = appliedCheckedBoxes.filter((row) => row.moduleId == item.moduleId);
      if (findUnitToAdd.length > 0) {
        unitstoAdd.push(findUnitToAdd[0]);
      }
      return item;
    });

    var remove = selectedUnitListFilter.map((item) => {
      var findUnitToAdd = appliedCheckedBoxes.filter((row) => row.moduleId == item.moduleId);
      if (findUnitToAdd.length > 0) {
        selectedIndex = arr.findIndex((x) => x.moduleId == findUnitToAdd[0].moduleId);
        arr.splice(selectedIndex, 1);
      }
      return item;
    });

    this.setState({
      selectedUnitList: arr,
      UnitList: [...this.state.UnitList, ...unitstoAdd],
      isLoading: false,
      appliedCheckedBoxes: [],
    });
  };

  saveChanges = () => {
    try{
      this.setState({
        isLoading: true,
      });
      const {
        selectedUnitList,
        newModule,
        courseIterationId,
        unFilteredselectedUnitList,
      } = this.state;
      let tempSeletedUnitList = newModule;
      var sameValue = selectedUnitList.length == unFilteredselectedUnitList.length;
      if (selectedUnitList.length ==0) {
        this.setState({
          popUpModalText: helperText.AddUnitToStudentModelText.addUnit,
          showPopupModal: true,
          messageType:MessageType.Failure,
        });
      }else if(sameValue==true){
        this.setState({
          popUpModalText: helperText.AddUnitToStudentModelText.addremoveunit,
          showPopupModal: true,
          messageType:MessageType.Failure,
        });
      } else {
        for (let index = 0; index < selectedUnitList.length; index++) {
          var courseIterationModuleBridge = new CourseIterationModuleBridgeModel();
          courseIterationModuleBridge.courseIterationID = courseIterationId;
          courseIterationModuleBridge.moduleID = selectedUnitList[index].moduleId;
          courseIterationModuleBridge.moduleVisible = selectedUnitList[index].moduleVisible;
          courseIterationModuleBridge.tMAQVisible = selectedUnitList[index].tmaqVisible;
          courseIterationModuleBridge.tmaqFileMediaId = selectedUnitList[index].tmaqfileMediaId;
          tempSeletedUnitList.push(courseIterationModuleBridge);
        }
  
        this.setState({
          newModule: tempSeletedUnitList,
          popUpModalText: helperText.AddUnitToStudentModelText.UnitAdded,
          showPopupModal: true,
          messageType:MessageType.Success,
        });
      }
      this.connectToDB();
  
      this.setState({
        isLoading: false,
      });
    }catch (ex) {
      return "";
    }
  };
  connectToDB = async () => {
    try{
      var response = await admincourseservice.AddModulesToCourseIteration(this.state.newModule);
    }catch (ex) {
      return "";
    }
  };

  resetList = () => {
    this.setState({
      UnitList: this.state.unFilteredUnitList,
      selectedUnitList: this.state.unFilteredselectedUnitList,
    });
    //this.componentDidMount();
  };

  handleChange = (state) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data 
    this.setState({
      allUnitCheckedBox: state.selectedRows,
      isChanged: true,
    });
  };

  handleappliedUnitChange = (state) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data 
    this.setState({
      appliedCheckedBoxes: state.selectedRows,
      isChanged: true,
    });
  };

  closePopupModal = () => {
    this.setState({
      showPopupModal: false,
    });
  };
  
  render() {
    const {
      isLoading,
      totalUnitList,
      totalSelectedUnitList,
      UnitList,
      selectedUnitList,
      popUpModalText,
      showPopupModal,
      messageType,
    } = this.state;
    return (
      <div
        className="modal update-unit-popup"
        id="updateUnitList"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
      >
        {isLoading && <Loader />}
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="exampleModalLongTitle">
                Select Modules
              </h4>
              <p>Tag Modules under this course iteration.</p>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.props.closeModal}
              >
                <span>×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-5">
                    <label>Applied Modules ({totalSelectedUnitList})</label>
                    <p>
                      <DataTable
                        columns={this.appliedUnitHeader}
                        data={selectedUnitList}
                        noHeader={true}
                        fixedHeader={true}
                        fixedHeaderScrollHeight="450px"
                        onSelectedRowsChange={this.handleappliedUnitChange}
                        selectableRows // add for checkbox selection
                      />
                    </p>
                  </div>
                  <div className="col-md-2">
                    <div className="sort-btn">
                      <a onClick={() => this.addUnitToSelectedUnitList()}>
                        <img src={rightsortbtn} />
                      </a>
                      <a onClick={() => this.addUnitToAllUnitList()}>
                        <img src={leftsortbtn} />
                      </a>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <label>All Modules ({totalUnitList})</label>

                    <DataTable
                      columns={this.allUnitHeader}
                      data={UnitList}
                      noHeader={true}
                      fixedHeader={true}
                      fixedHeaderScrollHeight="450px"
                      onSelectedRowsChange={this.handleChange}
                      selectableRows // add for checkbox selection
                    />
                  </div>
                </div>
              </div>
              <div className="reset-apply-btn">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={() => this.resetList()}
                >
                  RESET
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.saveChanges()}
                >
                  APPLY
                </button>
              </div>
            </div>
          </div>
        </div>
        {showPopupModal && (
          <PopupModal closeModal={this.closePopupModal} Text={popUpModalText} messageType={messageType} />
        )}
      </div>
    );
  }
}
