import React from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  Button
} from 'reactstrap';
import moment from "moment";
import DataTable from "react-data-table-component";
import Logout from "../_helpers/Logout";
import { xeroservice } from "../_services/xero.service";
import Calendar from "../assets/Calendar.png";
import { MessageType } from '../_helpers/Enums';
import Loader from "../_helpers/Loader";
const dateFormat = "YYYY-MM-DD";

export class PaymentDetailsModal extends React.Component {
  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
    this.showModal = this.showModal.bind(this);

    this.state = {
      mode: 0,
      openPaymentDetailsModal: false,
      studentPaymentDetailList: [],
      originalstudentPaymentDetailList: [],
      selectedPaymentAmount: "",
      selectedPaymentDoneDate: "",
      selectedPaymentDueDate: "",
      currentdate: "",
      fromdate: moment().format(dateFormat),
      todate: moment().format(dateFormat),
      datefilter: moment().format(dateFormat),
      messageType:0,
      Search: "",
    };
  }

  componentDidMount = async () => {
    try{
      this.setState({
        isLoading: true,
      });
      
      await this.GetCurrentDate();
      await this.GetCurrentDateMinus50days();

      var response = await xeroservice.getInvoiceSync(this.state.fromdate,this.state.todate);
      if (response.status == 200) {
       
        this.setState({
          //isLoading: false,
          studentPaymentDetailList: response.data,
          originalstudentPaymentDetailList: response.data
        });
      } else if (response.status == 401) {
        Logout();
      } else {
        //error while loading data
        this.setState({
          popUpModalText: "error while loading data",
          showPopupModal: true,
          messageType:MessageType.Failure,
        });
      }
  
      this.setState({
        isLoading: false,
      });
    }
    catch (e) { 
      return "";
    }
  };

  onSearchTextChange = (event) => {
    var inputValue = event.target.value.toLowerCase();
 
    if(inputValue == "")
    {
      this.setState({
        Search: event.target.value,
        studentPaymentDetailList: this.state.originalstudentPaymentDetailList,
      });
    }
    else{
    var filteredStudent = this.state.originalstudentPaymentDetailList.filter(
      (p) =>
        p.referenceNumber.toLowerCase().includes(inputValue) ||
        p.studentName.toLowerCase().includes(inputValue)
    );

    this.setState({
      Search: event.target.value,
      studentPaymentDetailList: filteredStudent,
    });
  }
  };

  GetCurrentDate = () =>
  {
    var today = new Date();
    var month = (today.getMonth() + 1) < 10 ? "0" + (today.getMonth() + 1) : (today.getMonth() + 1);
    var day = (today.getDate()) < 10 ? "0" + (today.getDate()) : (today.getDate());
    var date = today.getFullYear() + '-' + month + '-' + day;
    this.setState({
      currentdate: date,
    });
  }

  GetCurrentDateMinus50days = () =>
  {
    var fromdate = new Date();
    fromdate.setDate(fromdate.getDate()-30)
    var fromdatemonth = (fromdate.getMonth() + 1) < 10 ? "0" + (fromdate.getMonth() + 1) : (fromdate.getMonth() + 1);
    var fromdateday = (fromdate.getDate()) < 10 ? "0" + (fromdate.getDate()) : (fromdate.getDate());
    var fromdateformated = fromdate.getFullYear() + '-' + fromdatemonth + '-' + fromdateday;

    var todate = new Date();
    todate.setDate(todate.getDate())
    var todatemonth = (todate.getMonth() + 1) < 10 ? "0" + (todate.getMonth() + 1) : (todate.getMonth() + 1);
    var todateday = (todate.getDate()) < 10 ? "0" + (todate.getDate()) : (todate.getDate());
    var todateformated = todate.getFullYear() + '-' + todatemonth + '-' + todateday;

    this.setState({
      fromdate: fromdateformated, todate: todateformated
    });
  }

  closeModal = () => {
    this.setState({ openPaymentDetailsModal: false });
  };

  showModal = (mode) => {
    this.setState({ openPaymentDetailsModal: true, mode: mode });
  };

  submitModal = () => {
    this.setState({ openPaymentDetailsModal: false });
  };

  toggleRadio = async (e, item) => {
    if (e.target.checked) 
      this.setState({selectedPaymentAmount : item.paymentAmount, selectedPaymentDoneDate : item.paymentDoneDate, selectedPaymentDueDate : item.paymentDueDate });
    else
      this.setState({selectedPaymentAmount : "", selectedPaymentDoneDate : "", selectedPaymentDueDate : "" });
  };

  UpdateButtonClicked= async () => {
    if(this.state.selectedPaymentAmount == "")
    {
      this.setState({
        popUpModalText: "Please select payment details to update",
        showPopupModal: true,
        messageType:MessageType.Failure,
      });
    }
    else
    {
      this.props.getPaymentDetailsFromXero(this.state.selectedPaymentAmount, this.state.selectedPaymentDoneDate, this.state.selectedPaymentDueDate);
      this.props.closeModal();
    }
  } 

  ClearFilterClicked = async () =>
  {
    this.setState({Search : ""});
    this.componentDidMount();
  }

  FilterClicked =  async () =>
  {
    this.setState({
      isLoading: true,
    });
    var response = await xeroservice.getInvoiceSync(this.state.fromdate,this.state.todate);
    if (response.status == 200) {
      this.setState({
        //isLoading: false,
        studentPaymentDetailList: response.data,
        originalstudentPaymentDetailList: response.data,
      });
    } else if (response.status == 401) {
      Logout();
    } else {
      //error while loading data
      this.setState({
        popUpModalText: "error while loading data",
        showPopupModal: true,
        messageType:MessageType.Failure,
      });
    }
    
    this.setState({
      Search : "",
      isLoading: false,
    });
  }

  onfromDateChange = (e) => {
    let { name, value } = e.target;
    let date = moment(value).format(dateFormat);
    this.setState({
      fromdate: date
    });
  };

  ontoDateChange = (e) => {
    let { name, value } = e.target;
    let date = moment(value).format(dateFormat);
    this.setState({
      todate: date
    });
  };

  header = [
    {
      name: (
        <th className="text-center" scope="col">
          {" "}
        </th>
      ),
      cell: (row) => (
        <input
          type="radio"
          id="check"
          name="check"
          radioGroup="GP"
          defaultValue="check"
          value={row.invoiceNumber}
          //checked={this.state.radioButtonChecked}
          onChange={(e) => this.toggleRadio(e, row)}
        />
      ),
      center: true,
      width: "50px"
    },
    {
      name: (
        <div className="table-header" scope="col">
          Reference Number
        </div>
      ),
      selector: "referenceNumber",
      cell: (row) => row.referenceNumber,
      center: true,
    },
    {
      name: (
        <div className="text-center" scope="col">
          Student name
        </div>
      ),
      selector: "studentName",
      cell: (row) => row.studentName,
      center: true,
    },
    {
      name: (
        <div className="text-center" scope="col">
          Invoice Number
        </div>
      ),
      selector: "invoiceNumber",
      cell: (row) => row.invoiceNumber,
      center: true,
    },
    {
      name:<div className="text-center" scope="col">
          Item Code
          </div>,
      selector:'itemCode',
      cell:row => row.itemCode,
      center:true,
    },
    {
      name: (
        <div className="text-center" scope="col">
          Payment Amount
        </div>
      ),
      selector: "paymentAmount",
      cell: (row) => row.paymentAmount,
      center: true,
      //grow: 2,
    },
    {
      name:<div className="text-center" scope="col">
          Payment Date
          </div>,
      selector:'paymentDueDate',
      cell:row=>row.paymentDoneDate == "" ? "" : row.paymentDoneDate,
      center:true,
    },
    {
      name:<div className="text-center" scope="col">
          Due Date
          </div>,
      selector:'paymentDueDate',
      cell:row=>row.paymentDueDate == "" ? "" : row.paymentDueDate,
      center:true,
    }
    // {
    //   name: (
    //     <div className="text-center" scope="col">
    //       Courses
    //     </div>
    //   ),
    //   selector: "Courses",
    //   cell: (row) => (
    //     <button
    //       onClick={() => history.push(`/EnrolledCourses/${row.userID}`)}
    //       style={{ cursor: "pointer" }}
    //       className="btn btn-link"
    //       type="button"
    //     >
    //       Manage
    //     </button>
    //   ),
    //   center: true,
    // },
  ];
  
  render() {
    return (
      <div
        id="myModal"
        className="modal"
        role="dialog"
        style={{
          overflow: "auto",
          display: "block",
        }}
      >
        <div className="modal-dialog" style={{margin: "100px", marginLeft: "250px"}}>
          <div className="modal-content" style={{width : "1000px"}}>
            <div className="modal-header">
              <button
                type="button"
                className="close"
                onClick={this.props.closeModal}
                style={{
                  padding: "0px 12px",
                  margin: "5px",
                  paddingBottom: "5px",
                }}
              >
                ×
              </button>
              <h4 className="modal-title">Payment Details</h4>
            </div>
            <div className="modal-body">
            {this.state.isLoading && <Loader />}
                        <h4 className="modal-title">
                            <div>
                              <lable>From:</lable>&nbsp;&nbsp;
                            <input
                              type="date"
                              name="datefilter"
                              placeholder="dd-mm-yyyy"
                              defaultValue
                              min="1997-01-01"
                              max={this.state.currentdate}
                              value={this.state.fromdate}
                              onChange={this.onfromDateChange}
                            />
                        <img
                          src={Calendar}
                          className="calendar1 calendar-class"
                          width="30px"
                        />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <lable>To:</lable>&nbsp;&nbsp;
                            <input
                              type="date"
                              name="datefilter"
                              placeholder="dd-mm-yyyy"
                              defaultValue
                              min="1997-01-01"
                              max={this.state.currentdate}
                              value={this.state.todate}
                              onChange={this.ontoDateChange}
                            />
                        <img
                          src={Calendar}
                          className="calendar1 calendar-class"
                          width="30px"
                        />
                        <span className="date-mark date-image">(dd/mm/yy)</span>
                        <Button className="buttonColor" size="sm"
                  style={{ marginLeft: '15px', fontSize: '12px' }} onClick={()=>this.FilterClicked()}>
                  <b>Filter</b>
                </Button>
                        <Button className="buttonColor" size="sm"
                  style={{ marginLeft: '15px', margintop:'5px', float: 'right', fontSize: '12px' }} onClick={()=>this.ClearFilterClicked()}>
                  <b>Clear Filter</b>
                </Button>
                            </div>
                            
            <div className="flex-item-left">
              <div className="search-container">
                <form className="example1">
                  <input
                    type="text"
                    placeholder="Search.."
                    name="Search"
                    value={this.state.Search}
                    onChange={this.onSearchTextChange.bind(this)}
                  />
                  <button type="submit">
                    <i className="fa fa-search" />
                  </button>
                </form>
              </div>
            </div>
            
            <div className="table-responsive" style={{ paddingTop: "0.5em" }}>
                        <table className="table table-striped">
                        <DataTable
                            columns={this.header}
                            data={this.state.studentPaymentDetailList}
                            noHeader={true}
                            pagination
                        />
                        </table>
                    </div>
            </h4>
            </div>
            <div className="modal-footer">
            <Button className="buttonColor" size="sm"
                  style={{ marginLeft: '15px', float: 'right', fontSize: '12px' }} onClick={this.UpdateButtonClicked}>
                  <b>Update</b>
                </Button>
            </div>
          </div>
        </div>
      </div>      
    );
  }
}
