import React from 'react';
import { Router, Route, Switch } from "react-router-dom";
//import '../App/App.css';
import {history} from './_helpers/history';
import Courses from './Pages/Student/Course/Courses';
import AllCourses from './Pages/Student/Course/AllCourses';
import CourseOverview from './Pages/Student/Course/CourseOverview';
import StudentProfile from './Pages/Student/StudentProfile';
import StudentNotification from './Pages/Student/StudentNotification';
import Assignment from './Pages/Student/Assignment';
import Certificates from './Pages/Student/Certificates';
import CertificatePage from './Pages/Student/CertificatePage';
import StudentCourseManager from './Pages/Admin/StudentCourse/Studentcoursemanager';
import EnrolledCourses from './Pages/Admin/StudentCourse/EnrolledCourses';
import CourseIteration from './Pages/Admin/StudentCourse/CourseIteration';
import {PrivateRoute} from './_components/PrivateRoute';
import CreateCourseIteration from './Pages/Admin/StudentCourse/CreateCourseIteration';
import EditCourseIteration from './Pages/Admin/StudentCourse/EditCourseIteration';
import StudentsEnquired from './Pages/Admin/StudentCourse/StudentsEnquired';
import CreateCourse from './Pages/Admin/StudentCourse/CreateCourse';
import ManageEnrollment from './Pages/Admin/StudentCourse/ManageEnrollment';
import RegisteredStudents from './Pages/Admin/StudentCourse/RegisteredStudents';
import Register from './Pages/Register';
import LoginPage from './Pages/LoginPage';
import LandingPage from './Pages/LandingPage'; 
import StudentHomePage from './Pages/Student/StudentHomePage';
import DashboardPage from './Pages/Admin/DashboardPage';
import UploadStudentData from './Pages/Admin/StudentData/UploadStudentData';
import AdminNotification from './Pages/Admin/AdminNotification';
import ManageAutoNotification from './Pages/Admin/StudentCourse/ManageAutoNotification';
import AdminHeader from './_components/AdminHeader';
import CourseDetails from './Pages/CourseDetails';
import AssignmentDetails from './Pages/Student/AssignmentDetails';

import SendEmail from './Pages/Email/SendEmail';
import EmailToAssessor from './Pages/Email/EmailToAssessor';
import EmailToStudent from './Pages/Email/EmailToStudent';
import ReadEmail from './Pages/Email/ReadEmail';

import AssignmentSubmissions from './Pages/Admin/StudentData/AssignmentSubmissions';
import AssignmentSubCategory from './Pages/Admin/StudentData/AssignmentSubCategory';

import ManageStudyMaterial from './Pages/Admin/Units/ManageStudyMaterial';
import AddEditUnitTabSelection from './Pages/Admin/Units/AddEditUnitTabSelection';
import AddEditUnitAddDynamicContent from './Pages/Admin/Units/AddEditUnitAddDynamicContent';
import AddEditUnitEditor from './Pages/Admin/Units/AddEditUnitEditor';
import ResetPassword from './Pages/ResetPassword';
import AdminProfile from './Pages/Admin/AdminProfile';

import ErrorPage from './Pages/ErrorPage';

//xero
// import XeroBanktransaction from './Pages/Xero/XeroBanktransaction';
 import XeroInvoices from './Pages/Xero/XeroInvoices';
 import Authorise from "./Pages/Xero/Authorise";
// import XeroPaymentinfo from './Pages/Xero/XeroPaymentinfo';
// import XeroBatchPayments from './Pages/Xero/XeroBatchPayments';

class App extends React.Component{
  constructor(props) {
    super(props);

    history.listen((location, action) => {});
  }

  render(){
    return(
      <div>
        <Router history={history}>
          <Switch>
          <PrivateRoute exact path="/" component={LoginPage} />
            <Route path="/LandingPage" component={LandingPage} />
            <Route path="/Courses" component={Courses} />
            <Route path="/AllCourses" component={AllCourses}/>
            <Route path="/CourseOverview/:id?" component={CourseOverview}/>
            <Route path="/StudentProfile" component={StudentProfile}/>
            <Route path="/StudentNotification" component={StudentNotification}/>
            <Route path="/Assignments" component={Assignment} />
            <Route path="/Certificates" component={Certificates}/>
            <Route path="/CertificatePage/:moduleName?" component={CertificatePage} />
            <Route path="/DashboardPage" component={DashboardPage}/>
            <Route path="/StudentCourseManager" component={StudentCourseManager}/>
            <Route path="/EnrolledCourses/:userID?" component={EnrolledCourses}/>
            <Route path="/CourseIteration/:CourseId?" component={CourseIteration}/>
            <Route path="/CreateCourseIteration" component={CreateCourseIteration}/>
            <Route path="/EditCourseIteration/:CourseIterationId?/:courseID?" component={EditCourseIteration} />
            <Route path="/StudentsEnquired" component={StudentsEnquired} />
            <Route path="/CreateCourse" component={CreateCourse} />
            <Route path="/ManageEnrollment/:userID?/:CourseIterationId?/:courseID" component={ManageEnrollment} />
            <Route path="/RegisteredStudents" component={RegisteredStudents} />
            <Route path="/Register" component={Register} />
            <Route path="/LoginPage" component={LoginPage} />
            <Route path="/UploadStudentData" component={UploadStudentData}/>
            <Route path="/AdminNotification" component={AdminNotification} />
            <Route path="/ManageAutoNotification" component={ManageAutoNotification}/>
            <Route path="/AdminHeader" component={AdminHeader} />
            <Route path="/CourseDetails/:moduleId?/:preview?" component={CourseDetails} />
            <Route path="/AssignmentSubmissions/:userID?/:courseIterationId?" component={AssignmentSubmissions} />
            <Route path="/AssignmentSubCategory/:userID?/:courseIterationId?/:moduleId?" component={AssignmentSubCategory}/>
            <Route path="/ManageStudyMaterial" component={ManageStudyMaterial} />
            <Route path="/AddEditUnitTabSelection" component={AddEditUnitTabSelection} />
            <Route path="/AddEditUnitAddDynamicContent/:moduleId?" component={AddEditUnitAddDynamicContent} />
            <Route path="/AddEditUnitEditor" component={AddEditUnitEditor} />
            <Route path="/AssignmentDetails/:moduleId?" component={AssignmentDetails} />
            
            <Route path="/SendEmail/:emailId?/:roleId?" component={SendEmail}/>
            <Route path="/EmailToAssessor/:assignmentId?" component={EmailToAssessor} />
            <Route path="/EmailToStudent/:userID?/:assignmentId?" component={EmailToStudent} />
            <Route path="/ResetPassword/:emailId?" component={ResetPassword} />
            <Route path="/ReadEmail/:emailId?/:roleId?" component={ReadEmail} />

            <Route path="/StudentHomePage" component={StudentHomePage} /> 
            <Route path="/AdminProfile" component={AdminProfile} />

            <Route path="/ErrorPage" component={ErrorPage} />
            
            {/*
            Xero demo data values in this project*/}
             <Route path="/XeroInvoices" component={XeroInvoices} />
             <Route path="/Authorise" component={Authorise}/>
            {/* <Route path="/XeroBanktransaction" component={XeroBanktransaction}/>
           
            <Route path="/XeroPaymentinfo" component={XeroPaymentinfo} />
            <Route path="/XeroBatchPayments" component={XeroBatchPayments} /> */}
          </Switch>
        </Router>
      </div>
    )
  }
}

export default App ;
