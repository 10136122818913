import React from "react";
import IIMSScreenLoadingAnimIcon from "../assets/IIMSScreenLoadingAnimIcon.gif";
function Loader(){
    return(
        <div className="Loading">
        <center>
            <img src={IIMSScreenLoadingAnimIcon} style={{width:'100px',height:'100px'}} />
        </center>
        </div>
    );
}

export default Loader