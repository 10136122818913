import React from 'react';

export default class AddEditUnitEditor extends React.Component {
  constructor(props) {
    super(props);
  }

  updateEditorState(editorState)
  {
this.setState({editorState});
  }

  render() {
    return (
     <div>

     </div>      
    );
  }
}
