import webserviceWrapper from "../_helpers/webserviceWrapper";

//whenever something related to student is done on admin side this page is called
export const adminstudentservice={
    GetCourseAndIterationDetailsForStudent,
    RegisteredStudentsList,
    StudentsList,
    GetCourseandIterationForUserID,
    GetCategoryEnquiryList,
    enrollCourseToStudent,
    GetStudentDataUploadedFileList,
    RemoveStudenFromIteration,
    GetModuleEnrolledToStudent,
    GetAssignmentListBasedOnModuleID,
    SendWelcomeNote,
    AddEditIterationOnHoldDateBasedOnStudenID,
    GetIterationOnHoldDetailsForStudent,
    FilterStudentBasedOnCourseId,
    addEditAssignmentResult,
    sendTemplateMailToSelectedStudentGroup,
    removeStudentFromDB,
}

function GetCourseAndIterationDetailsForStudent(userID,courseIterationId){
    return webserviceWrapper.post(
        `StudentAdmin/GetCourseAndIterationDetailsForStudent`,
        { userID, courseIterationId},
        60000
      );
}


function RegisteredStudentsList(){
    return webserviceWrapper.get(
       `StudentAdmin/RegisteredStudentsList`
    );
}

function StudentsList(){
    return webserviceWrapper.get(
       `StudentAdmin/StudentsList`
    );
}

function GetCourseandIterationForUserID(userID){
    return webserviceWrapper.post(
        `StudentAdmin/GetCourseandIterationForUserID?userID=${userID}`
    )
}

function enrollCourseToStudent(userID, courseIterationId,membershipNumber){
    return webserviceWrapper.post(
        `StudentAdmin/enrollCourseToStudent`,
        { userID, courseIterationId,membershipNumber},
        60000
      );
}

function GetCategoryEnquiryList(){
    return webserviceWrapper.get(
       `StudentAdmin/GetCategoryEnquiryList`
    );
}

function GetStudentDataUploadedFileList(){
    return webserviceWrapper.get(
        `StudentAdmin/GetStudentDataUploadedFileList`
     );
}

function RemoveStudenFromIteration(userID,courseIterationId){
    return webserviceWrapper.post(
        `StudentAdmin/RemoveStudenFromIteration`,
        { userID, courseIterationId},
        60000
      );
}

function GetModuleEnrolledToStudent(userID){
    return webserviceWrapper.post(
        `StudentAdmin/GetModuleEnrolledToStudent`,
        { userID},
        60000
      );
}

function GetAssignmentListBasedOnModuleID(ModuleId,userID,courseIterationId){
    return webserviceWrapper.post(
        `StudentAdmin/GetAssignmentListBasedOnModuleID`,
        {ModuleId,userID,courseIterationId},
        60000
      );
}

function SendWelcomeNote(welcomeNoteSelectedStudent,courseIterationId){
    return webserviceWrapper.post(
        `StudentAdmin/SendWelcomeNote`,
        {welcomeNoteSelectedStudent,courseIterationId},
        60000
      );
}

// function GetAssignmentWithModule(userID,courseIterationId){
//     return webserviceWrapper.post(
//         `StudentAdmin/GetAssignmentWithModule`,
//         {userID,courseIterationId},
//         60000
//       );
// }

function AddEditIterationOnHoldDateBasedOnStudenID(studentOnHold,onHoldStartDate,onHoldEndDate,userID,courseIterationId){
    return webserviceWrapper.post(
        `StudentAdmin/AddEditIterationOnHoldDateBasedOnStudenID`,
        {studentOnHold,onHoldStartDate,onHoldEndDate,userID,courseIterationId},
        60000
      );
}

function GetIterationOnHoldDetailsForStudent(userID,courseIterationId){
    return webserviceWrapper.post(
        `StudentAdmin/GetIterationOnHoldDetailsForStudent`,
        {userID,courseIterationId},
        60000
      );
}

function FilterStudentBasedOnCourseId(courseId){
    return webserviceWrapper.post(
        `StudentAdmin/FilterStudentBasedOnCourseId?courseId=${courseId}`
    )
}

function addEditAssignmentResult(StudentAssignmentBridge){
    return webserviceWrapper.post(
        `StudentAdmin/addEditAssignmentResult`,
        StudentAssignmentBridge
    )
}

function sendTemplateMailToSelectedStudentGroup(studentList,categoryId){
    return webserviceWrapper.post(
        `StudentAdmin/sendTemplateMailToSelectedStudentGroup`,
        {studentList,categoryId}
    )
}

function removeStudentFromDB(studentList){
    return webserviceWrapper.post(
        `StudentAdmin/removeStudentFromDB`,
        {studentList}
    )
}