export default class CourseIterationPaymentDetailsModel{
    constructor(
        firstInstallmentReceived,
        firstInstallmentAmountRecieved,
        secondInstallmentReceived,
        secondInstallmentAmountRecieved,
        thirdInstallmentReceived,
        thirdInstallmentAmountRecieved,
        fourthInstallmentReceived,
        fourthInstallmentAmountRecieved,
        hardCopyPaymentAmount,
        hardCopyPaymentDate,
        advanceCourseFees,
        advanceCourseDate,
        studentId,
        courseIterationId,
        iterationCompletedDate,
    ){
        this.firstInstallmentReceived=firstInstallmentReceived==undefined?"":firstInstallmentReceived;
        this.firstInstallmentAmountRecieved=firstInstallmentAmountRecieved==undefined?"":firstInstallmentAmountRecieved;
        this.secondInstallmentReceived=secondInstallmentReceived==undefined?"":secondInstallmentReceived;
        this.secondInstallmentAmountRecieved=secondInstallmentAmountRecieved==undefined?"":secondInstallmentAmountRecieved;
        this.thirdInstallmentReceived=thirdInstallmentReceived==undefined?"":thirdInstallmentReceived;
        this.thirdInstallmentAmountRecieved=thirdInstallmentAmountRecieved==undefined?"":thirdInstallmentAmountRecieved;
        this.fourthInstallmentReceived=fourthInstallmentReceived==undefined?"":fourthInstallmentReceived;
        this.fourthInstallmentAmountRecieved=fourthInstallmentAmountRecieved==undefined?"":fourthInstallmentAmountRecieved;
        this.hardCopyPaymentAmount=hardCopyPaymentAmount==undefined?"":hardCopyPaymentAmount;
        this.hardCopyPaymentDate=hardCopyPaymentDate==undefined?"":hardCopyPaymentDate;
        this.advanceCourseFees=advanceCourseFees==undefined?"":advanceCourseFees;
        this.advanceCourseDate=advanceCourseDate==undefined?"":advanceCourseDate;
        this.studentId=studentId==undefined?0:studentId;
        this.courseIterationId=courseIterationId==undefined?0:courseIterationId;
        this.iterationCompletedDate=iterationCompletedDate==undefined?"":iterationCompletedDate;
    } 
}