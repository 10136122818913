import React,{ Component } from "react";
import errorImage from "../assets/404-errorImage.png";
import { history } from "../_helpers/history";

export default class ErrorPage extends Component{
    render(){
        return(
            <div className="error-body" style={{backgroundColor: '#fff', height: '100vh'}}>
            <section className="container" style={{padding: '4% 0%'}}>
            <div className="row">
              <div className="col-sm-2">                
              </div>
              <div className="col-sm-8">  
                <div style={{textAlign: 'center'}}>
                  <h1 style={{fontFamily: 'Arial', fontWeight: 600, fontSize: '36px', color: 'red', textShadow: '0 0 #000'}}>Link is no longer valid</h1>
                  <img src={errorImage} alt={404} width="100%;" /><br />
                  <button className="btn btn-primary1" onClick={()=> history.push("/LoginPage")}>Go Back</button>
                </div>        
              </div>
            </div>		     
          </section>
          </div>
        )
    }
}