
export default class CourseIterationModel{
    constructor(
        courseIterationId,
        courseID,
        courseName,
        courseIterationName,
        startDate,
        endDate,
        firstInstallmentDate,
        secondInstallmentDate,
        thirdInstallmentDate,
        fourthInstallmentDate,
        iterationCost,
        userID,
        membershipNumber
    ){
        this.courseIterationId=courseIterationId==undefined?"":courseIterationId;
        this.courseID=courseID==undefined?"":courseID;
        this.courseName = courseName == undefined ? "" : courseName;
        this.courseIterationName = courseIterationName == undefined ? "" : courseIterationName;
        this.startDate = startDate == undefined ? "" : startDate;
        this.endDate = endDate == undefined ? "" : endDate;
        this.firstInstallmentDate = firstInstallmentDate == undefined ? "" : firstInstallmentDate;
        this.secondInstallmentDate = secondInstallmentDate == undefined ? "" : secondInstallmentDate;
        this.thirdInstallmentDate = thirdInstallmentDate == undefined ? "" : thirdInstallmentDate;
        this.fourthInstallmentDate = fourthInstallmentDate == undefined ? "" : fourthInstallmentDate;
        this.iterationCost = iterationCost == undefined ? 0 : iterationCost;
        this.userID=userID==undefined?0:userID;
        this.membershipNumber=membershipNumber==undefined?0:membershipNumber;
    }
}