import React, { Component } from "react";
import Loader from "../../../../_helpers/Loader";
import { Input, Row, Col, Button, Container } from "reactstrap";
import { helperText } from "../../../../_helpers/helpertext";
import { admincourseservice } from "../../../../_services/admincourse.service";
import PopupModal from "../../../../_helpers/PopupModal";
import { MessageType } from "../../../../_helpers/Enums";

const errorStyle = {
  color: "Red",
  fontStyle: "bold",
  fontSize: 14,
  marginBottom: "10px",
};

const errorStrings = {
  emptyCourseName: "Course name cannot be empty",
};

export default class EditCourseNameModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      errorFile: false,
      courseName: "",
      courseId: 0,
      showPopupModal: false,
      popUpModalText: "",
      messageType: 0,
    };
  }

  componentDidMount = () => {
    var courseID = this.props.courseID;
    var courseNameToEdit = this.props.courseNameToEdit;
    this.setState({
      courseId: courseID,
      courseName: courseNameToEdit,
    });
  };

  closeModal = () => {
    this.props.closeModal();
  };

  onTextChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      errorFile: false,
    });
  };

  /* <summary>
  date: 15-11-2021
  Name: VD
  description: This is used to save the changed course name
  <summary>*/ 
  onSaveClick = async () => {
    try {
      const { courseName, courseId } = this.state;
      if (this.state.courseName == "") {
        this.setState({ errorFile: true });
        return;
      }

      this.setState({
        isLoading: true,
      });
      var response = await admincourseservice.AddEditCourseName(courseId,courseName);
      if (response.status == 200) {
        this.setState({
          popUpModalText: helperText.EditCourseNameModel.updatedCourseName,
          showPopupModal: true,
          messageType: MessageType.Success,
        });
      }

      this.setState({
        isLoading: false,
      });
      this.props.closeshowEditCourseNameModel(1);
    } catch (ex) {
      return "";
    }
  };

  closeModal = () => {
    this.setState({
      showPopupModal: false,
    });
    this.props.closeshowEditCourseNameModel(1);
  };

  render() {
    const {
      isLoading,
      errorFile,
      courseName,
      popUpModalText,
      showPopupModal,
      messageType,
    } = this.state;
    return (
      <div
        id="myModal"
        className="modal"
        role="dialog"
        style={{
          overflow: "auto",
          display: "block",
          marginTop: "5.5%",
          opacity: 1,
        }}
      >
        <div className="modal-dialog">
          {/* Modal content*/}
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                onClick={() => this.props.closeshowEditCourseNameModel(0)}
                style={{
                  padding: "0px 12px",
                  margin: "5px",
                  paddingBottom: "5px",
                }}
              >
                ×
              </button>
              <h4 className="modal-title">Update</h4>
            </div>
            <div className="modal-body">
              <form>
                <p>
                  <small className="text-muted">
                    {helperText.courseNameChange.message}
                  </small>
                </p>

                <Input
                  type="text"
                  name="courseName"
                  onChange={this.onTextChange}
                  value={courseName}
                  style={{
                    marginTop: "10px",
                    width: "95%",
                    overflow: "revert",
                    whiteSpace: "break-spaces",
                  }}
                />
                {errorFile && (
                  <div style={errorStyle}>{errorStrings.emptyCourseName}</div>
                )}
                <Container style={{ padding: "1em 0 0 0", width: "90%" }}>
                  <Row>
                    <div>
                      <Button
                        className="buttonCancelColor"
                        size="sm"
                        style={{
                          marginLeft: "5px",
                          float: "right",
                          fontSize: "12px",
                        }}
                        onClick={() =>
                          this.props.closeshowEditCourseNameModel(0)
                        }
                      >
                        <b>CANCEL</b>
                      </Button>
                      <Button
                        className="buttonColor"
                        size="sm"
                        style={{
                          marginLeft: "15px",
                          float: "right",
                          fontSize: "12px",
                        }}
                        onClick={this.onSaveClick}
                      >
                        <b>SAVE</b>
                      </Button>
                    </div>
                    <Col md="12">
                      {this.state.isLoading ? <Loader /> : <div></div>}
                    </Col>
                  </Row>
                </Container>
              </form>
            </div>
          </div>
        </div>
        {isLoading && <Loader />}
        {showPopupModal && (
          <PopupModal
            closeModal={this.closeModal}
            Text={popUpModalText}
            messageType={messageType}
          />
        )}
      </div>
    );
  }
}
