import {authHeader} from "../_helpers/auth-header";
import webserviceWrapper from "../_helpers/webserviceWrapper";

export const loginService={
    getAll,
    login,
    RegisterStudentForCategoryEnquired,
    SaveLogoutInfo,
    ResetPassword,
    updatePassword,
    EditPassword,
    CheckIfLinkValid,
};

/* <summary>
date: 11-01-2022
Name: VD
description: This is for the user to login and since the site can be opened from mobile the below conditions have been put
<summary>*/ 
function login(UserName,Password,browsername,browserversion,osName,osVersion){
    return webserviceWrapper.post(
        `Login/GetLoginInfo`,
        { UserName, 
          Password,
          browsername:browsername === undefined ? "":browsername,
          browserversion: browserversion == undefined ? "":browserversion,
          osName: osName == undefined ? "":osName,
          osVersion: osVersion == undefined ? "" : osVersion, },
        60000
      );
}

function getAll() {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };
}

function RegisterStudentForCategoryEnquired(registerStudentModel){
    return webserviceWrapper.post(
      `Login/RegisterStudentForCategoryEnquired`,
      registerStudentModel
    );
}

function SaveLogoutInfo(UserName,userID){
  return webserviceWrapper.post(
      `Login/SaveLogoutInfo`,
      { UserName, userID },
      60000
    );
}

function ResetPassword(emailId){
  return webserviceWrapper.post(
    `Login/ResetPassword`,
    {emailId} ,
    60000
  );
}

function updatePassword(emailId,password){
  return webserviceWrapper.post(
    `Login/updatePassword`,
    {emailId,password} ,
    60000
  );
}

function EditPassword(emailId,password){
  return webserviceWrapper.post(
    `Login/EditPassword`,
    {emailId,password} ,
    60000
  );
}

function CheckIfLinkValid(guid){
  return webserviceWrapper.post(
    `Login/CheckIfLinkValid`,
    {guid} ,
    60000
  );
}