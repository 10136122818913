
export default class StudentAssignmentFilesBridgeModel{
    constructor(
        studentAssignmentBridgeId,
        studentId,
        moduleId,
        remarksFileMediaId,
        result,
        courseIterationId,
        assignmentSubmitNumber,
    ){
        this.studentAssignmentBridgeId = studentAssignmentBridgeId == undefined ? 0 : studentAssignmentBridgeId;
        this.studentId = studentId == undefined ? 0 : studentId;
        this.moduleId = moduleId == undefined ? 0 : moduleId;
        this.remarksFileMediaId = remarksFileMediaId == undefined ? 0 : remarksFileMediaId;
        this.result = result == undefined ? 0 : result;
        this.courseIterationId = courseIterationId == undefined ? 0 : courseIterationId;
        this.assignmentSubmitNumber = assignmentSubmitNumber == undefined? 0 : assignmentSubmitNumber;
    }
}