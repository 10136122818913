import React,{ Component } from "react"
import {xeroservice} from "../../_services/xero.service"

export default class Authorise extends Component{

    componentDidMount=async()=>{

        await xeroservice.Authoriseservice();

}
    render(){
        return(
            <p>
        {/* <button onClick={()=>this.getTenantID()}>Connect to Xero</button> */}
      </p>
    

        )
    }
}