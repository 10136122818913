import React,{ Component } from "react";

export default class ShowPdfFileModal extends Component{
    constructor(props){
        super(props);
        this.state={
            pdfFile:"",
        }
    }

    componentDidMount=()=>{
        var pdfFile=this.props.pdfFilePath;
        this.setState({
            pdfFile:pdfFile,
        })
    }

    render(){
        return(
            <div
            id="myModal"
            className="modal"
            role="dialog"
            style={{
              overflow: "auto",
              display: "block",
              // marginTop:"10px",
              opacity:1,
              backgroundColor:'#000000bf'
            }}
          >
            <div className="modal-dialog essential-reading-pdf">
              {/* Modal content*/}
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    onClick={()=>this.props.closepdfModal()}
                    style={{
                      padding: "0px 12px",
                      margin: "5px",
                      paddingBottom: "5px",
                    }}
                  >
                    ×
                  </button>
                  <h4 className="modal-title">Upload</h4>
                </div>
                <div className="modal-body">
                  <form>
                    <embed src={this.state.pdfFile} width="100%" height="800px" />
                  </form>
                </div>
              </div>
            </div>
          </div>
        )
    }
}