import webserviceWrapper from "../_helpers/webserviceWrapper";

export const xeroservice={
    getBankTransaction,
    getInvoiceSync,
    getPaymentinfo,
    getBatchPayments,
    Authoriseservice,
}

function getBankTransaction(){
    return webserviceWrapper.get(
        `BankTransactionsInfo/Index`
        
      );
}

function getInvoiceSync(fromdatetofilter,todatetofilter){
    return webserviceWrapper.post(
        `InvoiceSync/Index`,
        {fromdatetofilter,todatetofilter}
    );
}

function getPaymentinfo(){
    return webserviceWrapper.get(
        `PaymentInfo/Index`
    );
}

function getBatchPayments(){
    return webserviceWrapper.get(
        `BatchPaymentsInfo/Index`
    );
}

function Authoriseservice(){
    return webserviceWrapper.get(
        `Authorization/Callback`
    );
}