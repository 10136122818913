import React,{ Component } from "react";
import AdminHeader from "../../../_components/AdminHeader";
import Loader from "../../../_helpers/Loader";
import { adminservice } from "../../../_services/admin.service";
import Logout from "../../../_helpers/Logout";
import { history } from "../../../_helpers/history";
import DataTable from "react-data-table-component";
import PopupModal from "../../../_helpers/PopupModal";
import { MessageType } from "../../../_helpers/Enums";
import { helperText } from "../../../_helpers/helpertext";

class AddEditUnitTabSelection extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isLoading: false,
        moduleTabList: [],
        showPopupModal: false,
        popUpModalText: "",
        messageType:0,
        checkedBoxes: [],
    };
  }

  /* <summary>
  date: 15-11-2021
  Name: RA
  description: To get all the module tabs and module tabs based on the module id
  <summary>*/ 
  componentDidMount = async () => {
    try{
      var response = await adminservice.GetAllModuleTabs();
      if (response.status == 200) {
        this.setState({
          moduleTabList: response.data,
        });
        
        var responseTabs = await adminservice.GetTabsBasedOnModuleID(1);
        if (responseTabs.status == 200) {    
        } else if (responseTabs.status == 401) {
        Logout();
        } else {
          this.setState({
            popUpModalText: helperText.DataLoadError.loadError,
            showPopupModal: true,
            messageType:MessageType.Failure,
          });
        }
  
      } else if (response.status == 401) {
        Logout();
      } else {
        this.setState({
          popUpModalText: helperText.DataLoadError.loadError,
          showPopupModal: true,
          messageType:MessageType.Failure,
        });
      }
    }catch (e) { 
      return "";
    }
  } 
  
  closeModal = () => {
    this.setState({
      showPopupModal: false,
    });
  }; 

  header = [
    {
      name: (
        <th className="text-center" scope="col">
          {" "}
        </th>
      ),
      cell: (row) => (
        <input
          type="checkbox"
          id="check"
          name="check"
          defaultValue="check"
          value={row.moduleTabId}
          checked={this.state.checkedBoxes.find((p) => p.id === row.moduleTabId)}
          onChange={(e) => this.toggleCheckbox(e, row)}
        />
      ),
      center: true,
      width: "50px"
    },
    {
      name: (
        <th className="text-center" scope="col">
          {" "}
        </th>
      ),
      cell: (row) => (
        <div>
          {row.name}
        </div>
      ),
      center: true,
      width: "700px"
    }, 
  ];

  toggleCheckbox = async (e, item) => {
    if (e.target.checked) {
      let arr = this.state.checkedBoxes;
      arr.push(item.moduleTabId);

      this.setState({ checkedBoxes: arr });
    } else {
      let items = this.state.checkedBoxes.splice(
        this.state.checkedBoxes.indexOf(item.moduleTabId),
        1
      );   
    }
  };

  continue = () =>
  {
    var arr = this.state.checkedBoxes;
    if(arr.length == 0)
    {      
      this.setState({
        popUpModalText: helperText.AddEditUnitTabSelection.selectModule,
        showPopupModal: true,
        messageType:MessageType.Failure,
      });
    }
    else{
      this.setState({
        popUpModalText: "",
        showPopupModal: false,
        messageType:MessageType.Success,
      });
      history.push({
        pathname: '/AddEditUnitAddDynamicContent',
          state: arr 
      })
    }
  }
  
  render() {
    const { 
      isLoading, 
      popUpModalText, 
      showPopupModal,
      messageType,
     } = this.state;
    return (
        <div className="overall-font" style={{ backgroundColor: "#F1F4F6" }}>
            <AdminHeader currentTab="Students" />
            {isLoading && <Loader />}
            <div class="col-md-12">
                <h4 class="intro-heading"> Introduction to Marine Surveying for Large Yacht & Small Craft Surveyors </h4>
            </div>
                    <div class=" col-12 col-md-12">
            <div class="buttons-group">
            <button type="button" class="btn delete-button"><img src="images/Delete icon.png" class="delete-icon" width="20px" /> Delete</button>
            <button type="button" class="btn btn-dark button-color update-unit">Update Module</button>
            </div>
            </div>

            <div class="col-md-12" style={{margin:"10px"}}>

                <div class="choose-unit">
                    <h4> Choose Module Sections</h4>
                </div>
                <div class="applied-style">
                    {/* <h4>Applied Style: Standard Style</h4> */}
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive standard-style"> 
                              <DataTable
                                columns={this.header}
                                data={this.state.moduleTabList}
                                noHeader={true}
                                pagination
                              />
                            </div>
                        </div>
                    </div>
                </div>
                   
            </div>

        <div style={{margin:"10px"}}>
                    <center>
                    <button type="button" onClick={this.continue} class="btn btn-dark button-color update-unit">Continue</button>
                    </center>
                </div>

                
        {isLoading && <Loader />}
        {showPopupModal && (
          <PopupModal closeModal={this.closeModal} Text={popUpModalText} messageType={messageType} />
        )}
        </div>

            
        );
  }
}

export default AddEditUnitTabSelection;